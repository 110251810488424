import Vue from "vue";
import VueI18n from "vue-i18n";
import zhCn from "./language/zh-cn";
import enGb from "./language/en-gb";
import zhHk from "./language/zh-hk";
import zhTw from "./language/zh-tw";
Vue.use(VueI18n); // 全局注册国际化包

// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: "en-gb",
  fallbackLocale: "en-gb",
  messages: {
    "zh-cn": zhCn,
    "zh-tw": zhTw,
    "zh-hk": zhHk,
    "en-gb": enGb
  }
});

export default i18n;
