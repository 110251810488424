<template>
  <div class="nav-bar-container">
    <div class="navbar-box">
      <div class="navbar-left" v-if="!back">
        <img src="../assets/image/Index/LOGO.png" />
      </div>
      <div class="hotel-left" v-else @click="backHandler">
        <img src="../assets/image/Hotel/return.png" />
      </div>

      <div class="navbar-right" v-if="tools">
        <slot>
          <template>
            <!-- <div style="margin-right:12px;" @click="$root.feedback.show()">Help</div> -->
            <div class="navbar-icon" @click="$root.mailboxShow = true">
              <img src="../assets/image/share/skype.png" class="mailbox" />
            </div>
            <div
              class="navbar-icon"
              @click="$refs.login.show()"
              v-if="!isLogin"
            >
              <img src="../assets/image/share/use.png" />
            </div>
            <div class="navbar-icon" @click="$refs.my.show()">
              <img src="../assets/image/share/more.png" />
            </div>
          </template>
        </slot>
        <div class="navbar-icon" @click="$emit('close')" v-if="close">
          <img src="../assets/image/share/close.png" />
        </div>
      </div>
    </div>
    <login-pop ref="login" @success="success" v-if="!isLogin" />
    <my-pop ref="my" @login="$refs.login.show()" @update="$emit('change')" />

    <!-- <van-popup v-model="mailboxShow" closeable>
      <h3 style="text-align: center; margin: 18px 0 10px">Customer Service</h3>
      <div class="mailboxPopup">
        <div><img src="../assets/image/share/skype.png" width="22" /></div>
        <div class="mailboxPopup_right">
          <div class="mailboxaddress">{{ $root.info.email }}</div>
          <div style="font-size: 12px">
            Beijing time {{ ($root.info.time || []).join(", ") }}
          </div>
        </div>
      </div>
      <p
        style="
          margin: 0 16px 20px;
          text-align: center;
          font-size: 12px;
          color: #999;
        "
      >
        *You can find us through Skype or Whatsapp for help.
      </p>
    </van-popup> -->
  </div>
</template>

<script>
export default {
  name: "navbarComponent",
  props: {
    back: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
    tools: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    loginPop: () => import("@/components/login.vue"),
    myPop: () => import("@/components/my.vue"),
  },
  data() {
    // const info = {
    //   email: process.env.VUE_APP_EMAIL,
    //   time: process.env.VUE_APP_TIME,
    // };
    return {
      // mailboxShow: false,
      // info,
      backMap: {
        hotelDetail: "/hotel",
        hotel: "home",
      },
    };
  },
  computed: {
    isLogin() {
      return this.$root.isLogin;
    },
  },
  methods: {
    backHandler() {
      const { name } = this.$route;
      if (this.backMap[name]) {
        this.$router.replace(this.backMap[name]);
      } else {
        this.$router.go(-1);
      }
    },
    success() {},
    hide() {
      this.$refs.my && this.$refs.my.hide();
    },
  },
};
</script>

<style scoped lang="scss">
.nav-bar-container {
  background: #fff;

  .hotel-left {
    img {
      width: auto;
      height: 17px;
    }
  }
  .navbar-box {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar-left {
      img {
        display: block;
        height: 22px;
      }
    }

    .navbar-right {
      display: flex;
      align-items: center;
      .navbar-icon {
        width: 22px;

        + .navbar-icon {
          margin-left: 16px;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
