<template>
  <div class="page-container" ref="page" @scroll="scroll">
    <div class="page-scroll-box" ref="scroll"><slot /></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageNumber: 1,
      stepTime: 120,
      spacing: 200,
    };
  },
  props: {
    callback: {
      type: Function,
      default() {
        return () => {
          return new Promise((resolve) => {
            resolve(false);
          });
        };
      },
    },
  },
  mounted() {
    this.debounce();
  },
  methods: {
    debounce(reset = false) {
      const page = this.$refs.page;
      if (!page.debounce) {
        page.debounce = (() => {
          let timer = null;
          let hinder = false;
          let end = false;

          return (reset) => {
            if (reset) {
              clearTimeout(timer);
              hinder = end = false;
              this.pageNumber = 1;
            }

            if (end || hinder) return;
            clearTimeout(timer);
            timer = setTimeout(async () => {
              const callback = this.callback;
              let loading = null;
              if (this.pageNumber === 1) {
                loading = this.$toast.loading({
                  message: "Loading...",
                  duration: 0,
                  overlay: true
                });
              } else {
                page.classList.add("loading");
              }

              hinder = true;
              const bind = callback.bind(callback, this.pageNumber);
              end = await bind();

              if (this.pageNumber === 1) {
                loading.clear();
              } else {
                page.classList.remove("loading");
              }

              if (end && this.pageNumber !== 1) {
                page.classList.add("end");
              }

              ++this.pageNumber;
              setTimeout(() => {
                hinder = false;
              }, 100);
            }, this.stepTime);
          };
        })();
      }

      page.debounce(reset);
    },
    scroll(e) {
      if (!this.$refs.scroll) return;
      const { scrollTop } = e.target;
      const windowHeight = window.outerHeight;
      const spacing = scrollTop + windowHeight;

      const content = this.$refs.scroll.getBoundingClientRect();

      if (spacing > content.height - this.spacing) {
        this.debounce();
      }
    },
    reset() {
      this.debounce(true);
    },
  },
};
</script>
