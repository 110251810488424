<template>
  <div class="google-book">
    <div class="google-book-head google-book-block">
      <div class="customer-block">
        <span class="book-customer">ONLY For Google Customer</span>
      </div>
      <h3>
        <div>
          {{ roomDetail.HotelName }}
          <div class="score" v-if="roomDetail.Star">
            {{ roomDetail.Star }}
            <i
              class="star"
              :class="[item]"
              v-for="(item, index) in doubleStar(roomDetail.Star)"
              :key="index"
            ></i>
          </div>
        </div>
        <span @click="RoomDetailsPopup = true">Room Details</span>
      </h3>

      <!-- TODO && price.total -->
      <div class="change-room-type" v-if="roomType.RoomName">
        <div class="current-room" @click="changeType">
          <template v-if="!roomPriceInfo.RatePlanName">
            {{ roomType.RoomName }}
          </template>
          <template v-else>
            {{ roomType.RoomName + "(" + roomPriceInfo.RatePlanName + ")" }}
          </template>

          <i class="icon-arrow-right"></i>
        </div>
        <span v-if="promotion">Promotion</span>
      </div>

      <div class="equipment" v-if="equipment">
        <p>
          <i class="icon-people"></i>
          {{ equipment.Maxperson | complex("adult") }}
        </p>
        <p><i class="icon-bed"></i>{{ equipment.BedTypeDesc }}</p>
        <p>
          <template v-if="equipment.Breakfast">
            <i class="icon-breakfast"></i>Breakfast
          </template>
          <template v-else>
            <i class="icon-no-breakfast"></i>Breakfast not included
          </template>
        </p>
        <p>
          <template v-if="equipment.RefundType">
            <i class="icon-refundable"></i>Refundable
          </template>
          <template v-else>
            <i class="icon-no-refundable"></i>Non-refundable
          </template>
        </p>
      </div>

      <div class="date-range-wrap">
        <div class="date-range">
          <div class="date-range-content">
            <calender :default="dateRange" @change="calenderHandler" />
          </div>
          <i class="icon-arrow-right"></i>
        </div>
      </div>

      <div class="tips" @click="ShowMorePopup = true">
        <i class="icon-sigh"></i>
        <p>{{ policySort }}</p>
        <span>Show More</span>
      </div>
    </div>

    <div
      style="background: #f4f6f8; overflow: hidden; padding-bottom: 10px"
      v-if="!price.total && price.total !== null"
    >
      <div class="hotel-empty">
        <h4>No available room</h4>
        <p>
          Please reselect the check-in date, price range and check for the room
          availability.
        </p>
      </div>
    </div>

    <div class="google-book-block google-book-guest">
      <div class="guest-head">
        <h3 class="google-title">
          Guest Info <i class="icon-doubt" @click="guestInfoPop = true"></i>
        </h3>
        <div class="guest-select" @click="guestPop = true">
          {{ guest.roomNum | complex("Room") }},
          {{
            (parseInt(guest.adultNum) + parseInt(guest.childNum))
              | complex("Guest")
          }}
        </div>
      </div>

      <div class="guest-form">
        <template v-for="(room, roomIndex) in roomOptionalArr">
          <div class="guest-form-item" :key="roomIndex">
            <h4>
              <i
                class="icon-guest-reduce"
                v-if="roomIndex"
                @click="reduceHandler(roomOptionalArr, roomIndex)"
              ></i>
              Room
              {{ roomOptionalArr.length > 1 ? roomIndex + 1 : "" }}
            </h4>
            <template v-for="(guest, guestIndex) in room.OptionalArr">
              <div class="guest-user" :key="guestIndex">
                <h5>
                  <i
                    class="icon-guest-reduce"
                    v-if="guestIndex"
                    @click="reduceHandler(room.OptionalArr, guestIndex)"
                  ></i>
                  Guest
                  {{ room.OptionalArr.length > 1 ? guestIndex + 1 : "" }}
                </h5>
                <div class="guest-from-label">
                  <p>First name & middle name</p>
                  <input
                    type="text"
                    v-model="room.OptionalArr[guestIndex].surName"
                    v-error="nameReg"
                    placeholder="e.g. MARY ISABELLE"
                  />
                </div>
                <div class="guest-from-label">
                  <p>Last name</p>
                  <input
                    type="text"
                    v-model="room.OptionalArr[guestIndex].name"
                    v-error="nameReg"
                    placeholder="e.g. SMITH"
                  />
                </div>
              </div>
            </template>
            <div
              class="guest-create"
              v-if="queryStore.adult > room.OptionalArr.length"
              @click="addOptional(roomIndex)"
            >
              <i class="icon-create"></i>
              Add New Guest (Optional)
            </div>
          </div>
        </template>

        <div
          class="guest-create center"
          v-if="queryStore.room > roomOptionalArr.length"
          @click="RoomAdd"
        >
          <i class="icon-create"></i>
          Add a Room (Optional)
        </div>

        <div class="guest-form-other">
          <div class="guest-input" v-error="emailReg">
            <input type="text" placeholder="Email" v-model="values.email" />
          </div>
          <div class="guest-phone guest-input" v-error="phoneReg">
            <p @click="phoneCodePop = true">
              <span>{{ phoneCodeList[countryChoice] | phoneCode }}</span>
              <i class="icon-arrow-down"></i>
            </p>
            <input
              type="text"
              placeholder="Phone number"
              v-model="values.phone"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="google-book-block">
      <h3 class="google-title">Special Requests</h3>

      <div class="google-special">
        <p>
          We will deliver your request to the hotel. but requests are subject to
          availability.
        </p>

        <div class="special-ul" v-if="bedType.length > 1">
          <div
            class="special-li"
            @click="bedHandler(item.BedGroupId)"
            :class="{ choice: bedTypeChoice == item.BedGroupId }"
            v-for="(item, index) in bedType ? bedType : bedType.slice(0, 3)"
            :key="index"
          >
            <i></i> {{ item.Description }}
          </div>
        </div>

        <div class="special-textarea">
          <textarea
            placeholder="Please fill in your special requirements / requirements"
            v-model="requirement"
          ></textarea>
        </div>

        <div class="policy">
          By submitting this booking, I acknowledge that I have read and agree
          to Tuyitrip.com's
          <span @click="$refs.predetermined.show()">Terms of Use</span> and
          <span @click="$refs.policy.show()">Privacy Statement.</span>
        </div>
      </div>
    </div>

    <template v-if="price.total">
      <div style="height: 66px; width: 100%"></div>
      <div class="google-book-payment">
        <div class="google-book-price">
          <template>
            <h3>
              {{ price.total[1] }}{{ price.total[2] }}
              <small v-if="ratePrice">{{ ratePrice }}</small>
            </h3>
            <div class="google-book-tax" v-if="roomPriceInfo.PriceType === 1">
              <i class="icon-sigh" @click="isPriceDetailOpen = true"></i>
              Tax Included | Prepay Online
            </div>
          </template>
          <!-- <div v-else class="no-availablity">
            <h4>No availablity</h4>
            <p>Please switch to other room types or dates</p>
          </div> -->
        </div>
        <div class="google-book-pay">
          <button @click="book">Book</button>
        </div>
      </div>
    </template>

    <!-- currency pop -->
    <van-popup
      v-model="guestInfoPop"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="pop-box">
        <div class="pop-navigation">
          <span class="close-button" @click="guestInfoPop = false">Close</span>
        </div>
        <div class="pop-container">
          <div class="article-container">
            <h3 class="article-title">Guidelines</h3>
            <div class="article-box">
              <p class="article-p">
                1. Please enter the information for at least one guest.
              </p>
              <p class="article-p">
                2. The guest names entered must match the valid ID which will be
                used at check-in.
              </p>
              <p class="article-p">
                3. Please enter your full name according to the following
                format, leaving a space between your first and middle names.
              </p>
            </div>
            <div class="article-box">
              <p class="article-p article-bold article-border-bottom">
                First name & middle name
              </p>
              <p class="article-p article-bold article-border-bottom">
                Last name
              </p>
            </div>
            <div class="article-box">
              <p class="article-p">
                4. Guests aged under 18 cannot stay alone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 政策 -->
    <van-popup
      v-model="ShowMorePopup"
      close-icon-position="top-left"
      round
      position="bottom"
    >
      <div class="RoomDetails">
        <div class="RoomDetails_head">
          <img
            src="../../assets/image/payment/closeIcon.png"
            class="closeIcon"
            @click="ShowMorePopup = false"
          />
          <span class="title">Need to Know</span>
        </div>
        <div style="padding: 0 16px" class="RoomDetailsRoll">
          <div class="">
            <div>
              <div class="Important_box">
                <div class="Info">Non-refundable</div>
                <div>
                  <div class="According">
                    {{ policySort }}
                  </div>
                </div>
              </div>
              <template v-for="(policy, index) in roomDetail.HotelPolicyList">
                <div class="Important_box" :key="index">
                  <template v-if="policy.Title == 'Need to Know'">
                    <div
                      :key="indexs"
                      v-for="(pItem, indexs) in policy.Items"
                      class="Important_box"
                    >
                      <div class="Info">{{ pItem.Title }}</div>
                      <div class="According" v-html="pItem.Description"></div>
                    </div>
                  </template>
                  <div v-else-if="policy.Title == 'Check-in and Check-out'">
                    <div class="Info">{{ policy.Title }}</div>
                    <div class="Checktime">
                      <div
                        v-for="(pItem, indexs) in policy.Items"
                        :key="indexs"
                      >
                        <div
                          class="Checktime_item"
                          v-html="pItem.Description"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="Info">{{ policy.Title }}</div>
                    <div v-for="(pItem, indexs) in policy.Items" :key="indexs">
                      <div class="According" v-html="pItem.Description"></div>
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="Important_box"
                v-if="
                  roomDetail.PaymentMethod &&
                  roomDetail.PaymentMethod.length > 0
                "
              >
                <template v-for="(pm, index) in roomDetail.PaymentMethod">
                  <div
                    :key="index"
                    v-if="
                      pm.ImageList &&
                      pm.ImageList.length > 0 &&
                      pm.Title == 'BankCard'
                    "
                    class="BankCard"
                  >
                    <template v-for="(imgItem, index) in pm.ImageList">
                      <img
                        :src="imgItem"
                        class="fontsSize30"
                        :key="index"
                        v-if="pm.Title == 'BankCard'"
                      />
                    </template>
                  </div>
                </template>
                <div class="Paymentmethod">
                  <template v-for="(pm, index) in roomDetail.PaymentMethod">
                    <img
                      :src="pm.ImageList[0]"
                      class="paypaitem1"
                      :key="index"
                      v-if="
                        pm.ImageList &&
                        pm.ImageList.length &&
                        pm.Title != 'BankCard'
                      "
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 酒店信息 -->
    <van-popup
      v-model="RoomDetailsPopup"
      close-icon-position="top-left"
      round
      position="bottom"
    >
      <div class="RoomDetails">
        <div class="RoomDetails_head">
          <img
            src="../../assets/image/payment/closeIcon.png"
            class="closeIcon"
            @click="RoomDetailsPopup = false"
          />
          <span class="title">Room Details</span>
        </div>
        <div class="RoomDetailsRoll">
          <div
            class="swipe_box"
            v-if="roomType.Photos && roomType.Photos.length"
          >
            <van-swipe
              class="my-swipe"
              :show-indicators="false"
              @change="RotationarrChange"
              ref="swiper"
            >
              <van-swipe-item
                v-for="(item, index) in roomType.Photos"
                :key="index"
              >
                <van-image fit="cover" :height="200" :src="item" />
              </van-swipe-item>
            </van-swipe>
            <div class="RotationLeft" @click="$refs.swiper.prev()">
              <img src="../../assets/image/Hotel/RotationLeft.png" />
            </div>
            <div class="RotationRight" @click="$refs.swiper.next()">
              <img src="../../assets/image/Hotel/RotationRight.png" />
            </div>
            <div class="quantity">
              <img src="../../assets/image/Hotel/Rotati.png" />
              <div class="quantityNumbers">
                {{ roomType.PhotoIndex + 1 }}&nbsp;/&nbsp;{{
                  roomType.Photos.length
                }}
              </div>
            </div>
          </div>
          <div class="detailsfacilities">
            <div class="detailsfacilities_item" v-if="roomType.RoomArea">
              <img src="../../assets/image/Hotel/measure.png" />
              <span class="measure">{{ roomType.RoomArea }}</span>
            </div>
            <div class="detailsfacilities_item" v-if="roomType.RoomFloor">
              <img src="../../assets/image/Hotel/floor.png" />
              <span class="measure">{{ roomType.RoomFloor }}</span>
            </div>
            <div
              class="detailsfacilities_item"
              v-if="roomType.Window != 0 && roomType.Window != 2"
            >
              <img src="../../assets/image/Hotel/Windowed.png" alt="" />
              <span class="measure">{{ roomType.WifiDesc }}</span>
            </div>
          </div>

          <div class="Rollhoteldetails">
            <div class="Rollhoteldetails_item">
              <img
                src="../../assets/image/Hotel/RoomDetailsShow/Numberpeople.png"
              />
              <div class="Rollhoteldetailsdescribe">
                <span>
                  {{ roomPriceInfo.Maxperson }}
                  adult{{ roomPriceInfo.Maxperson > 1 ? "s" : "" }}
                </span>
              </div>
            </div>
            <div class="Rollhoteldetails_item">
              <img
                src="../../assets/image/Hotel/RoomDetailsShow/singlebed.png"
              />
              <div class="Rollhoteldetailsdescribe">
                {{ roomType.BedTypeDesc }}
              </div>
            </div>
            <div class="Rollhoteldetails_item">
              <img
                v-if="roomPriceInfo.Breakfast > 0"
                src="../../assets/image/Hotel/RoomDetailsShow/Catering.png"
              />
              <img
                v-else
                src="../../assets/image/Hotel/RoomDetailsShow/Restaurant.png"
              />
              <div class="Rollhoteldetailsdescribe">
                <div class="Breakfasts" v-if="roomPriceInfo.Breakfast > 0">
                  {{ roomPriceInfo.Breakfast }} Breakfast
                </div>
                <div class="Breakfasts" v-else>Breakfast not included</div>
                <!--<div>Extra Child's Breakfast(O-2 years old): RMB$97</div>-->
              </div>
            </div>
            <div class="Rollhoteldetails_item">
              <div class="">
                <img src="../../assets/image/Hotel/RoomDetailsShow/wifi.png" />
              </div>
              <div class="Rollhoteldetailsdescribe">has Wi-Fi</div>
            </div>
            <div
              class="Rollhoteldetails_item"
              v-if="
                roomPriceInfo.RefundRule &&
                roomPriceInfo.RefundRule.RefundType !== 0
              "
            >
              <div class="">
                <img src="../../assets/image/Hotel/Refundable.png" />
              </div>
              <div class="Rollhoteldetailsdescribe">Refundable</div>
            </div>
            <div class="Rollhoteldetails_item" v-else>
              <div class="">
                <img src="../../assets/image/Hotel/Non.png" />
              </div>
              <div class="Rollhoteldetailsdescribe">Non-refundable</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 区号 -->
    <van-popup v-model="phoneCodePop" round position="bottom">
      <div>
        <van-nav-bar right-text="determine" @click-right="countryHandler" />
      </div>
      <van-picker
        :columns="phoneCodeList"
        :default-index="countryChoice"
        ref="countryPicker"
      />
    </van-popup>

    <!--价格详情-->
    <van-popup
      v-model="isPriceDetailOpen"
      closeable
      close-icon-position="top-left"
      round
      class="hoteldetails"
      position="bottom"
    >
      <div class="price-detail-wrap">
        <div class="hoteldetails_box">Price Details</div>
        <div class="hoteldetailsroll" v-if="price.total">
          <div class="OnlineBox">
            <div class="OnlinePrepay">
              <div class="OnlinePrepay_left">Prepay Online</div>
              <div class="OnlinePrepay_right">
                {{ price.total[1] }}
                {{ price.total[2] }}
              </div>
            </div>
            <div class="OnlinePrepay margTop30">
              <div class="OnlineroomPrepay_left">
                <span
                  >{{ queryStore.room }} room x
                  {{ days | complex("night") }}</span
                >
                <img
                  v-if="days > 1"
                  @click="DaysboxShow = !DaysboxShow"
                  :src="
                    DaysboxShow
                      ? require('../../assets/image/Hotel/upper.png')
                      : require('../../assets/image/Hotel/openIcon.png')
                  "
                />
              </div>
              <div class="OnlineroomPrepay_right">
                {{ price.baseTotal[1] }}
                {{ price.baseTotal[2] | toFixed }}
              </div>
            </div>
            <div class="Days_box" v-if="DaysboxShow">
              <template
                v-for="(rate, ind) in calcUnitPrice({
                  range: dateRange,
                  room: queryStore.room,
                  price: price.baseTotal[2],
                })"
              >
                <div
                  class="JunRoom"
                  :key="ind"
                  v-if="roomPriceInfo != undefined"
                >
                  <div class="JunRoom_left">
                    {{ rate.date }}, {{ rate.room | complex("Room") }}
                  </div>
                  <div class="JunRoom_right">
                    {{ rate.room }} x $ {{ rate.unit }}
                  </div>
                </div>
              </template>
            </div>
            <div class="Includes" :class="{ dashed: price.extra[2] }">
              <span
                >Includes
                {{ price.taxesAndFees[1] }}
                {{ price.taxesAndFees[2] }} in taxes & fees</span
              >
            </div>

            <div class="extra" v-if="price.extra[2]">
              <p>
                Extra
                <small>Pay at the property</small>
              </p>
              <p>{{ price.extra[0] }}{{ (price.extra[2] || 0) | toFixed }}</p>
            </div>
          </div>
          <van-popup v-model="feesexplain" closeable class="feesexplain">
            <div
              class="feesexplainPopup"
              v-if="roomPriceInfo && roomPriceInfo.TotalOfAllRoom"
            >
              <div class="Includestaxes">
                Includes
                {{ price.taxesAndFees[1] }}
                {{ price.taxesAndFees[2] }} in taxes & fees
              </div>
              <template v-for="(tItem, k) in roomPriceInfo.Taxes">
                <div
                  class="fees_item"
                  :key="k"
                  v-if="roomPriceInfo.Taxes != null"
                >
                  <div class="feesitem_left">{{ tItem.Type }}</div>
                  <div class="feesitem_right">
                    {{ tItem.CurrencySymbol }} {{ tItem.Price }}
                  </div>
                </div>
              </template>
              <template v-for="(tItem, k) in roomPriceInfo.Fees">
                <div
                  class="fees_item"
                  :key="k"
                  v-if="roomPriceInfo.Fees != null"
                >
                  <div class="feesitem_left">{{ tItem.Type }}</div>
                  <div class="feesitem_right">
                    {{ tItem.CurrencySymbol }} {{ tItem.Price }}
                  </div>
                </div>
              </template>
            </div>
          </van-popup>
        </div>
      </div>
    </van-popup>

    <modal :title="values.email" ref="orderResolve" @resolve="createOrder">
      We will send your booking confirmation to this email address.
    </modal>

    <policy ref="policy" />
    <predetermined ref="predetermined" />
    <guest :default="guest" :pop.sync="guestPop" @change="guestHandler" />
  </div>
</template>

<script>
import hotelMixin from "@/mixins/hotel.mixin";
import bookMixin from "@/mixins/book.mixin";
import Calender from "@/components/Calender";
import Guest from "@/components/Guest";
export default {
  name: "googleBook",
  mixins: [hotelMixin, bookMixin],
  data() {
    // const { room, adult, children, age } = this.$route.query;
    // const guest = {
    //   roomNum: parseInt(room || 1),
    //   adultNum: parseInt(adult || 2),
    //   childNum: parseInt(children || 0),
    //   childAges: !!age ? age.split(",") : [],
    // };

    return {
      guestInfoPop: false,
      ShowMorePopup: false,
      RoomDetailsPopup: false,
      phoneCodePop: false,
      feesexplain: false,
      isPriceDetailOpen: false,
      DaysboxShow: false,
      guestPop: false,
      promotion: false,
      policySort:
        "If you cancel your reservation for personal reasons, 100% of the order fee will be charged as liquidated damages. If there are other circumstances, please explain the reasons. We will try our best to apply for a fee waiver for you, but we can't guarantee success.Important tips! ! If the hotel can't arrange your accommodation, please contact us in time to avoid your loss.",
      values: {
        email: null,
        phone: null,
      },
      roomOptionalArr: [
        {
          OptionalArr: [
            {
              surName: "",
              name: "",
              surNameTips: false,
              NameTips: false,
            },
          ],
        },
      ],
    };
  },
  components: {
    Calender,
    Guest,
  },
  created() {
    this.promotion =
      this.queryStore.promotion === "true" || !this.queryStore.promotion;

    // const { guest, checkIn, checkOut } = this.initSearch();
    // this.dateRange = {
    //   start: checkIn,
    //   end: checkOut,
    // };
    // this.$set(this, "guest", guest);
  },

  computed: {
    // policyText() {
    //   let result = this.policySort.replace(/<\/?(\w+?)>/g, "");
    //   result = result.replace(/\s+/g, " ");
    //   return result;
    // },
  },
  activated() {
    const googleRoomType = localStorage.getItem("googleRoomType");
    if (googleRoomType) {
      const room = JSON.parse(googleRoomType);
      if (room.id !== this.queryStore.roomId) {
        this.updateUrl({
          roomId: room.id,
          promotion: room.promotion,
          payPrice: room.payPrice,
        });

        this.promotion = room.promotion;
        this.init();
      }

      localStorage.removeItem("googleRoomType");
    }
  },
  methods: {
    init() {
      this.getRoomDetail();
      this.search();
    },
    reduceHandler(item, index) {
      item.splice(index, 1);
    },
    changeType() {
      const { start, end } = this.dateRange;
      const { roomNum, childNum, adultNum, ageNum } = this.guest;
      this.$router.push({
        path: "/google-change",
        query: {
          PriceType: this.roomPriceInfo.PriceType,
          ...this.queryStore,

          checkIn: start,
          checkOut: end,
          room: roomNum,
          adult: adultNum,
          children: childNum,
          age: ageNum,
          rate: this.userLocale ? this.userLocale.rate : null,
        },
      });
    },
    guestHandler(guest) {
      const { adultNum, childAges, childNum, roomNum } = guest;
      let store = localStorage.getItem("googleRoom");

      if (store) {
        store = JSON.parse(store);
        store.guest = guest;
      } else {
        store = {
          guest: guest,
        };
      }
      localStorage.setItem("googleRoom", JSON.stringify(store));
      const newGuest = {
        room: roomNum,
        adult: adultNum,
        children: childNum,
        age: childAges,
      };

      this.updateUrl(
        Object.assign({}, newGuest, {
          age: childAges ? childAges.join(",") : "",
        })
      );

      this.guest = Object.assign({}, guest);
      // roomOptionalArr
      // console.log(adultNum, roomNum);
      this.roomOptionalArr = this.roomOptionalArr.reduce(
        (total, item, index) => {
          if (index < roomNum) {
            const r = {
              OptionalArr: null,
            };
            item.OptionalArr = item.OptionalArr.slice(0, adultNum);
            total.push(item);
          }

          return total;
        },
        []
      );
      this.search();
    },
    calenderHandler(range) {
      const { start, end } = range;
      let store = localStorage.getItem("googleRoom");
      if (store) {
        store = JSON.parse(store);
        store.dateRange = range;
      } else {
        store = {
          dateRange: range,
        };
      }
      localStorage.setItem("googleRoom", JSON.stringify(store));
      this.updateUrl({
        checkIn: start,
        checkOut: end,
      });

      this.dateRange = range;
      this.search();
    },
    countryHandler() {
      const picker = this.$refs.countryPicker;
      this.countryChoice = picker.getIndexes()[0];
      this.phoneCodePop = false;
    },
    RotationarrChange(index) {
      if (this.roomType != undefined && this.roomType != null) {
        this.roomType.PhotoIndex = index;
      }
    },
    doubleStar(star) {
      const double = (star || 0) * 2;
      const result = [];

      for (let i = 1; i <= double; i++) {
        if (i % 2 === 0 || i === double) {
          result.push(i % 2 === 0 ? "" : "half");
        }
      }
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.google-book {
  font-family: "Microsoft YaHei";

  .validate-box {
    transition: border 0.2s ease;

    &.error {
      border-color: #f00 !important;
    }
  }

  .icon-guest-reduce {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/reduce.png) no-repeat;
    background-size: 100%;
  }

  .RoomDetails {
    .RoomDetails_box {
      padding-top: 17px;
      padding-bottom: 40px;
      height: 500px;
    }

    .RoomDetails_title {
      text-align: center;
      padding: 20px 0px;
      font-size: 18px;
      font-weight: bold;
    }

    .swipe_box {
      height: 200px;
      width: 100%;
      position: relative;
    }

    .detailsfacilities {
      border-bottom: 1px solid #d3dce6;
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0px;
      margin: 0 15px;
      margin-top: 15px;
      padding-top: 0px;
    }

    .measure {
      text-indent: 6px;
      font-size: 14px;
      color: #000000;
    }

    .Rollhoteldetails_item img {
      width: 16px;
      height: 16px;
    }

    .Breakfasts {
      font-size: 12px;
      color: #00adb2;
    }

    .Rollhoteldetails {
      margin-top: 15px;
      padding: 0px 15px;
    }

    .Rollhoteldetails_item {
      margin-top: 8px;
      display: flex;
    }

    .Rollhoteldetailsdescribe {
      font-size: 12px;
      color: #000000;
      margin-left: 12px;
      line-height: 19px;

      span {
        font-size: 12px;
      }
    }

    .RotationLeft {
      position: absolute;
      left: 15px;
      top: 40%;
    }

    .RotationRight {
      position: absolute;
      right: 15px;
      top: 40%;
    }

    .quantity {
      position: absolute;
      right: 0px;
      bottom: 8px;
      background: #000000;
      opacity: 0.6;
      border-radius: 20px 0px 0px 20px;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;

      img {
        width: 14px;
        height: 14px;
      }
    }

    .quantityNumbers {
      color: #cccccc;
      font-size: 14px;
      margin-left: 3px;
      padding-top: 3px;
    }

    .van-image {
      width: 100%;
      height: 100%;
    }

    .van-image img {
      width: 100%;
      height: 200px;
    }

    .RotationLeft {
      position: absolute;
      left: 15px;
      top: 40%;
    }

    .RotationRight {
      position: absolute;
      right: 15px;
      top: 40%;
    }

    .detailsfacilities_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      padding-top: 5px;

      img {
        max-height: 20px;
        width: auto;
      }
    }

    .RoomDetailsRoll {
      height: 488px;
      overflow: auto;
    }

    .RoomDetails_head {
      padding: 17px 15px;
      display: flex;
      align-items: center;
    }

    .closeIcon {
      width: 16px;
      height: 16px;
    }

    .RoomDetailsRoll {
      height: 488px;
      overflow: auto;
    }

    .van-popup--bottom.van-popup--round {
      border-radius: 15px 15px 0 0;
    }

    .title {
      text-align: center;
      flex: 1;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-top: 2px;
      text-indent: -20px;
    }

    .Important_box {
      margin-top: 20px;
    }

    .Checktime {
      background: #f8f8f8;
      border-radius: 4px;
      width: 100%;
      display: flex;
      margin-top: 10px;
    }

    .Checktime_item {
      width: 100%;
      padding: 10px;
      font-size: 12px;
      color: #000000;
      line-height: 18px;
    }
    .Info {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
    }
    .According {
      font-size: 14px;
      color: #000000;
      margin-top: 10px;
      line-height: 1.4;
      // text-align: justify;
    }
  }

  .google-book-block {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
    padding: 15px;
  }

  .google-book-block + .google-book-block {
    margin-top: 10px;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.08);
  }

  i[class^="icon-"] {
    display: block;
  }

  .icon-people {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/people.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 2px;
  }

  .icon-bed {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/bed.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 2px;
  }

  .icon-breakfast {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/Catering.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 2px;
  }

  .icon-no-breakfast {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/no-breakfast.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 2px;
  }

  .icon-no-refundable {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/prohibit.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 2px;
  }

  .icon-refundable {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/Refundable.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 2px;
  }

  .icon-arrow-right {
    width: 6px;
    height: 11px;
    background: url(../../assets/image/google/arrow-right.png) no-repeat;
    background-size: 100% 100%;
    margin-left: 12px;
  }

  .icon-sigh {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/sigh.png) no-repeat;
    background-size: 100%;
    margin-right: 10px;
  }

  .icon-doubt {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/doubt.png) no-repeat;
    background-size: 100%;
    margin-left: 10px;
  }

  .icon-create {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/google/add.png) no-repeat;
    background-size: 100%;
    margin-right: 10px;
  }

  .icon-arrow-down {
    width: 11px;
    height: 6px;
    background: url(../../assets/image/google/arrow-down.png) no-repeat;
    background-size: 100%;
  }

  .google-book-head {
    padding: 0 15px;

    .customer-block {
      font-size: 0;
      line-height: 0;

      .book-customer {
        line-height: 20px;
        background: #fae7e6;
        padding: 0 16px;
        display: inline-block;
        font-size: 12px;
        border-radius: 0 0 10px 10px;
        color: #ea4335;
      }
    }

    > h3 {
      font-size: 16px;
      color: #202124;
      font-weight: 400;
      line-height: 20px;
      display: flex;

      span {
        color: #1a73e8;
        font-size: 12px;
      }

      > div {
        flex: 1;
        // display: flex;
        // flex-wrap: wrap;
      }

      .score {
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        color: #5f6368;
        margin-left: 10px;

        i {
          display: block;
          width: 12px;
          height: 12px;
          background: url(../../assets/image/google/star.png) no-repeat;
          background-size: 100%;
          margin-left: 6px;

          + i {
            margin-left: 1px;
          }

          &.half {
            background: url(../../assets/image/google/star2.png) no-repeat;
            background-size: 100%;
          }
        }
      }
    }

    .change-room-type {
      display: flex;
      align-items: center;

      span {
        line-height: 20px;
        background: #e9f3ec;
        padding: 0 6px;
        margin-left: 10px;
        border-radius: 4px;
        color: #327e46;
        font-size: 12px;
      }

      .current-room {
        border: 1px solid #dadce0;
        padding: 10px;
        font-size: 12px;
        color: #202124;
        line-height: 1.6;
        display: flex;
        align-items: center;
        border-radius: 10px;

        i {
          min-width: 6px;
        }
      }
    }

    .equipment {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      padding-bottom: 15px;

      p {
        font-size: 14px;
        margin: 0 16px 10px 0;
        display: flex;
        line-height: 16px;
        align-items: center;
        color: #70757a;
      }
    }

    .date-range-wrap {
      border-bottom: 1px solid #dadce0;
      border-top: 1px solid #dadce0;

      .date-range {
        display: flex;
        align-items: center;
        border: 1px solid #dadce0;
        border-radius: 10px;
        margin: 15px 0;
        padding: 0 10px;

        &-content {
          display: flex;
          flex: 1;
          align-items: center;
          color: #202124;
          text-align: center;
          padding: 0 10px;
          height: 50px;

          :deep {
            .Check,
            .Checkright {
              display: none;
            }

            .finaldate,
            .finaldateright {
              font-weight: 400;
            }
          }

          p {
            flex: 1;
            font-size: 14px;
          }

          span {
            flex: 1;
            font-size: 12px;
          }
        }
      }
    }

    .tips {
      height: 46px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      font-size: 12px;

      p {
        flex: 1;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
      }

      span {
        color: #1a73e8;
        font-size: 12px;
      }
    }
  }

  h3.google-title {
    font-size: 18px;
    font-weight: 400;
    color: #202124;
    display: flex;
    align-items: center;
    margin: 0;
  }

  .google-book-guest {
    .guest-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 34px;
      line-height: 34px;

      .guest-select {
        height: 34px;
        border: 1px solid #dadce0;
        border-radius: 10px;
        font-size: 14px;
        color: #202124;
        padding: 0 10px;
        display: flex;
        align-items: center;

        &::after {
          display: block;
          content: "";
          border: 4px solid transparent;
          border-top-color: #202124;
          transform: translate(0, 2px);
          margin-left: 10px;
        }
      }
    }
  }

  .guest-form {
    .guest-form-item {
      border: 1px solid #dadce0;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.08);
      margin-top: 15px;
      border-radius: 10px;
      padding: 10px;

      h4 {
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 14px;
        display: flex;
        align-items: center;
        i {
          margin-right: 4px;
        }
      }

      .guest-user {
        h5 {
          font-size: 14px;
          font-weight: 400;
          margin: 10px 0 10px 0;
          display: flex;
          align-items: center;

          i {
            margin-right: 4px;
          }
        }
        .guest-from-label {
          p {
            color: #5f6368;
            font-size: 14px;
            margin: 0 0 10px;
          }

          input {
            display: block;
            width: 100%;
            height: 44px;
            border: 1px solid #dadce0;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 0 10px;
          }

          + .guest-from-label {
            margin-top: 20px;
          }
        }
      }
    }

    .guest-create {
      display: flex;
      align-items: center;
      height: 16px;
      color: #202124;
      margin-top: 20px;
      margin-bottom: 5px;

      &.center {
        justify-content: center;
      }
    }

    .guest-form-other {
      margin-top: 15px;

      .guest-input {
        border: 1px solid #dadce0;
        height: 44px;
        padding: 0 10px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        overflow: hidden;

        &.guest-phone {
          p {
            margin: 0;
            display: flex;
            align-items: center;
            margin-right: 20px;

            span {
              color: #202124;
              font-size: 15px;
              width: 46px;
              display: block;
            }

            i {
              transform: translate(0, 1px);
            }
          }
        }

        input {
          display: block;
          flex: 1;
          outline: none;
          border: none;
          height: 44px;
          font-size: 15px;

          &::-webkit-input-placeholder {
            color: #dadce0;
          }
        }

        + .guest-input {
          margin-top: 20px;
        }
      }
    }
  }

  .google-book-special {
    padding: 10px;
  }

  .google-special {
    p {
      font-size: 14px;
      color: #000;
      line-height: 1.6;
    }

    .special-ul {
      margin-top: 20px;

      .special-li {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #000;

        + .special-li {
          margin-top: 20px;
        }

        &.choice {
          i {
            border: none;
            &::after {
              display: block;
              width: 100%;
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background: url(../../assets/image/google/choice.png) no-repeat;
              background-size: 100% 100%;
            }
          }
        }

        i {
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid #5f6368;
          border-radius: 2px;
          margin-right: 15px;
          position: relative;
        }
      }
    }

    .special-textarea {
      margin-top: 20px;
      textarea {
        height: 90px;
        border: 1px solid #dadce0;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 90px;
        max-height: 90px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 14px;
        outline: none;
        border-radius: 10px;

        &::-webkit-input-placeholder {
          color: #ccc;
        }
      }
    }

    .policy {
      margin: 20px 0 20px;
      font-size: 14px;
      line-height: 1.6;

      span {
        font-size: 14px;
        color: #1a73e8;
      }
    }
  }

  .google-book-payment {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 64px;
    padding: 15px;
    background: #fff;
    z-index: 20;
    box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    .google-book-pay {
      button {
        width: 140px;
        height: 42px;
        background: #1a73e8;
        outline: none;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        border: none;
        border-radius: 50px;
      }

      &.disable {
        button {
          opacity: 0.5;
        }
      }
    }

    .google-book-price {
      flex: 1;

      .no-availablity {
        color: #999;
        h4 {
          font-weight: 14px;
          font-weight: 400;
          margin: 0;
        }
        p {
          margin: 4px 0 0 0;
          font-size: 12px;
        }
      }

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #202124;
        margin: 0;

        small {
          font-size: 12px;
          color: #202124;
        }
      }

      .google-book-tax {
        font-size: 12px;
        color: #70757a;
        margin-top: 5px;
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  .price-detail-wrap {
    .hoteldetails_box {
      text-align: center;
      padding: 15px 0px;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #d3dce6;
    }

    .OnlineBox {
      padding: 20px 15px 0;
    }
    // .OnlinePrepay {
    //   display: flex;
    //   align-items: flex-start;
    // }

    .OnlinePrepay {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      // margin-top: 30px;
      & + .OnlinePrepay {
        margin-top: 20px;
      }
    }

    .OnlinePrepay_left {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
    }

    .OnlinePrepay_right {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 16px;
      font-weight: bold;
      color: #ff6454;

      span {
        display: block;
        font-size: 12px;
        color: #000;
        font-weight: 400 !important;
        margin-top: 4px;
      }
    }

    .Days_box {
      background: #f4f6f9;
      padding: 7px 10px;
      padding-bottom: 5px;
      margin-top: 10px;
    }

    .JunRoom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .JunRoom_left {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        line-height: 22px;
        font-size: 12px;
        color: #999999;
      }

      .JunRoom_right {
        flex: 1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        line-height: 22px;
        font-size: 12px;
        color: #999999;
      }
    }

    .Includes {
      margin-top: 10px;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      padding-bottom: 20px;

      &.dashed {
        border-bottom: 1px dashed #eceff5;
      }
    }

    .Includes span {
      font-size: 12px;
      color: #999999;
      margin-right: 5px;
    }

    .Includes img {
      width: 16px;
      height: 16px;
    }

    .feesexplainPopup {
      padding: 38px 15px;
      padding-bottom: 20px;
      /* padding: 38px 32px; */
      width: 272px;
    }

    .Includestaxes {
      font-size: 12px;
      color: #000000;
    }

    .OnlineroomPrepay_left {
      display: flex;
      align-items: center;
      img {
        width: 11px;
        display: inline-block;
        margin-left: 4px;
      }
    }

    .fees_item {
      display: flex;
      margin-top: 10px;
    }

    .feesitem_left {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #000000;
    }

    .feesitem_right {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 12px;
      color: #000000;
    }

    .extra {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 40px;

      p {
        font-size: 14px;
        display: flex;
        flex-direction: column;

        small {
          font-size: 12px;
          color: #ccc;
        }
      }
    }
  }
}
</style>