<template>
  <div id="app">
    <keep-alive :include="include">
      <router-view />
    </keep-alive>

    <div class="feedback-fixed" ref="feedbackBtn">
      <img src="./assets/image/share/feedback.png" />
    </div>

    <van-popup v-model="$root.mailboxShow" closeable>
      <h3 style="text-align: center; margin: 18px 0 10px">Customer Service</h3>
      <div class="mailboxPopup">
        <div><img src="./assets/image/share/skype.png" width="22" /></div>
        <div class="mailboxPopup_right">
          <div class="mailboxaddress">{{ $root.info.skype }}</div>
          <div style="font-size: 12px">Beijing time {{ $root.info.time }}</div>
        </div>
      </div>
      <p
        style="
          margin: 0 16px 20px;
          text-align: center;
          font-size: 12px;
          color: #999;
        "
      >
        *You can find us through Skype or Whatsapp for help.
      </p>
    </van-popup>

    <feedback ref="feedback" @hook:mounted="feedbackHandler" />
  </div>
</template>


<script>
import {
  language,
  currency as currencyData,
  languageChoice,
  currencyChoice,
} from "@/shared/data";

import { requireCompanyInfo } from "@/apis/home";
export default {
  data() {
    return {
      showCacheView: true,
      include: [],
      level: 1,
      // uuid: null,
    };
  },
  components: {
    feedback: () => import("@/components/feedback.vue"),
  },
  methods: {
    drag(obj, sent, cb) {
      if (!obj) return;
      const rect = obj.getBoundingClientRect();
      const s = sent || {};
      const l = s.l || 0;
      const r = s.r || window.innerWidth - rect.width;
      const t = s.t || 0;
      const b = s.b || window.innerHeight - rect.height;

      obj.ontouchstart = function (ev) {
        const oEvent = ev;
        const touch = oEvent.touches[0];
        const sentX = touch.clientX - obj.offsetLeft;
        const sentY = touch.clientY - obj.offsetTop;

        obj.isClick = true;

        document.ontouchmove = function (ev) {
          var oEvent = ev;
          const touch = oEvent.touches[0];
          var slideLeft = touch.clientX - sentX;
          var slideTop = touch.clientY - sentY;

          obj.isClick = false;

          if (slideLeft <= l) slideLeft = l;
          if (slideLeft >= r) slideLeft = r;
          if (slideTop <= t) slideTop = t;
          if (slideTop >= b) slideTop = b;

          obj.style.left = slideLeft + "px";
          obj.style.top = slideTop + "px";
        };

        document.ontouchend = function () {
          document.ontouchmove = null;
          document.ontouchend = null;

          if (obj.isClick && cb) {
            cb.call(cb);
          }
        };
        return false;
      };
    },
    feedbackHandler() {
      this.$root.feedback = this.$refs.feedback;

      this.drag(this.$refs.feedbackBtn, null, () => {
        this.$root.feedback.show();
      });
    },
  },
  created() {
    requireCompanyInfo().then((res) => {
      const result = res.data.data;
      this.$root.info = {
        time: result.contactTimes,
        email: result.email,
        tel: result.telephone,
        timeout: result.payTimeout || 20,
        skype: result.skype,
      };
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(n) {
        // 路由缓存 START
        if (!n.name) return;

        const { name } = n;
        const { include } = this;

        if (!include.length && n.name !== "home") {
          this.include.push("home");
        }

        if (n.meta.level < this.level) {
          const penultimate = this.include.length - 1;
          this.include.splice(penultimate, 1, n.name);
        }

        if (include.includes(name)) {
          const index = include.indexOf(name);
          this.include = include.slice(0, index);
        }

        this.include.push(n.name);
        this.level = n.meta.level;

        // 路由缓存 END
        let { locale, currency } = n.query;

        if (locale && language[locale]) {
          localStorage.setItem("language", locale);
        } else if (!localStorage.getItem("language")) {
          locale = languageChoice;
          localStorage.setItem("language", languageChoice);
        }

        this.$i18n.locale = locale;

        if (currency && currencyData[currency]) {
          localStorage.setItem("currency", currency);
        } else if (!localStorage.getItem("currency")) {
          localStorage.setItem("currency", currencyChoice);
        }
      },
    },
  },
};
</script>


<style lang="scss">
@import "./assets/css/Shared.scss";
* {
  font-size: 16px;
}
.zoid-outlet {
  width: 100% !important;
}

.describehotel_item {
  line-height: 1.5 !important;
}

.feedback-fixed {
  position: fixed;
  top: 50%;
  right: 10px;
  width: 70px;
  height: 70px;
  z-index: 50;

  img {
    display: block;
    width: 70px;
    height: 70px;
  }
}

.mailboxPopup {
  padding: 10px 20px 20px;
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: center;
}

.mailboxPopup_right {
  margin-left: 10px;
}

.mailboxaddress {
  color: #00adb2;
  font-size: 14px;
  line-height: 24px;
  // text-align: center;
}

.Beijingtime {
  font-size: 12px;
  line-height: 24px;
  color: #666666;
  text-align: center;
}

.mailboxPopup_right {
  margin-left: 10px;
}

// img {
//   pointer-events: none;
// }
</style>