import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout";
import Home from "@/views/home";
import hotelList from "@/views/hotel/list";
import hotelDetail from "@/views/hotel/detail";
import googleChange from "@/views/google/change";
import googleBook from "@/views/google/book";
import policy from "@/views/info/policy-page";
import predetermined from "@/views/info/predetermined-page";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   component: Layout,
  //   children: [
  {
    // 首页
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "home",
      level: 1
    }
  },
  {
    // 政策
    path: "/policy",
    name: "policy",
    component: policy,
    meta: {
      title: "policy",
      level: 1
    }
  },
  {
    // 政策
    path: "/predetermined",
    name: "predetermined",
    component: predetermined,
    meta: {
      title: "predetermined",
      level: 1
    }
  },
  {
    path: "/hotel",
    component: hotelList,
    name: "hotel",
    meta: {
      title: "list",
      level: 2
    }
  },
  {
    path: "/hotel-detail",
    component: hotelDetail,
    name: "hotelDetail",
    meta: {
      title: "detail",
      level: 3
    }
  },
  {
    path: "/google-change",
    component: googleChange,
    name: "googleChange",
    meta: {
      title: "Change room type",
      level: 5
    }
  },
  {
    path: "/google-book",
    component: googleBook,
    name: "googleBook",
    meta: {
      title: "Hotel Booking",
      level: 4
    }
  },
  {
    path: "/booking",
    name: "booking",
    component: () => import("@/views/reservation/booking"),
    meta: {
      title: "booking",
      level: 4
    }
  },
  {
    path: "/card",
    name: "card",
    component: () => import("@/views/reservation/card"),
    meta: {
      title: "booking",
      level: 5
    }
  },
  {
    path: "/pay",
    name: "pay",
    component: () => import("@/views/reservation/pay"),
    meta: {
      title: "pay",
      level: 10
    }
  },
  {
    path: "/result",
    name: "result",
    component: () => import("@/views/reservation/result"),
    meta: {
      title: "result",
      level: 10
    }
  },
  {
    path: "/order-detail",
    name: "orderDetail",
    component: () => import("@/views/order/detail"),
    meta: {
      title: "orderDetail",
      level: 10
    }
  },
  {
    path: "",
    redirect: "/home"
  }
  //   ],
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  router.app.isBase = from.path === "/";

  // if (app.routerCache && !app.routerCache.length) {
  //   // if (from.name !== "home") {
  //   //   app.routerCache.push("home");
  //   // }

  //   app.routerCache.push(...[from.name, to.name]);
  // }
  next();
});

export default router;
