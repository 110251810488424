<template>
  <div class="hotel-container">
    <page :callback="scroll" ref="page">
      <nav-bar :back="true" @change="navbarChange" />
      <div class="hotel-list-search">
        <div class="hotel-list-search-input" @click="searchPop = true">
          <i class="icon-search"></i>
          <div class="hotel-search-info" v-if="!!searchDefault">
            <h4>{{ searchDefault.city }}</h4>
            <p>
              {{ formatDate(searchDefault.dateRange.start) }} ~
              {{ formatDate(searchDefault.dateRange.end) }} ,
              {{ searchDefault.guest.roomNum }}
              {{ searchDefault.guest.roomNum > 1 ? "Rooms" : "Room" }} ,
              {{ searchDefault.guest.adultNum }}
              {{ searchDefault.guest.adultNum > 1 ? "Guests" : "Guest" }}
            </p>
          </div>
        </div>
      </div>

      <div class="hotel-filter-tools">
        <!-- <div class="hotel-filter-item" @click="triggerFilter('pop', 'sort')">
          <i class="icon-sort"></i>
          <span>{{ $t("hotel.sort") }}</span>
        </div> -->
        <div
          class="hotel-filter-item"
          @click="triggerFilter('pop', 'priceAndStar')"
        >
          <i class="icon-price-star"></i>
          <span>Price Star</span>
        </div>
        <!-- <div class="hotel-filter-item" @click="triggerFilter('pop', 'screen')">
          <i class="icon-screen"></i>
          <span>Screen</span>
        </div> -->
      </div>

      <div class="hotel-list">
        <div class="hotel-empty" v-if="hotelList && !hotelList.length">
          <h4>No available room</h4>
          <p>
            Please reselect the check-in date, price range and check for the
            room availability.
          </p>
        </div>

        <template v-else>
          <div
            class="hotel-list-item"
            v-for="(item, index) in hotelList || []"
            :key="index"
          >
            <div class="hotel-cover">
              <custom-image
                width="105px"
                height="159px"
                :radius="6"
                fit="cover"
                :src="item.cover"
              />
            </div>
            <div class="hotel-info" @click="showDetail(item.hotelId)">
              <h3 class="hotel-title">{{ item.title }}</h3>
              <div class="hotel-star">
                <i
                  v-for="(s, i) in doubleStar(item.star)"
                  :class="{ half: !s }"
                  :key="i"
                ></i>
              </div>
              <div class="hotel-description">{{ item.address }}</div>
              <p class="hotel-position">{{ item.position }}</p>
              <div class="hotel-absolute">
                <!-- <div
                  class="hotel-tips"
                  v-if="item.save != undefined && item.save != ''"
                >
                  <b>SAVE {{ item.save }}%TODAY!</b>
                </div> -->
                <div class="hotel-price" v-if="item.price > 0">
                  <!-- <del > ${{ item.price }} </del> -->
                  <b>${{ item.price }} </b>
                </div>
                <div class="hotel-price" v-else>
                  <span style="color: #ccc">Sold out</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </page>

    <!-- filter pop -->
    <van-popup
      v-model="filterPop"
      position="bottom"
      @close="filterClose"
      :style="{ height: '70%' }"
    >
      <div class="pop-box">
        <div class="pop-navigation">
          <span @click="filterPop = false" class="close-button">Close</span>
          <p>{{ filterStatus | format }}</p>
        </div>
        <div class="pop-container" style="padding-bottom: 71px">
          <div style="padding-bottom: 72px">
            <template v-if="filterStatus === 'sort'">
              <div class="filter-list" style="margin-top: 15px">
                <div
                  class="filter-list-item"
                  v-for="(item, index) in filterOptions.sort"
                  :key="index"
                  :class="{ active: filterValue.sort === index }"
                  @click="filterSortConfirm(index)"
                >
                  {{ item }}
                  <i
                    class="icon-sort-choice"
                    v-if="filterValue.sort === index"
                  ></i>
                </div>
              </div>
            </template>
            <template v-if="filterStatus === 'priceAndStar'">
              <div class="filter-block">
                <h4 class="filter-title bold">
                  Price <span>{{ piceRange }}</span>
                </h4>
                <div class="filter-container">
                  <!-- <p class="filter-price-data"></p> -->
                  <div style="padding: 0 20px">
                    <van-slider
                      v-model="filterValue.price"
                      active-color="#66C976"
                      bar-height="5"
                      inactive-color="#DCEAF7"
                      :min="0"
                      :max="1000"
                      :range="true"
                    >
                      <template slot="button">
                        <div class="slider-button">
                          <span></span>
                          <!-- <div class="slider-button-tip">Under $350</div> -->
                        </div>
                      </template>
                    </van-slider>
                  </div>
                </div>

                <div class="price-option">
                  <p
                    v-for="(range, index) in priceRangeOption"
                    :class="{
                      active: filterValue.price.join('-') === range.join('-'),
                    }"
                    :key="index"
                    @click="triggerPiceRange(range)"
                  >
                    {{ formatPrice(range) }}
                  </p>
                </div>
              </div>
              <div class="filter-block">
                <h4 class="filter-title">Star</h4>
                <div class="filter-container">
                  <div class="filter-star">
                    <div
                      class="filter-star-item"
                      v-for="(item, index) in filterOptions.star"
                      :key="index"
                      @click="
                        filterValue.star =
                          filterValue.star === item ? null : item
                      "
                      :class="{ active: filterValue.star === item }"
                    >
                      {{ item }} <i class="icont-filter-star"></i>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="filterStatus === 'screen'">
              <template v-for="(item, key, index) in filterOptions.screen">
                <div
                  class="filter-block down-line"
                  v-if="item.list && item.list.length"
                  :key="index"
                >
                  <h4 class="filter-title">{{ item.title }}</h4>
                  <div class="filter-container">
                    <div
                      class="filter-list no-margin"
                      :class="[index % 2 === 0 ? 'round' : 'line']"
                    >
                      <div
                        class="filter-list-item"
                        :class="{
                          active: filterValue.screen[key] === screen.key,
                        }"
                        v-for="(screen, screenIndex) in screenStatus[index]
                          ? item.list
                          : item.list.slice(0, 4)"
                        :key="screenIndex"
                        @click="screenClick(screen.key, key)"
                      >
                        <p>{{ screen.label }}</p>
                        <i
                          :class="[
                            filterValue.screen[key] === screen.key
                              ? 'icon-sort-choice-active-round'
                              : 'icon-sort-choice-round',
                          ]"
                          v-if="index % 2 === 0"
                        ></i>
                        <i
                          class="icon-sort-choice"
                          v-else-if="
                            index % 2 !== 0 &&
                            filterValue.screen[key] === screen.key
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div
                    class="showAll"
                    v-if="item.list.length > 4"
                    @click="showAll(index)"
                  >
                    Show {{ screenStatus[index] ? "Less" : "All" }}
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
        <div class="filter-btn-group" v-if="filterStatus !== 'sort'">
          <div @click="filterEliminate()">Eliminate</div>
          <div @click="filterConfirm()">Display results</div>
        </div>
      </div>
    </van-popup>

    <!-- search pop -->
    <div class="search-pop-background">
      <van-popup v-model="searchPop" position="top">
        <search
          :region="regionOption"
          mode="search-block-pop"
          search-btn="Update"
          ref="search"
          @search="search"
          @close="searchPop = false"
        ></search>
      </van-popup>
    </div>
    <currency-pop ref="currency" @change="currencyHandler" />
  </div>
</template>

<script>
import Search from "@/components/Search";
import Page from "@/components/Page";
import currencyPop from "@/components/Currency";
import NavBar from "@/components/Navbar";
import { urlDecode, formatDateENShort } from "@/shared/tools";
import { getPopular, getCountryCities, queryHotel } from "@/apis/home";

/* 
hotelId=51045&
checkInDate=2023-02-20&
checkOutDate=2023-02-21&
adults=2&children=0&
age=&
  locale=en-gb&
currency=USD&
userDevice=desktop&
googleSite=verification&
gSite=verification&
paymentId=&
userCurrency=CAD&
taxPrice=39.96&
totalPrice=396.90&
planId=F5609857611178470378_201052896%5C_382057392&
ruleId=&userCountry=CA&userLanguage=en&
click=hotel&dateType=selected&
packageId=201052896_382057392&
rid=F5609857611178470378&gl=1&
c_type=price&
adType=1&
v=1675409552346.8341.3625
*/
import { currency, currencyChoice, guestStore } from "@/shared/data";

export default {
  name: "hotel",
  components: {
    Search,
    Page,
    NavBar,
    currencyPop,
  },
  watch: {
    filterStatus(n) {
      this.filterPop = !!n;
    },
  },
  filters: {
    format(v) {
      if (!v) return;
      if (v === "priceAndStar") {
        v = "price star";
      }
      const ast = v.split(" ").map((word) => {
        const firstWord = word.slice(0, 1).toUpperCase();
        return firstWord + word.slice(1).toLowerCase();
      });
      return ast.join(" ");
    },
  },
  data() {
    const searchDefault = guestStore();
    return {
      pageIndex: 1,
      pageSize: 10,
      regionOption: {
        popular: [],
        other: [],
      },
      searchDefault,
      priceRangeOption: [
        [0, 50],
        [50, 100],
        [100, 150],
        [150, 200],
        [200, 250],
        [250, 300],
        [300, 350],
        [350, 1000],
      ],
      selectCurrency: currency[currencyChoice],
      searchPop: false,
      filterPop: false,
      filterStatus: null,
      // 筛选框：地区数据
      region: {},
      screenStatus: [],
      filterValue: {
        sort: null,
        price: [0, 1000],
        star: null,
        screen: {},
      },
      filterOptions: {
        sort: [
          "Tuyi Recommended",
          //"Praise first",
          "Highest price",
          "Lowest price",
          "Closest",
        ],
        star: ["≤2", "2-3", "3-4", "4-5"],
        screen: {
          brand: {
            title: "brand",
            list: [
              {
                label: "Aloft",
                key: "Aloft",
              },
              {
                label: "[Plateno Hotels Group] lU Hotel",
                key: "[Plateno Hotels Group] lU Hotel",
              },
              {
                label: "Sheraton",
                key: "Sheraton",
              },
            ],
          },
          bedType: {},
          facilities: {},
          distanceRange: {
            title: "Range distance",
            list: [
              {
                label: "Within 500m",
                key: [0, 500],
              },
              {
                label: "Within 1km",
                key: [0, 1000],
              },
              {
                label: "Within 2 km",
                key: [0, 2000],
              },
              {
                label: "Within 4 km",
                key: [0, 4000],
              },
              {
                label: "Within 8 km",
                key: [0, 8000],
              },
            ],
          },
        },
      },
      // 酒店列表数据
      hotelList: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    piceRange() {
      const [min, max] = this.filterValue.price;
      return this.formatPrice([min, max]);
    },
  },
  activated() {
    this.$refs.search && this.$refs.search.update();
    this.$refs.page && this.$refs.page.reset();
    // 回填筛选数据
    this.searchDateMerge();
  },
  mounted() {},
  methods: {
    navbarChange() {
      this.$refs.page && this.$refs.page.reset();
      this.init();
    },
    doubleStar(star) {
      const double = (star || 0) * 2;
      const result = [];

      for (let i = 1; i <= double; i++) {
        if (i % 2 === 0 || i === double) {
          result.push(i % 2 === 0);
        }
      }

      return result;
    },
    currencyHandler(currency) {
      this.selectCurrency = currency;
    },
    triggerPiceRange([min, max]) {
      this.filterValue.price = [min, max];
    },
    formatPrice([min, max]) {
      let range = "Unlimited";

      if (min === 0 && max !== 1000) {
        range = `Under $${max}`;
      }

      if (min !== 0 && max === 1000) {
        range = `Over $${min}`;
      }

      if (min !== 0 && max !== 1000) {
        range = `$${min} - $${max}`;
      }

      return range;
    },
    formatDate(date) {
      if (!date) return "";
      return formatDateENShort(date);
    },
    /********************* mvc begin *********************/
    //初始化
    init() {
      this.getScreen();
      // 初始化城市数据
      this.getPopulars();
      this.getCountryCities();
    },

    // 读取缓存
    searchDateMerge() {
      const storeSearchData = localStorage.getItem("hotelfilter");
      if (storeSearchData) {
        this.searchDefault = JSON.parse(storeSearchData);
      }
    },

    getScreen() {
      const screen = this.filterOptions.screen;

      // TODO SCREEN 赋值
      // 赋值成功后则行 this.$forceUpdate();

      screen.bedType = {
        title: "Bed type",
        list: [
          {
            label: "One single bed",
            key: 1,
          },
          {
            label: "Two beds",
            key: 2,
          },
          {
            label: "One double bed",
            key: 3,
          },
          {
            label: "Multiple beds",
            key: 4,
          },
        ],
      };
      screen.facilities = {
        title: "facilities",
        list: [
          {
            label: "Indoor swimming pool",
            key: "Indoor swimming pool",
          },
          {
            label: "Outdoor swimming pool",
            key: "Outdoor swimming pool",
          },
          {
            label: "Pets allowed",
            key: "Pets allowed",
          },
          {
            label: "Gymnasium",
            key: "Gymnasium",
          },
          {
            label: "Pick up service",
            key: "Pick up service",
          },
        ],
      };
      this.$forceUpdate();
    },

    //热门城市
    getPopulars() {
      var self = this;
      getPopular().then(function (response) {
        if (response.status == 200) {
          var data = response.data.data || [];
          // const result = [];

          const result = data.map((v, i) => {
            return { name: v.Name };
          });
          // $.each(data, function (i, v) {
          //   var obj = { name: v.Name };
          //   result.push(obj);
          // });

          self.$set(self.regionOption, "popular", result);
        }
      });
    },

    //国家城市
    getCountryCities() {
      var self = this;
      getCountryCities().then(function (response) {
        if (response.status == 200) {
          var data = response.data.data || [];
          const result = data.map((v, i) => {
            var obj = { name: v.Country, list: [] };

            obj.list = (v.CityList || []).map((city) => {
              return { name: city.Name };
            });

            return obj;

            // $.each(v.CityList, function (k, city) {
            //   obj.list.push({ name: city.Name });
            // });
            // result.push(obj);
          });

          // $.each(data, function (i, v) {
          //   var obj = { name: v.Country, list: [] };
          //   $.each(v.CityList, function (k, city) {
          //     obj.list.push({ name: city.Name });
          //   });
          //   result.push(obj);
          // });

          self.$set(self.regionOption, "other", result);
        }
      });
    },

    showDetail(hotelId) {
      this.$router.push({
        path: "/hotel-detail",
        query: {
          hotelId,
          ...urlDecode(this.searchDefault),
        },
      });
    },

    //搜索--传参
    searchHotelList() {
      const params = this.getFilter();
      return queryHotel(params).then((response) => {
        if (response.status == 200) {
          var data = response.data.data;

          if (params.PageIndex == 1) {
            this.hotelList = [];
          }

          const hotelList = (data.items || []).map((v) => {
            var obj = {
              hotelId: v.hotelCode,
              title: v.hotelName,
              cover: v.image || "",
              star: v.star,
              description: v.NearBy,
              position:
                v.distanceShow == ""
                  ? ""
                  : v.distanceShow + " from your location",
              save: "",
              price: v.minPrice,
              priceShow: v.currencySymbol + v.minPrice,
              originalPrice: "",
              address: v.address,
            };
            return obj;
          });

          this.$set(this, "hotelList", this.hotelList.concat(hotelList));
          // 返回分页结果
          return (
            params.PageCount <= params.PageIndex ||
            hotelList.length < params.PageSize
          );
        }
        // 报错默认为结束分页
        return true;
      });
    },

    // 搜索--内部构造传参
    getFilter() {
      const { city, guest, keyword, dateRange, latitude, longitude } =
        this.searchDefault;
      const { roomNum, adultNum, childAges, childNum } = guest;

      const screen = Object.assign(
        {
          brand: "",
          bedType: "",
          distanceRange: [],
          facilities: "",
        },
        this.filterValue.screen
      );

      var filter = {
        City: city,
        RoomNum: roomNum,
        AdultNum: adultNum,
        childNum,
        ChildAges: childAges,
        Keyword: keyword,
        CheckIn: dateRange.start,
        CheckOut: dateRange.end,
        Latitude: latitude,
        Longitude: longitude,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
        ...screen,
      };

      if (this.filterValue.sort != null) {
        switch (this.filterValue.sort) {
          case 0: //途易推荐
            filter.orderFld = "RecommendSort";
            filter.isDesc = false;
            break;
          case 1: //价格最高
            filter.orderFld = "CNYPrice";
            filter.isDesc = true;
            break;
          case 2: //价格最低
            filter.orderFld = "CNYPrice";
            filter.isDesc = false;
            break;
          case 3: //距离最近
            filter.orderFld = "Distance";
            filter.isDesc = false;
            break;
        }
      }
      filter.Start = this.filterValue.star;
      filter.PriceRange = this.filterValue.price;

      // console.log(filter);
      // console.log("filter", filter);
      return filter;
    },
    /********************* mvc end *********************/

    // choiceCurrency(item) {
    //   this.selectCurrency = item.head;
    //   this.currencyShow = false;
    // },

    // 分页
    scroll(pageNumber) {
      this.pageIndex = pageNumber;
      return this.searchHotelList();
    },

    showAll(index) {
      this.screenStatus[index] = !this.screenStatus[index];
      this.$forceUpdate();
    },

    screenClick(key, label) {
      const screen = this.filterValue.screen;

      if (screen[label] === key) {
        delete screen[label];
      } else {
        screen[label] = key;
      }

      this.$forceUpdate();
    },

    // 【筛选】排序确认
    filterSortConfirm(value) {
      this.filterValue.sort = value;
      this.filterConfirm();
    },

    // 【筛选】重置
    filterEliminate() {
      switch (this.filterStatus) {
        case "priceAndStar":
          this.filterValue.star = null;
          this.filterValue.price = [0, 1000];
          break;
        case "screen":
          this.filterValue.screen = {};
          break;
      }

      // this.filterConfirm();
    },

    // 【筛选】确认
    filterConfirm(type) {
      this.filterPop = false;
      this.rePage();
    },

    // 【筛选】确认
    search(e) {
      this.searchPop = false;
      this.searchDefault = e;
      localStorage.setItem("hotelfilter", JSON.stringify(e));
      this.rePage();
    },

    // 重置分页
    rePage() {
      this.pageIndex = 1;
      this.$refs.page && this.$refs.page.reset();
    },

    // 关闭排序弹框
    filterClose() {
      setTimeout(() => {
        this.filterStatus = null;
      }, 200);
    },

    // 排序框不同状态弹框
    triggerFilter(state, value) {
      switch (state) {
        case "pop":
          this.filterStatus = value;
          break;
        case "options":
          return this.filterOptions[this.filterStatus];
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hotel-container {
  .hotel-list-search {
    padding: 0 12px 12px;
    background: #fff;
    display: flex;
    align-items: center;

    .icon-back {
      display: block;
      margin-right: 10px;
    }
  }

  .icon-back {
    width: 18px;
    height: 18px;
    background: url(../../assets/image/mailboxConfirm/return.png) no-repeat;
    background-size: 100%;
    display: block;
  }

  .hotel-list-search-input {
    flex: 1;
    display: flex;
    align-items: center;
    height: 46px;
    box-sizing: border-box;
    padding: 0 12px;
    background: #f5f5f5;
    // margin: 0 0 0 10px;
  }

  .hotel-search-info {
    margin-left: 10px;

    h4 {
      margin: 0;
      font-size: 12px;
    }

    p {
      margin: 4px 0 0 0;
      font-size: 12px;
    }
  }

  .hotel-list-language {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }

  .hotel-filter-tools {
    display: flex;
    height: 40px;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
    color: #323232;

    span {
      font-size: 14px;
    }
  }

  .hotel-filter-item {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: block;
      font-weight: bold;
      margin-left: 10px;
    }

    & + .hotel-filter-item:after {
      display: block;
      content: "";
      width: 1px;
      height: 80%;
      background: #d3dce6;
      position: absolute;
      top: 10%;
      left: 0;
    }
  }

  .icon-sort {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/Index/sort.png) no-repeat;
    background-size: 100%;
    display: block;
  }

  .icon-price-star {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/Index/star.png) no-repeat;
    background-size: 100%;
    display: block;
  }

  .icon-screen {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/Index/screen.png) no-repeat;
    background-size: 100%;
    display: block;
  }

  .hotel-list-item {
    padding: 10px 0;
    margin: 0 15px;
    display: flex;

    & + .hotel-list-item {
      border-top: 1px solid #d3dce6;
    }
  }

  .hotel-absolute {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .hotel-cover {
    margin-right: 10px;
  }

  .hotel-info {
    width: calc(100% - 115px);
    height: 159px;
    position: relative;

    .hotel-title {
      font-size: 18px;
      margin: 0 0 10px;
      font-weight: 400;
      color: #323232;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      /*white-space: nowrap;*/
      width: 100%;
    }
  }

  .hotel-star {
    margin: 0 0 10px;
    display: flex;
    align-items: center;

    i {
      height: 12px;
      width: 12px;
      background: url(../../assets/image/Stars/star.png) repeat-x;
      background-size: 12px;
      display: block;
      margin-right: 3px;

      &.half {
        background: url(../../assets/image/Stars/lack.png) repeat-x;
        background-size: 12px;
      }
    }
  }

  .hotel-description {
    font-size: 12px;
    color: #666666;
    margin: 0 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hotel-position {
    font-size: 12px;
    color: #000;
    margin: 0 0 15px;
  }

  .hotel-tips {
    height: 20px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    b {
      display: block;
      height: 20px;
      padding: 0 10px;
      line-height: 20px;
      font-weight: 400;
      color: #ff6454;
      font-size: 12px;
      background: rgba(#ff6454, 0.05);
    }
  }

  .hotel-price {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    align-items: center;
    color: #ff6600;

    b {
      font-size: 18px;
    }

    del {
      font-size: 12px;
      display: block;
      color: #999999;
      margin-right: 6px;
    }
  }

  .tabShow_Navigation {
    background: #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 18px 15px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    color: #66c976;
    display: flex;
    align-items: center;
    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translate(0, -50%);
    }
  }

  .filter-list.no-margin .filter-list-item {
    padding: 0;
  }

  .filter-list-item > p {
    width: 80%;
    overflow: hidden;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .filter-list.line .filter-list-item + .filter-list-item {
    border-top: 1px solid #d3dce6;
  }

  .icon-sort-choice-round {
    display: block;
    width: 20px;
    height: 20px;
    background: url(../../assets/image/Index/abandon.png) no-repeat;
    background-size: 100%;
  }

  .icon-sort-choice-active-round {
    display: block;
    width: 20px;
    height: 20px;
    background: url(../../assets/image/Index/choice2.png) no-repeat;
    background-size: 100%;
  }

  .filter-list-item {
    height: 48px;
    display: flex;
    padding: 0 15px;
    font-size: 14px;
    color: #000;
    align-items: center;
    justify-content: space-between;
  }

  .filter-list-item.active {
    color: #66c976;
  }

  .icon-sort-choice {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../assets/image/Index/choice1.png) no-repeat;
    background-size: 100%;
  }

  .filter-block {
    padding: 20px 15px 10px;
    &.down-line {
      border-bottom: 1px solid #d3dce6;
    }

    .filter-container {
      margin-top: 12px;
    }
  }

  .filter-title {
    font-weight: 400;
    font-size: 18px;
    color: #000;
    margin: 0 0 16px;
    display: flex;
    align-items: center;
    line-height: 1.2;
  }
  .filter-title.bold {
    font-weight: bold;
  }

  .filter-title span {
    font-weight: 400;
    font-size: 14px;
    display: block;
    margin-left: 6px;
  }

  .filter-price-data {
    font-size: 14px;
    color: #000;
    padding: 10px 0 20px;
    margin: 0;
  }

  .price-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    font-size: 12px;
    color: #000;
  }

  .price-option p {
    width: 80px;
    height: 42px;
    font-size: 12px;
    line-height: 42px;
    text-align: center;
    border: 1px solid #f4f6f9;
    background: #f4f6f9;
    margin: 0;
    transition: all 0.2s ease;
    margin-bottom: 6px;
  }

  .price-option p.active {
    color: #66c976;
    border-color: #66c976;
    background: #e8ffeb;
  }

  .icont-filter-star {
    width: 16px;
    height: 16px;
    background: url(../../assets/image/Index/star2.png) no-repeat;
    background-size: 100%;
    display: block;
    margin-left: 8px;
  }

  .filter-star {
    display: flex;
    align-items: center;
  }

  .filter-star-item {
    flex: 1;
    height: 40px;
    background: #f4f6f9;
    border: 1px solid #f4f6f9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: all 0.2s ease;
    color: #000;
  }

  .filter-star-item.active {
    border-color: #66c976;
    background: #e6f2fe;
    color: #66c976;
  }

  .filter-star-item.active .icont-filter-star {
    background: url(../../assets/image/Index/star3.png) no-repeat;
    background-size: 100%;
  }

  .filter-star-item + .filter-star-item {
    margin-left: 10px;
  }

  .filter-btn-group {
    display: flex;
    align-items: center;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 15px;
    position: fixed;
    background: #fff;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    bottom: 0;
  }

  .filter-btn-group div {
    flex: 1;
    margin-left: 15px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border: 1px solid #66c976;
    font-size: 16px;
    color: #66c976;
    border-radius: 2px;
  }

  .filter-btn-group div:nth-of-type(2) {
    background: #66c976;
    color: #fff;
  }

  .search-pop-background {
    :deep {
      .van-popup {
        background: transparent;
      }
    }
  }

  .showAll {
    padding: 15px 0;
    text-align: center;
    font-size: 14px;
    color: #66c976;
  }

  .slider-button span {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../../assets/image/Hotel/slider.png) no-repeat;
    background-size: 100%;
  }
}
</style>