import { formatTime } from "./tools";

export const bedTypes = {
  0: {
    name: "queen bed",
    nameCN: "大床"
  },
  1: {
    name: "twin bed",
    nameCN: "双床"
  },
  2: {
    name: "queen bed or twin bed",
    nameCN: "大床或双床"
  },
  3: {
    name: "three beds",
    nameCN: "三床"
  },
  4: {
    name: "four beds",
    nameCN: "四床"
  },
  5: {
    name: "single bed",
    nameCN: "单人床"
  },
  6: {
    name: "bunk Bed",
    nameCN: "上下铺"
  },
  7: {
    name: "paving",
    nameCN: "通铺"
  },
  8: {
    name: "tatami",
    nameCN: "榻榻米"
  },
  9: {
    name: "water bed",
    nameCN: "水床"
  },
  10: {
    name: "round bed",
    nameCN: "圆床"
  },
  11: {
    name: "split bed",
    nameCN: "拼床"
  },
  12: {
    name: "child mother bed",
    nameCN: "子母床"
  },
  13: {
    name: "multiple beds",
    nameCN: "多床"
  },
  14: {
    name: "king bed",
    nameCN: "特大床"
  },
  999: {
    name: "other bed types",
    nameCN: "其他床型"
  }
};

export const languageChoice = "en-gb";
export const language = {
  "en-gb": {
    name: "English",
    image: require("../assets/image/country/country.png"),
    short: "en-gb"
  }
  // "zh-cn": {
  //   name: "简体中文",
  //   image: require("../assets/image/country/China.png"),
  //   short: "zh-cn",
  // },
};

export const currencyChoice = "USD";
export const currency = {
  // CNY: {
  //   head: "CNY",
  //   name: " - Chinese Yuan",
  //   unit : '￥'
  // },
  USD: {
    head: "USD",
    name: " - United States Dollar ($)",
    unit: "$"
  }
};

export const guestStore = () => {
  const today = new Date();
  const positionStore = localStorage.getItem("position") || "";
  let searchStore = localStorage.getItem("hotelfilter");
  const search = JSON.parse(searchStore || "{}");

  let startTime = today.getTime();
  let endTime = today.getTime() + 3600 * 24 * 1000;

  if (search.dateRange) {
    let { start, end } = search.dateRange;
    start = new Date(start);
    end = new Date(end);

    startTime = Math.max(start.getTime(), startTime);
    endTime = Math.max(end.getTime(), endTime);
  }

  return {
    keyword: search.keyword,
    city: search.city || positionStore || "",
    dateRange: { start: formatTime(startTime, "-"), end: formatTime(endTime, "-") },
    guest: search.guest || { roomNum: 1, adultNum: 2, childNum: 0, childAges: [] },
    latitude: search.latitude || "10.3029169446392",
    longitude: search.longitude || "124.005737979587"
  };
};
