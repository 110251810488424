<template>
  <div class="home-container">
    <nav-bar />
    <!-- banner -->
    <div class="Rotation_box">
      <van-swipe
        class="my-swipe"
        autoplay="4000"
        :show-indicators="false"
        @change="rotationArrChange"
        ref="swiper"
      >
        <van-swipe-item v-for="(item, index) in rotationArr" :key="index">
          <van-image fit="cover" width="100%" :src="item" />
        </van-swipe-item>
      </van-swipe>
      <div class="paging" v-if="rotationArr.length > 1">
        <span
          v-for="(item, index) in rotationArr"
          :key="index"
          :class="{ active: rotationArrIndex === index + 1 }"
        ></span>
      </div>
    </div>

    <!-- search block -->
    <div class="new-block new-block-first">
      <search :region="regionOption" ref="search" @search="search"></search>
    </div>

    <div class="new-block" style="background: #f4f6f9; padding: 15px 0">
      <div class="new-block-title" style="padding: 10px 15px">
        Recommend Popular Hotels
      </div>
      <div class="new-block-hotel">
        <div style="padding: 0 5px 0 10px">
          <template v-for="(hotel, index) in hotelList">
            <div
              class="new-block-hotel-item"
              v-if="index % 2 === 0"
              :key="index"
              @click="showDetail(hotel.HotelId)"
            >
              <div class="new-block-hotel-img">
                <custom-image
                  width="100%"
                  height="120"
                  fit="cover"
                  :src="hotel.Image"
                />
              </div>
              <h4>{{ hotel.HotelName }}</h4>
              <span v-if="hotel.Price"
                >{{ hotel.CurrencySymbol }}{{ hotel.Price }}</span
              >
              <span v-else class="sold-out">Sold Out</span>
            </div>
          </template>
        </div>
        <div style="padding: 0 10px 0 5px">
          <template v-for="(hotel, index) in hotelList">
            <div
              class="new-block-hotel-item"
              v-if="index % 2 !== 0"
              :key="index"
              @click="showDetail(hotel.HotelId)"
            >
              <div class="new-block-hotel-img">
                <custom-image
                  width="100%"
                  fit="cover"
                  height="120"
                  :src="hotel.Image"
                />
              </div>
              <h4>{{ hotel.HotelName }}</h4>
              <span v-if="hotel.Price"
                >{{ hotel.CurrencySymbol }}{{ hotel.Price }}</span
              >
              <span v-else class="sold-out">Sold Out</span>
            </div>
          </template>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search.vue";
import NavBar from "@/components/Navbar.vue";
import { getCountryCities, getPopular, getTopHotels } from "@/apis/home";
import { urlDecode } from "@/shared/tools";

export default {
  name: "home",
  components: {
    Search,
    NavBar,
  },
  activated() {
    this.$refs.search.update();
  },
  data() {
    return {
      regionOption: {},
      hotelList: [],
      rotationArr: [require("../../assets/image/Index/banner.png")],
      rotationArrIndex: 1,
      date: [],
      loadingShow: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // this.$refs.search.queryCurrentPosition();
  },
  methods: {
    init() {
      this.getPopulars();
      this.getCountryCities();
      this.getTopHotelList();
    },
    getPopulars() {
      getPopular().then((response) => {
        if (response.status == 200) {
          var data = response.data.data || [];

          const result = data.map((v, i) => {
            return { name: v.cityName };
          });

          this.$set(this.regionOption, "popular", result);
        }
      });
    },
    getCountryCities() {
      getCountryCities().then((response) => {
        if (response.status == 200) {
          var data = response.data.data || [];
          const result = data.map((v, i) => {
            var obj = { name: v.countryCN, list: [] };

            obj.list = (v.cityList || []).map((city) => {
              return { name: city.name };
            });

            return obj;
          });

          this.$set(this.regionOption, "other", result);
        }
      });
    },
    //top推荐
    getTopHotelList() {
      var data = { quantity: 10 };
      getTopHotels(data).then((response) => {
        if (response.status == 200) {
          var data = response.data;

          this.hotelList = data.data;
        }
      });
    },
    showDetail(hotelId) {
      const search = this.$refs.search.getResult();
      this.$router.push({
        path: "/hotel-detail",
        query: {
          hotelId,
          ...urlDecode(search),
        },
      });
    },
    search(e) {
      localStorage.setItem("hotelfilter", JSON.stringify(e));
      this.$router.push("/hotel");
    },
    rotationArrChange(index) {
      this.rotationArrIndex = index + 1;
    },
  },
};
</script>

<style scoped lang="scss">
.home-container {
  overflow: hidden;
  .my-swipe {
    height: 200px;
  }

  .Rotation_box {
    width: 100%;
    height: 100%;
    position: relative;

    .paging {
      position: absolute;
      bottom: 60px;
      right: 25px;
      display: flex;
      align-items: center;

      span {
        display: block;
        width: 4px;
        margin-left: 4px;
        height: 4px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        transition: all 0.2s ease;

        &.active {
          width: 12px;
          background: #fff;
          border-radius: 2px;
        }
      }
    }

    ::v-dep {
      .van-swipe-item img {
        width: 100%;
        height: 200px;
      }
    }
  }

  .new-block {
    background: #fff;
    padding: 15px;

    &.new-block-first {
      position: relative;
      z-index: 10;
      border-radius: 10px 10px 0 0;
      margin-top: -50px;
      // overflow: hidden;

      &.new-block-first .search-block {
        height: auto;
      }

      & + .new-block {
        border-top: 10px solid #f4f6f9;
      }
    }

    .new-block-hotel {
      display: flex;
      padding: 10px 0;

      & > div {
        flex: 1;
      }

      h4 {
        font-size: 14px;
        color: #323232;
        font-weight: 400;
        padding: 10px;
        line-height: 20px;
        margin: 0;
      }

      span {
        font-weight: bold;
        color: #ff6454;
        display: block;
        font-size: 18px;
        padding: 0 10px 10px;
      }

      .new-block-hotel-item {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 6px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);

        .sold-out {
          color: rgb(204, 204, 204);
          font-weight: 400;
        }
      }

      .new-block-hotel-img {
        ::v-dep {
          display: block;
        }
        img {
          display: block;
        }
      }
    }

    .new-block-item {
      padding: 15px;
      display: flex;
      align-items: center;

      &.reset {
        padding: 0;
        border-top: none !important;
      }

      & + .new-block-item {
        border-top: 1px solid #d3dce6;
      }
    }
  }
}
</style>