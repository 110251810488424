<template>
  <div class="modal-box">
    <van-popup v-model="display" round>
      <div class="mailboxPopup">
        <div class="mailbox_title" v-if="title">{{ title }}</div>
        <div class="mailbox_content">
          <slot>{{ content }}</slot>
        </div>
        <div class="close" @click="resolveHandler">{{ resolve }}</div>
      </div>
    </van-popup>
  </div>
</template>


<script>
export default {
  data() {
    return {
      display: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    resolve: {
      type: String,
      default: "OK",
    },
  },
  methods: {
    resolveHandler() {
      this.hide();
      this.$emit("resolve");
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
  },
};
</script>

<style scoped lang="scss">
.modal-box {
  .mailboxPopup {
    padding: 20px 0 0 0;
    width: 272px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mailbox.van-popup--round {
    border-radius: 12px;
  }

  .mailbox_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    text-align: center;
  }

  .mailbox_content {
    padding: 0px 20px;
    padding-top: 10px;
    padding-bottom: 17px;
    border-bottom: 1px solid #f4f6f9;
    text-align: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    color: #000000;
  }

  .close {
    padding: 12px 0px;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #66c976;
  }
}
</style>