<template>
  <div class="currency-container">
    <van-popup v-model="showPop" position="bottom" :style="{ height: '100%' }">
      <div class="pop-box">
        <div class="pop-navigation">
          <span class="close-button" @click="showPop = false">Close</span>
        </div>
        <div class="pop-container">
          <div
            class="popup-list-template"
            @click="choiceCurrency(item.head)"
            v-for="(item, index) in currency"
            :key="index"
          >
            <div
              class="popup-list-label"
              :class="{ active: choiceKey == item.head }"
            >
              {{ item.head }}{{ item.name }}
            </div>
            <div class="countryFor_right">
              <img
                v-if="choiceKey == item.head"
                src="../assets/image/country/Select.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { currency, currencyChoice } from "@/shared/data";
export default {
  name: "currencyComponent",
  data() {
    let choiceKey = localStorage.getItem("currency");

    if (!choiceKey) {
      localStorage.setItem('currency', currencyChoice)
      choiceKey = currencyChoice;
    }
    return {
      currency,
      choiceKey,
      showPop: false,
    };
  },
  computed: {
    choice() {
      return this.currency[this.choiceKey] || this.currency[currencyChoice];
    },
  },
  created() {
    // this.$emit("change", {
    //   init: true,
    //   value: this.choice,
    // });
  },
  methods: {
    choiceCurrency(key) {
      this.choiceKey = key;
      localStorage.setItem("currency", key);
      this.$emit("change", {
        init: false,
        value: this.choice,
      });
      this.hide();
    },
    show() {
      this.showPop = true;
    },
    hide() {
      this.showPop = false;
    },
  },
};
</script>


<style scoped lang="scss">
.currency-container {
  .currency_item {
    padding: 15px 0px;
    border-bottom: 1px solid #d3dce6;
    display: flex;
  }
}
</style>