import { formatTime } from "@/shared/tools";

export default {
  filters: {
    complex(num, field) {
      field = typeof field === "string" ? [field, `${field}s`] : field;
      num = num || 0;
      return `${num} ${num > 1 ? field[1] : field[0]}`;
    }
  },
  data() {
    const currency = localStorage.getItem("currency");
    const language = localStorage.getItem("language");
    return {
      currency,
      language,
      queryStore: null,
      defaultKeys: ["hotelId", "checkInDate", "checkOutDate", "room", "adults", "age"]
    };
  },
  created() {
    const { checkInDate, checkOutDate, adults } = this.$route.query;
    const custom = {
      checkInDate,
      checkOutDate,
      adults
    };
    this.queryStore = Object.assign({}, this.$route.query, custom);
  },
  methods: {
    // 计算酒店单天价格
    calcUnitPrice({ range, price, room }) {
      const start = formatTime(range.start);
      const end = formatTime(range.end);
      const days = (end.date - start.date) / (3600 * 24) / 1000;
      const result = [];
      const average = Math.ceil(price / days || 0);
      let total = 0;

      for (let i = 0; i < days; i++) {
        const index = i + 1;
        const current = formatTime(start.date, "-", i);

        const p = index === days ? price - total : average;
        result.push({
          price: p,
          unit: Math.round((p / room) * 1000) / 1000,
          date: current,
          room
        });

        total += average;
      }
      return result;
    },
    // 根据url传入的参数初始化筛选条件
    initSearch() {
      let { checkInDate, checkOutDate, adults, children, age, room } = this.queryStore;
      const guest = { roomNum: 1, adultNum: 2, childNum: 0, childAges: [] };

      if (!checkInDate || !checkOutDate) {
        if (checkInDate) {
          checkOutDate = formatTime(checkInDate, "-", 1);
        } else if (checkOutDate) {
          checkInDate = formatTime(checkOutDate, "-", -1);
        } else {
          const today = new Date();
          checkInDate = formatTime(today, "-");
          checkOutDate = formatTime(today, "-", 1);
        }
      }

      guest.adultNum = adults && adults <= 9 && adults >= 1 ? Number(adults) : 2;
      guest.roomNum = room && room <= 10 && room >= 1 ? Number(room) : 1;
      children = children && children <= 3 && children >= 0 ? Number(children) : 0;
      guest.childNum = children;

      const newAge = new Array(children).fill(null);

      age = (age || "").split(",").filter((item) => item !== "");
      age = age ? age : [];
      guest.childAges = newAge.map((a, ai) => {
        return age[ai] || "<1";
      });

      return {
        guest,
        checkIn: checkInDate,
        checkOut: checkOutDate
      };
    }
  }
};
