<template>
  <div class="policy-container">
    <div class="privacy-container">
      <div class="pop-container" style="height: 100vh">
        <div class="terms_box">
          <div class="terms_title margin_buttom30">Privacy Policy</div>
          <div class="Privacy_item">
            Tuyi Travel Technology Limited ('Tuyi', 'we', 'us', 'our') are
            committed to protecting and respecting your personal data privacy
            and complying with data protection principles and provisions under
            applicable laws.
          </div>
          <div class="Privacy_item">
            We may collect, process, use and disclose your information when you
            use this website www.Tuyi.com and/or the Tuyi App (together, 'Tuyi
            Platform') and the services offered by Tuyi or the third party
            operators (the 'Operators') through Tuyi Platform (the 'Services')
            as described in this Privacy Policy. 'You' and 'your' when used in
            this Privacy Policy includes any person who accesses Tuyi Platform
            or use the Services.
          </div>
          <div class="Privacy_item">
            This Privacy Policy sets out the basis and terms upon which Tuyi
            collects, processes, uses and/or discloses your information that is
            obtained from you when you access Tuyi Platform and/or use the
            Services. Such information may include personal information relating
            to or linked to a specific individual such as name, residential
            address, telephone number, email address, travel document
            information, vehicle rental information, insurance information, age,
            date of birth, or any such information we have requested and you
            have provided through Tuyi Platform ('Personal Information').
          </div>
          <div class="Privacy_item">
            Please read this Privacy Policy carefully. By visiting Tuyi
            Platform, you are consenting to the collection, processing, usage
            and disclosure of your Personal Information as set out in this
            Privacy Policy.
          </div>
        </div>

        <div class="terms_box">
          <div class="terms_title">Scope of Terms</div>
          <ul>
            <li class="Privacy_item">
              Tuyi reserves the right to update, amend or modify the terms of
              this Privacy Policy or any part of it without prior notice, and
              your continued access of Tuyi Platform or use of the Services
              signifies your acceptance of the updated, amended or modified
              Privacy Policy, unless if the changes reduce your rights in which
              case we will ask for your consent. If you do not agree to all the
              terms and conditions in this Privacy Policy and/or any subsequent
              updates, amendments or modifications thereto, you must stop
              accessing or otherwise using Tuyi Platform and the Services.
            </li>
            <li class="Privacy_item">
              Accordingly, please visit this page if you wish to access and view
              the current version of this Privacy Policy.
            </li>
          </ul>
        </div>

        <div class="terms_box">
          <div class="terms_title">Collection of Information</div>
          <div class="Privacy_item">
            We may collect Personal Information about you that you provide to us
            while using Tuyi Platform and information about how you use Tuyi
            Platform including when you open your user account ('User Account'),
            visit Tuyi Platform or make reservations, rentals and/or bookings
            for any intended Services or using the Services. Providing your
            Personal Information to Tuyi is always on a voluntary basis.
            However, we might not be able to provide you with certain Services
            if you choose not to give us your Personal Information. For example,
            we cannot open your user account or make reservations, rentals
            and/or bookings for you if we do not collect your name and contact
            details.
          </div>
          <div class="Privacy_item">
            <div class="item_flex">
              <div class="item_left">1)</div>
              <div class="item_right">
                <div>Opening Your User Account</div>
                <div>
                  When you open with us a User Account or amend any information
                  of your User Account, we may collect your Personal
                  Information, such as your name, email address, username,
                  password and telephone number.
                </div>
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">2)</div>
              <div class="item_right">
                Visit Tuyi Platform, Making Reservations, Rentals, and/or
                Bookings for the Services or Using the Services:
              </div>
            </div>
          </div>
          <div class="Privacy_item">
            When you visit Tuyi Platform (even you have not registered an User
            Account or logged in), make reservations, rentals, and/or bookings
            for any intended Services or use the Services, we may collect and
            process certain information (which may contain your Personal
            Information or may contain non-personally identifiable information
            but nevertheless linked to your Personal Information) including but
            not limited to those set out below:
          </div>
          <div class="Privacy_item">
            <div class="item_flex">
              <div class="item_left">(a)</div>
              <div class="item_right">
                <div>
                  Copies of correspondence (whether by e-mail, instant or
                  personal messaging or otherwise) between you and us, and
                  between you and the Operators;
                </div>
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">(b)</div>
              <div class="item_right">
                <div>
                  Information necessary to fulfil your reservations, rentals,
                  and/or bookings with us and our Operators (including but not
                  limited to travel document information, vehicle rental
                  information, insurance information, age, and date of birth);
                </div>
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">(c)</div>
              <div class="item_right">
                <div>
                  Details of your usage of Tuyi Platform (including traffic
                  data, location data and length of user sessions);
                </div>
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">(d)</div>
              <div class="item_right">
                <div>
                  Feedback on and responses to surveys conducted by Tuyi
                  relating to the Services and newsletters which may be
                  published, circulated or distributed by Tuyi;
                </div>
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">(e)</div>
              <div class="item_right">
                <div>
                  Information automatically collected and stored in our server
                  or the server of our third party services provider by using or
                  accessing to Tuyi Platform (including the log-in name and
                  password for your User Account, your computers Internet
                  Protocol (IP) address, browser type, browser information,
                  device information (including unique device identifier), pages
                  visited, previous or subsequent sites visited).
                </div>
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">3)</div>
              <div class="item_right">
                <div>
                  When you use your mobile devices to visit Tuyi Platform, we
                  may collect information about you and your mobile device as
                  stated above. This can include location information if you
                  have consented and configured your mobile device to send us
                  such information or upload photos with location information to
                  Tuyi Platform. Such location information will be used in the
                  ways and for the purposes as stated in this Privacy Policy.
                  For example, we may use your location to suggest nearby
                  Services during your travel or provide personalised content.
                  You can change your mobile device’s privacy settings at any
                  time to deactivate the function of sharing location
                  information. Please note some features of Tuyi Platform may be
                  affected if you turn off such location sharing function.
                  Should you have any enquiries about your mobile devices’
                  privacy settings, we recommend you contacting your mobile
                  services provider or the manufacturer of your device.
                </div>
              </div>
            </div>
          </div>
          <div class="Privacy_item">
            If you make reservations, rentals, and/or bookings for other
            individuals through the Tuyi Platform, we may request personal
            information about such individual. You must obtain all requisite
            consent of such individuals and ensure they are aware of, understand
            and accept this Privacy Policy prior to providing their personal
            data to Tuyi.
          </div>
        </div>

        <div class="terms_box">
          <div class="terms_title">Storage of Information</div>
          <div class="Privacy_item">
            The Personal Information and other data we collect from you may be
            transferred to, processed, and stored in our servers or the servers
            of our third party services providers. Your Personal Information
            will be stored as long as is necessary to fulfil any of the purposes
            described in this Privacy Policy, or to comply with any (i)
            contractual requirement that are necessary for the provision of the
            Services, and (ii) legal, tax or accounting requirement.
          </div>
          <div class="Privacy_item">
            When it is no longer necessary for us to process your Personal
            Information, we will either delete or anonymise the data or, if this
            is not possible (for example, because your personal information has
            been stored in backup archives), then we will securely store your
            personal information.
          </div>
          <div class="Privacy_item">
            Tuyi will use reasonable endeavours to maintain appropriate
            physical, electronic and organisational procedures to ensure that
            your Personal Information and other data is treated securely and in
            accordance with this Privacy Policy, and to protect such data
            against unauthorized access or unauthorized alteration, disclosure
            or destruction of data.
          </div>
          <div class="Privacy_item">
            Once we have received your information, we will use strict
            procedures and security features to try to prevent unauthorized
            access. Tuyi does not give any representation, warranty or
            undertaking that the Personal Information you provide to us will be
            secure at all times, and to the extent Tuyi has fulfilled its
            obligations under no circumstances shall Tuyi be responsible for any
            losses, damages, costs and expenses which you may suffer or incur
            arising from unauthorised access to or use of your Personal
            Information.
          </div>
          <div class="Privacy_item">
            All payment transactions carried out by us or our chosen third-party
            provider of payment processing services will be encrypted using
            online encryption technology. You are responsible for keeping your
            chosen password confidential and not to share your password with any
            third party.
          </div>
        </div>

        <div class="terms_box">
          <div class="terms_title">Usage of Information</div>
          <div class="Privacy_item">
            We process the Personal Information collected in as far as necessary
            for performance of the contract with and providing services to you.
            Besides, we process the other Personal Information collected on the
            basis of our legitimate interests, which are the further improvement
            of the services and for direct marketing purposes.
          </div>
          <div class="Privacy_item">
            For example, Tuyi will use Personal Information and other data
            collected through Tuyi Platform or when making purchases for the
            Services to create your User Account, to fulfil your reservations,
            rentals, and/or bookings for the Services, to provide you with the
            Services, to continually improve Tuyi Platform and the Services, and
            to contact you in relation to the Services. This includes using your
            Personal Information or such other data to achieve faster purchase
            requests, better customer support and to provide you with timely
            notice of new Services and special offers.
          </div>
        </div>

        <div class="terms_box">
          <div class="terms_title">Disclosure of Information</div>
          <div class="Privacy_item">
            We may from time to time share and disclose your Personal
            Information and other data to third parties, some of whom may be
            located outside your home country. The circumstances under which
            such sharing and disclosure will take place may include without
            limitation, the following:
          </div>

          <div class="Privacy_item">
            <div class="item_flex">
              <div class="item_left">1)</div>
              <div class="item_right">
                <div>
                  To successfully complete your reservations, rentals, and/or
                  bookings, or to otherwise implement our Terms of Use. We may
                  share your information with Operators or third parties
                  (including but not limited to tour operators, activity
                  providers, restaurants, shops, transportation companies,
                  railway companies, cruise companies, amusement parks,
                  telecommunication network operators, hotels, car rental
                  companies, insurance companies, etc.), both within and outside
                  your home country, who deliver or provide goods and services
                  or otherwise act on our behalf.
                </div>
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">2)</div>
              <div class="item_right">
                If you are a visitor, to the relevant Operator in connection
                with a Services which you have made reservations, rentals,
                and/or bookings for or intend to make reservations, rentals,
                and/or bookings for.
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">3)</div>
              <div class="item_right">
                If you are an Operator, to any visitor in connection with the
                Services you are offering.
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">4)</div>
              <div class="item_right">
                To our third party service providers (including Google
                Analytics), which we engage amongst others for the performance
                of certain services on our behalf, such as web hosting services,
                data analysis, marketing, market research, and to otherwise
                provide you with customer service.
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">5)</div>
              <div class="item_right">
                If and to the extent required by any applicable law, order of
                court or requests by any governmental authority to make such
                disclosure.
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">6)</div>
              <div class="item_right">
                Within the Tuyi group of companies. In case of a corporate
                transaction, in connection with the sale, merger, acquisition,
                or other corporate reorganization or restructuring of our
                corporation, your Personal Information may be disclosed, shared
                or transferred to the new controlling entity or its authorised
                third party for carrying on our business.
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">7)</div>
              <div class="item_right">
                To our advisors, agencies or other parties concerned in order to
                protect the rights and property of Tuyi.
              </div>
            </div>
            <div class="item_flex">
              <div class="item_left">8)</div>
              <div class="item_right">
                In any other case, to any third parties with your prior written
                consent (and in which case we will make it possible for you to
                withdraw your consent as easily as it was to provide consent).
              </div>
            </div>
          </div>

          <div class="Privacy_item">
            We may also share aggregate or anonymous information with relevant
            third parties, including our advertisers. Such information does not
            contain any Personal Information and will not identify you
            personally. However, in some occasions, these third parties may
            possess information about you or obtain your information from other
            sources. When they combine such information with our aggregate
            information, they may be able to identify you personally.
          </div>
          <div class="Privacy_item">
            Your Personal Information may be transferred outside of your home
            country and outside of the European Union, for the above mentioned
            purposes. If such transfer takes place to a country that does not
            provide an adequate level of protection, Tuyi will use reasonable
            endeavours to ensure that appropriate safeguards are in place. Such
            safeguards include but are not limited to (i) standard contractual
            clauses of the European Commission; (ii) the EU-US and
            Switzerland-US privacy shield; and/or (iii) any other appropriate
            cross-border transfer mechanisms.
          </div>
          <div class="Privacy_item">
            There may be links present on Tuyi Platform which could result in
            you leaving Tuyi Platform and/or being taken to other third party
            websites. You should note that any Personal Information that you
            provide to these third party websites are not subject to this
            Privacy Policy, and Tuyi is not liable for any losses, damages,
            costs or expenses which you may suffer or incur in connection with
            you providing or making available Personal Information or other data
            to such third party websites.
          </div>
        </div>

        <div class="terms_box">
          <div class="terms_title">Cookies</div>
          <div class="Privacy_item">
            Cookies are widely used in order to make websites work, or work more
            efficiently. When you visit our Website, we collect some of your
            Personal Information transmitted to us by your browser via cookies.
            This enables you to access Tuyi Platform and helps us to create
            better user experience for you. You will find more details about
            cookies and similar technologies that we use, in our Cookies Policy.
          </div>
        </div>

        <div class="terms_box">
          <div class="terms_title">Your rights</div>
          <div class="Privacy_item">
            You may at all times access, correct or erase your Personal
            Information through Tuyi Platform via the user portal, under “My
            Account”. Alternatively, you may make your data access, correction
            or erasure request by sending your request by email at
            <b>tuyi_travel@hktuyitrip.com</b> / hazel@xiwantrip.com
          </div>
          <div class="Privacy_item">
            Where mandatory under applicable legislation, you may also request
            restriction of processing of your Personal Information or object to
            processing by sending your request or objection by email at
            privacy@Tuyi.com. You may also request a copy of the information
            that we hold about you by sending your request by email at
            tuyi_travel@hktuyitrip.com /hazel@xiwantrip.com.
          </div>
          <div class="Privacy_item">
            Please contact us via the contact details mentioned below if you
            have a complaint regarding the processing of your Personal
            Information.
          </div>
          <div class="Privacy_item">
            When handling any of these requests described above, we have the
            right to check the identity of the requester to ensure that he/she
            is the person entitled to make the request.
          </div>

          <div class="Privacy_item font-bold margin_buttom30">
            If you have any questions about this Privacy Policy, please contact
            us by email at tuyi_travel@hktuyitrip.com /hazel@xiwantrip.com.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style>
.policy-container + .feedback-fixed {
  display: none;
}
</style>
<style scoped lang="scss">
.policy-container {
  .Navigation {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }

  .PrivacyPolicy_box {
    padding: 30px 0px;
  }

  .Navigation img {
    margin: 20px;
  }

  .privacy-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }

  .margin_buttom30 {
    padding-bottom: 30px !important;
  }

  .terms_box {
    padding: 0px 15px;
    padding-top: 20px;
    box-sizing: border-box;
  }

  .terms_title {
    font-size: 18px;
    padding-bottom: 10px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }

  .item_title {
    font-weight: bold;
  }

  .Privacy_item {
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
  }

  .Privacy_item b {
    font-weight: 400;
    color: #66c976;
  }

  .font-bold {
    font-weight: bold;
  }

  .Privacy_item + .Privacy_item {
    margin-top: 15px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  li {
    margin-top: 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    word-wrap: break-word;
  }

  .PrivacyPolicy_item {
    margin-top: 10px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    text-align: justify;
  }

  .item_flex {
    display: flex;
    margin-top: 5px;
  }
  .item_left {
    padding-right: 10px;
  }
  .item_right {
    flex: 1;
  }
}
</style>