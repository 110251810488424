<template>
  <van-popup
    v-model="popModel"
    position="bottom"
    :style="{ height: '200%' }"
    @close="$emit('update:pop', false)"
  >
    <div class="search-country">
      <div class="xw-container">
        <div class="search-pop-keyword">
          <div class="search-pop-input">
            <i class="icon-search"></i>
            <input type="text" v-model="keyword" @input="search" />
            <i
              class="icon-cancel search-cancel"
              v-if="keyword !== ''"
              @click="reset"
            ></i>
          </div>
          <span class="search-pop-cancel" @click="close">Cancel</span>
        </div>
        <div class="current-location" @click="queryPosition">
          <i class="icon-position" :class="{ search: searchPosition }"></i>
          <p>{{ current || "Current Location" }}</p>
        </div>
      </div>
      <div class="xw-container" v-if="storeKeyword && storeKeyword.length">
        <div class="xw-container-title">
          <p>Recently viewed</p>
          <div class="xw-container-title-tool">
            <span @click="clearStore">Clear records</span>
          </div>
        </div>
        <div class="xw-container-body">
          <div class="records-scroll-list">
            <div
              class="records-item"
              :class="{ active: storeKeywordChoice === item }"
              @click="choiceKeyword(item)"
              v-for="(item, index) in storeKeyword"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="xw-container"
        v-if="defOption.popular && defOption.popular.length"
      >
        <div class="xw-container-title">
          <p>Popular</p>
        </div>
        <div class="xw-container-body">
          <div class="region-list">
            <div
              class="region-item"
              :class="{ active: value === item.name }"
              v-for="(item, index) in defOption.popular"
              @click="choiceRegion(item)"
              :key="index"
            >
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <template v-for="(item, index) in defOption.other">
        <div
          class="xw-container"
          v-if="item.list && item.list.length"
          :key="index"
        >
          <div class="xw-container-title">
            <p>{{ item.name }}</p>
            <div class="xw-container-title-tool" v-if="item.list.length > 9">
              <i
                class="icon-region-more"
                :class="{ 'region-show-all': regionStatus[index] }"
                @click="triggerStatus(index)"
              ></i>
            </div>
          </div>
          <div class="xw-container-body">
            <div class="region-list region-coll">
              <div
                class="region-item"
                :key="cityIndex"
                :class="{ active: value === city.name }"
                v-for="(city, cityIndex) in regionStatus[index]
                  ? item.list
                  : item.list.slice(0, 9)"
                @click="choiceRegion(city)"
              >
                <p>{{ city.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </van-popup>
</template>

<script>
import { debounce } from "@/shared/tools";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    pop: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default() {
        return {
          popular: [],
          other: [],
        };
      },
    },
    choice: {
      type: String,
      default: "",
    },
  },
  data() {
    // console.log(this.options);
    const storeKeyword = JSON.parse(localStorage.getItem("keyword") || "[]");
    const current = localStorage.getItem("position") || "";
    const defOption = JSON.parse(JSON.stringify(this.options));
    return {
      popModel: false,
      defOption,
      storeKeyword,
      storeKeywordChoice: "",
      regionStatus: [],
      keyword: "",
      current,
      searchPosition: false,
    };
  },
  watch: {
    pop(n) {
      this.popModel = n;
    },
    options: {
      deep: true,
      handler(n) {
        this.defOption = JSON.parse(JSON.stringify(n));
      },
    },
  },
  methods: {
    close() {
      this.popModel = false;
    },
    choiceKeyword(keyword) {
      this.storeKeywordChoice = keyword;
      this.keyword = "";
      this.$emit("change", keyword);
      this.$emit("update:pop", !this.pop);
    },
    choiceRegion(name) {
      this.store(name.name);
      this.$emit("change", name.name);
      this.$emit("update:pop", !this.pop);
    },
    clearStore() {
      this.$dialog
        .confirm({
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          message: "Confirm to clear the record?",
          cancelButtonColor: "#ccc",
        })
        .then(() => {
          this.storeKeyword = [];
          localStorage.setItem("keyword", "[]");
        });
    },
    triggerStatus(index) {
      this.regionStatus[index] = !this.regionStatus[index];
      this.$forceUpdate();
    },
    store(keyword) {
      if (!keyword) return;
      const store = localStorage.getItem("keyword") || "[]";
      let storeArray = JSON.parse(store);
      const hasStore = storeArray.indexOf(keyword);
      if (hasStore >= 0) {
        storeArray.splice(hasStore, 1);
      }
      storeArray.unshift(keyword);

      storeArray = storeArray.slice(0, 4);

      this.storeKeywordChoice = "";
      this.storeKeyword = storeArray;
      localStorage.setItem("keyword", JSON.stringify(storeArray));
    },
    search(e) {
      debounce(e, () => {
        const keyword = this.keyword;
        // // 本地缓存关键字
        // if (keyword !== "") {
        //   this.store(keyword);
        // }

        this.searchHandle(keyword);
      });
    },
    searchHandle(keyword) {
      const options = JSON.parse(JSON.stringify(this.options));
      if (keyword) {
        this.defOption.other = (options.other || []).map((item) => {
          return {
            name: item.name,
            list: (item.list || []).filter((l) => l.name.indexOf(keyword) >= 0),
          };
        });

        this.defOption.popular = (options.popular || []).filter(
          (l) => l.name.indexOf(keyword) >= 0
        );
      } else {
        this.defOption = options;
      }

      this.$forceUpdate();
    },
    queryCurrentLocation() {
      return new Promise((resolve) => {
        const _that = this;
        if (navigator.geolocation) {
          const callback = _that.$listeners && _that.$listeners.callback;
          navigator.geolocation.getCurrentPosition(
            async (e) => {
              if (callback) {
                this.current = await callback({
                  latitude: e.coords.latitude,
                  longitude: e.coords.longitude,
                }).catch((e) => {
                  return "";
                });
                resolve();
              }
            },
            async (e) => {
              callback && callback({ error: e.message });
              resolve();
            }
          );
        }
      });
    },
    queryPosition() {
      // this.searchPosition = true;
      // return new Promise((resolve) => {
      //   // 已经获取到定位
      //   if (this.current) {
      //     this.$emit("choice", { name: this.current });
      //     this.$emit("update:pop", !this.pop);
      //     resolve();
      //   } else {
      //     // 获取了定位
      //     this.queryCurrentLocation().finally(() => {
      //       resolve();
      //     });
      //   }
      // }).then(() => {
      //   this.searchPosition = false;
      //   return true;
      // });
    },
    reset() {
      this.keyword = "";
      this.store();
      this.searchHandle();
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes search {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.search-country {
  background: #f4f6f9;
  min-height: 100%;

  .search-pop-cancel {
    font-size: 14px;
  }

  .icon-region-more {
    width: 12px;
    height: 12px;
    background: url(../assets/image/Index/more.png) no-repeat;
    background-size: 100%;
    display: block;

    &.region-show-all {
      transform: rotate(-180deg);
    }
  }

  .icon-position {
    width: 15px;
    height: 15px;
    display: block;
    background: url(../assets/image/Index/map2.png) no-repeat;
    background-size: 100%;

    &.search {
      animation: search 2s infinite;
      opacity: 1;
    }
  }

  .region-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
  .region-item {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f6f9;
    border: 1px solid #f4f6f9;
    padding: 0 8px;
    font-size: 14px;
    width: calc(33.33333% - 6px);
    box-sizing: border-box;
    margin-bottom: 9px;
    text-align: center;
    margin-left: 9px;

    &.active {
      background: #e6f2fe;
      border: 1px solid #66c976;
      color: #66c976;
    }

    p {
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin: 0;
      word-break: break-all;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .region-list {
    .region-item:nth-of-type(1),
    .region-item:nth-of-type(3n + 1) {
      margin-left: 0;
    }

    &.region-coll {
      .region-item {
        background: #fff;
        width: 33.333333%;
        margin-left: 0;
        border: none;
        margin-bottom: 0;
        position: relative;

        &:nth-of-type(1):after,
        &:nth-of-type(3n + 1):after {
          display: none;
        }

        &:nth-of-type(1):before,
        &:nth-of-type(3n + 1):before {
          content: "";
          width: 300%;
          height: 1px;
          background: #d3dce6;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
        }

        &.active {
          color: #66c976;
        }

        &:after {
          width: 1px;
          height: 50%;
          content: "";
          display: block;
          background: #eee;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
        }
      }
    }
  }

  .xw-container {
    padding: 15px 15px 0;
    background: #fff;
    font-size: 14px;
    color: #000;
    overflow: hidden;

    & + .xw-container {
      margin-top: 10px;
    }
  }

  .xw-container-title {
    display: flex;
    align-items: center;
    height: 15px;
    justify-content: space-between;
    padding: 0 0 15px;
  }

  .xw-container-title p {
    font-weight: bold;
  }

  .xw-container-title-tool {
    color: #66c976;

    span {
      font-size: 14px;
    }
  }
}
</style>