import Vue from "vue";
import vant, { Toast } from "vant";
import "amfe-flexible";
import App from "./App.vue";
import router from "./router";
import Image from "./components/Image";
import modal from "@/components/modal";
import i18n from "./shared/I18n";
import "vant/lib/index.css";

Vue.config.productionTip = false;
Vue.component("customImage", Image);

Vue.use(vant);
Vue.component("modal", modal);

Vue.prototype.$toast = Toast;

new Vue({
  i18n,
  router,
  data() {
    return {
      isLogin: false,
      isBase: true,
      info: {},
      mailboxShow: false,
      feedback: {
        show: () => {}
      }
    };
  },
  created() {
    this.validateToken();
  },
  methods: {
    referLoginStatus() {
      this.isLogin = localStorage.getItem("token");
    },
    validateToken() {
      const expiration = localStorage.getItem("expiration");
      const token = localStorage.getItem("token");
      const time = Date.now();

      let result = true;

      if (time >= expiration || !token) {
        result = false;
        localStorage.removeItem("expiration");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      }

      this.referLoginStatus();

      return result;
    }
  },
  render: (h) => h(App)
}).$mount("#app");
