<template>
  <div class="predetermined-container">
    <van-popup
      v-model="showPop"
      position="bottom"
      style="width: 100%; height: 100vh"
    >
      <div class="privacy-container">
        <div class="pop-navigation">
          <!-- <img src="../image/country/close.png" /> -->
          <span class="close-button" @click="hide">Close</span>
        </div>
        <div class="pop-container">
          <div class="terms_box" style="padding-bottom: 15px">
            <div class="terms_title">Terms and conditions</div>
            <div class="Privacy_item">
              <div class="item_title">A. Your Agreement</div>
              <div class="Agreementcontent">
                A.1 This website www. Tuyi .com is operated by Hong Kong Tuyi
                Travel Limited, a Hong Kong incorporated company. Please read
                these terms of use (“this Terms of Use”) carefully before using
                the Tuyi Platform and the services offered by Tuyi Travel
                Limited, its affiliated companies (together, “ Tuyi ”) or the
                third-party operators (the “Operator”) through the Tuyi Platform
                (the “Services”). “You” and “your” when used in this Terms of
                Use includes (1) any person who accesses the Tuyi Platform and
                (2) persons for whom you make a purchase of the Services.
              </div>
            </div>

            <div
              class="Privacy_item"
              v-for="(item, index) in PredeterminedTermsArr"
              :key="index"
            >
              <div class="item_title">{{ item.title }}</div>
              <div v-for="(item1, index1) in item.details" :key="index1">
                <div
                  class="Agreementcontent"
                  v-if="item1.sontitle !== ''"
                  v-html="item1.sontitle"
                ></div>
                <div
                  class="item_flex"
                  v-for="(items, indexs) in item1.detailsArr"
                  :key="indexs"
                >
                  <div class="item_left">{{ items.detailsIndex }}</div>
                  <div class="item_right" v-html="items.detailsItem"></div>
                </div>
              </div>
            </div>

            <div class="Privacy_item">
              <div class="bottom_item">
                Booking related enquiry contact number : 020-37528550 (Guangzhou
                China)
              </div>
              <div class="bottom_item">
                Booking related enquiry contact email address :
                <span class="mailbox" style="color: #66c976"
                  >tuyi_travel@hktuyitrip.com</span
                >
              </div>
              <div class="bottom_item">
                Complaint email address :
                <span class="mailbox" style="color: #66c976">
                  hazel@hktuyitrip.com</span
                >
                / tuyi_travel@hktuyitrip.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPop: false,
      PredeterminedTermsArr: [
        {
          title: "B. Change of Terms of Use",
          details: [
            {
              sontitle: "B.1 Tuyi 's Modifications",
              detailsArr: [
                {
                  detailsIndex: "B.1.1",
                  detailsItem:
                    "Tuyi reserves the right, at its sole discretion, to modify any part of this Terms of Use at any time without prior notice. You should visit this page periodically to review the current Terms of Use to which you are bound.",
                },
                {
                  detailsIndex: "B.1.2",
                  detailsItem:
                    "Your continued use of the Tuyi Platform after any such changes or modifications constitutes your acceptance of the revised Terms of Use. If you do not agree to abide by the revised Terms of Use, do not use or access or continue to use or access the Tuyi Platform and/or the Services. It is your responsibility to regularly check the Tuyi Platform to ascertain if there are any changes to this Terms of Use and to review such changes",
                },
                {
                  detailsIndex: "B.1.3",
                  detailsItem:
                    "In addition, when using the Services, you shall be subject to any additional terms applicable to such Services that may be posted on the page relating to such Services from time to time and the privacy policy adopted by Tuyi from time to time (“the Privacy Policy”). All such terms are hereby expressly incorporated by reference in this Terms of Use.",
                },
              ],
            },
          ],
        },
        {
          title: "C. Access and Use of the Services",
          details: [
            {
              sontitle: "C.1 Ownership of Content",
              detailsArr: [
                {
                  detailsIndex: "C.1.1",
                  detailsItem:
                    "This Tuyi Platform, the domain name (www. Tuyi .com), features, contents and application services offered periodically by Tuyi in connection therewith are owned and operated by Tuyi .",
                },
              ],
            },
            {
              sontitle: "C.2 Provision and Accessibility of Services",
              detailsArr: [
                {
                  detailsIndex: "C.2.1",
                  detailsItem:
                    'Subject to this Terms of Use, Tuyi may either offer to provide the Services by itself or on behalf of the Operators, as described in further detail on the Tuyi Platform. The Services that have been selected by you on the Tuyi Platform are solely for your own use, and not for the use or benefit of any third party. The term "Services" includes but is not limited to the use of the Tuyi Platform, any Services offered by Tuyi by itself or on behalf of the Operators on the Tuyi Platform. Tuyi may change, suspend or discontinue any Services at any time, including the availability of any feature, database or content. Tuyi may also impose limits or conditions on certain Services or restrict your access to any part or all of the Services without notice or liability.',
                },
                {
                  detailsIndex: "C.2.2",
                  detailsItem:
                    "Tuyi does not guarantee that the Services will always be available or uninterrupted. Tuyi will not be liable to you if for any reason the Services are unavailable at any time or for any period. You are responsible for making all arrangements necessary for you to have access to the Services. You are also responsible for ensuring that all persons who access the Services through Internet connection are aware of this Terms of Use and other applicable terms and conditions for the Services, and that they comply with them.",
                },
                {
                  detailsIndex: "C.2.3",
                  detailsItem:
                    "If you link to the Tuyi Platform, Tuyi may revoke your rights to so link at any time, at Tuyi ’s sole discretion. Tuyi reserves the right to require prior written consent before linking to the Tuyi Platform.",
                },
              ],
            },
          ],
        },
        {
          title: "D. Tuyi Platform and Content",
          details: [
            {
              sontitle: "D.1 Use of the Content",
              detailsArr: [
                {
                  detailsIndex: "D.1.1",
                  detailsItem:
                    "All materials displayed or performed on the Tuyi Platform including but not limited to text, data, graphics, articles, photographs, images, illustrations, video, audio and other materials (“Content”) are protected by copyright and/or other intellectual property rights. This Tuyi Platform and the Content are intended solely for your personal and non-commercial use of the Services and may only be used in accordance with this Terms of Use.",
                },
                {
                  detailsIndex: "D.1.2",
                  detailsItem:
                    "If Tuyi agrees to grant you access to the Tuyi Platform and/or the Content, such access shall be non-exclusive, non-transferable and limited license to access the Tuyi Platform in accordance with this Terms and Use. Tuyi may, at its absolute discretion and at any time, without prior notice to you, amend or remove or alter the presentation, substance or functionality of any part or all of the Content from the Tuyi Platform.",
                },
                {
                  detailsIndex: "D.1.3",
                  detailsItem:
                    "You shall abide by all copyright notices, trademark rules, information, and restrictions contained in the Tuyi Platform and the Content accessed through the Tuyi Platform, and shall not use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purposes whatsoever the Tuyi Platform or the Content or third party submissions or other proprietary rights not owned by you without the express prior written consent of the respective owners, or in any way that violates any third party rights.",
                },
              ],
            },
            {
              sontitle:
                "D.2 Tuyi ’s Liability for the Tuyi Platform and Content",
              detailsArr: [
                {
                  detailsIndex: "D.2.1",
                  detailsItem:
                    "Tuyi cannot guarantee the identity of any other users with whom you may interact with in the course of using the Tuyi Platform. Tuyi cannot guarantee the authenticity and accuracy of any content, materials or information which other users or the Operators may provide. All Content accessed by you using the Tuyi Platform is at your own risk and you will be solely responsible for any damage or loss to any party resulting therefrom.",
                },
                {
                  detailsIndex: "D.2.2",
                  detailsItem:
                    "Under no circumstances will Tuyi be liable in any way for any Content, including but not limited to any errors or omissions in any Content, or any loss or damage of any kind incurred in connection with the use of or exposure to any Content posted, emailed, accessed, transmitted, or otherwise made available via the Tuyi Platform.",
                },
              ],
            },
          ],
        },
        {
          title: "E. Intellectual Property Rights",
          details: [
            {
              sontitle: "E.1 Intellectual Property",
              detailsArr: [
                {
                  detailsIndex: "E.1.1",
                  detailsItem:
                    "All intellectual property rights subsisting in respect of the Tuyi Platform belong to Tuyi or have been licensed to Tuyi for use on the Tuyi Platform. This Tuyi Platform, the Services and the Content are protected by copyright and other intellectual property rights as collective works and/or compilations, pursuant to applicable copyright laws, international conventions, and other intellectual property laws. You undertake that:",
                },
                {
                  detailsIndex: "(a)",
                  detailsItem:
                    "You shall not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works based on, distribute, perform, display, or in any way exploit, any part of the Tuyi Platform and the Content, software, materials, or the Services in whole or in part;",
                },
                {
                  detailsIndex: "(b)",
                  detailsItem:
                    " You shall only download or copy the Content (and other items displayed on the Tuyi Platform or related to the Services) for personal and non-commercial use only, provided that you maintain all copyright and other notices contained in such Content; and ",
                },
                {
                  detailsIndex: "(c)",
                  detailsItem:
                    "You shall not store any significant portion of any Content in any form. Copying or storing of any Content other than personal and non-commercial use is expressly prohibited without prior written permission from Tuyi or from the copyright holder identified in such Contents copyright notice.",
                },
              ],
            },
          ],
        },
        {
          title: "F. User Submissions",
          details: [
            {
              sontitle: "F.1 Uploading of Information",
              detailsArr: [
                {
                  detailsIndex: "F.1.1",
                  detailsItem:
                    "In the course of accessing the Tuyi Platform or using the Services, you may provide information which may be used by Tuyi and/or the Operators in connection with the Services and which may be visible to other users of the Tuyi Platform. You understand that by posting information or content on the Tuyi Platform or otherwise providing content, materials or information to Tuyi and/or the Operators in connection with the Services (“User Submissions”):",
                },
                {
                  detailsIndex: "(a)",
                  detailsItem:
                    "You hereby grant to Tuyi and the Operators a non-exclusive, worldwide, royalty free, perpetual, irrevocable, sub-licensable and transferable right to use and fully exploit such User Submissions, including all related intellectual property rights subsisted thereon, in connection with providing the Services and operating the Tuyi Platform and Tuyi ’s business, including but not limited to the promotion and redistribution of part or all of the Services and derivative works thereof in any media formats and through any media channels; ",
                },
                {
                  detailsIndex: "(b)",
                  detailsItem:
                    " You agree and authorize Tuyi to use your personal data in accordance with the Privacy Policy in effect from time to time; ",
                },
                {
                  detailsIndex: "(c)",
                  detailsItem:
                    "You hereby grant each user of the Tuyi Platform a non-exclusive license to access your User Submissions through the Tuyi Platform, and to use, modify, reproduce, distribute, prepare derivative works of, display and perform such User Submissions as permitted through the functionality of the Tuyi Platform and under this Terms of Use; ",
                },
                {
                  detailsIndex: "(d)",
                  detailsItem:
                    "You acknowledge and agree that Tuyi retains the right to reformat, modify, create derivative works of, excerpt, and translate any User Submissions submitted by you. For clarity, the foregoing license grant to Tuyi does not affect your ownership of or right to grant additional non-exclusive licenses to the material in the User Submissions, unless otherwise agreed in writing;  ",
                },
                {
                  detailsIndex: "(e)",
                  detailsItem:
                    "You hereby represent and warrant that any content in your User Submission (including but not limited to text, graphics and photographs) do not infringe any applicable laws, regulations or any third party rights; and ",
                },
                {
                  detailsIndex: "(f)",
                  detailsItem:
                    "That all the User Submissions publicly posted or privately transmitted through the Tuyi Platform is the sole responsibility of you and that Tuyi will not be liable for any errors or omissions in any content.",
                },
              ],
            },
          ],
        },
        {
          title: "G. Users Representations, Warranties and Undertakings",
          details: [
            {
              sontitle: "G.1 Use of the Tuyi Platform and the Services",
              detailsArr: [
                {
                  detailsIndex: "G.1.1",
                  detailsItem:
                    "You represent, warrant and undertake to Tuyi that you will not provide any User Submissions or otherwise use the Tuyi Platform or the Services in a manner that:",
                },
                {
                  detailsIndex: "(a)",
                  detailsItem:
                    "Infringes or violates the intellectual property rights or proprietary rights, rights of publicity or privacy, or other rights of any third party; or",
                },
                {
                  detailsIndex: "(b)",
                  detailsItem:
                    "Violates any law, statute, ordinance or regulation; or",
                },
                {
                  detailsIndex: "(c)",
                  detailsItem:
                    "Is harmful, fraudulent, deceptive, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or otherwise objectionable; or",
                },
                {
                  detailsIndex: "(d)",
                  detailsItem:
                    "Constitutes libel, impersonates any person or entity, including but not limited to any employee or representative of Tuyi ; or",
                },
                {
                  detailsIndex: "(e)",
                  detailsItem:
                    "Contains a virus, trojan horse, worm, time bomb, or other harmful computer code, file, or program.",
                },
              ],
            },
            {
              sontitle: "G.2 Removal of User Submissions",
              detailsArr: [
                {
                  detailsIndex: "G.2.1",
                  detailsItem:
                    "Tuyi reserves the right to remove any User Submissions from this Tuyi Platform at any time, for any reason including but not limited to, receipt of claims or allegations from third parties or authorities relating to such User Submission or if Tuyi is concerned that you may have breached any of the preceding representations, warranties or undertakings, or for no reason at all.",
                },
              ],
            },
            {
              sontitle: "G.3 Responsibility for User Submissions",
              detailsArr: [
                {
                  detailsIndex: "G.3.1",
                  detailsItem:
                    "You remain solely responsible for all User Submissions that you upload, post, email, transmit, or otherwise disseminate using, or in connection with, the Tuyi Platform.",
                },
                {
                  detailsIndex: "G.3.2",
                  detailsItem:
                    "You acknowledge and agree that you shall be solely responsible for your own User Submissions and the consequences of posting or publishing all of your User Submissions on the Tuyi Platform. You represent, warrant and undertake to Tuyi that:",
                },
                {
                  detailsIndex: "(a)",
                  detailsItem:
                    "You own or have the necessary rights, licenses, consents, releases and/or permissions to use and authorize Tuyi to use all copyright, trademark or other proprietary or intellectual property rights in and to any User Submission to enable inclusion and use thereof as contemplated by the Tuyi Platform and this Terms of Use; and ",
                },
                {
                  detailsIndex: "(b)",
                  detailsItem:
                    "Neither the User Submissions nor your posting, uploading, publication, submission or transmittal of the User Submission or Tuyi ’s use of the User Submissions, or any portion thereof, on or through the Tuyi Platform and/or the Services will infringe, misappropriate or violate any third party’s patent, copyright, trademark, trade secret, moral rights or other proprietary or intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law, rule or regulation.",
                },
                {
                  detailsIndex: "G.3.3",
                  detailsItem:
                    "You are responsible for all of your activity in connection with using the Tuyi Platform and/or the Services. You further represent, warrant and undertake to Tuyi that you shall not:",
                },
                {
                  detailsIndex: "(a)",
                  detailsItem:
                    "Conduct any fraudulent, abusive, or otherwise illegal activity which may be grounds for termination of your right to access or use the Tuyi Platform and/or the Services; or",
                },
                {
                  detailsIndex: "(b)",
                  detailsItem:
                    "sell or resell any products, services or reservation obtained from or via the Tuyi Platform;",
                },
                {
                  detailsIndex: "(c)",
                  detailsItem:
                    "use the Tuyi Platform for commercial or competitive activity or purposes, or for the purpose of making speculative, false or fraudulent bookings or any reservations in anticipation of demand; ",
                },
                {
                  detailsIndex: "(d)",
                  detailsItem:
                    "Post or transmit, or cause to be posted or transmitted, any communication or solicitation designed or intended to obtain password, account, or private information from any other user of the Tuyi Platform; or ",
                },
                {
                  detailsIndex: "(e)",
                  detailsItem:
                    "Violate the security of any computer network, crack passwords or security encryption codes, transfer or store illegal material (including material that may be considered threatening or obscene), or engage in any kind of illegal activity that is expressly prohibited; or ",
                },
                {
                  detailsIndex: "(f)",
                  detailsItem:
                    'Run maillist, listserv, or any other form of auto-responder, or "spam" on the Tuyi Platform, or any processes that run or are activated while you are not logged on to the Tuyi Platform, or that otherwise interfere with the proper working of or place an unreasonable load on the Tuyi Platform’s infrastructure; or ',
                },
                {
                  detailsIndex: "(g)",
                  detailsItem:
                    'Use manual or automated software, devices, or other processes to "crawl," "scrape," or "spider" any page of the Tuyi Platform; or',
                },
                {
                  detailsIndex: "(h)",
                  detailsItem:
                    "Decompile, reverse engineer, or otherwise attempt to obtain the source code of Tuyi Platform.",
                },
                {
                  detailsIndex: "G.3.4",
                  detailsItem:
                    "You will be responsible for withholding, filing, and reporting all taxes, duties and other governmental assessments associated with your activity in connection with using the Tuyi Platform and/or the Services.",
                },
              ],
            },
          ],
        },

        {
          title: "H. Registration and Security",
          details: [
            {
              sontitle: "H.1 Opening of the Tuyi Account",
              detailsArr: [
                {
                  detailsIndex: "H.1.1",
                  detailsItem:
                    "In the course of using the Services, you may be required to open and maintain an account with Tuyi (“ Tuyi Account”).",
                },
              ],
            },
            {
              sontitle: "H.2 Provision of Personal Information",
              detailsArr: [
                {
                  detailsIndex: "H.2.1",
                  detailsItem:
                    "As a condition to using some aspects of the Services, you may be required to register with Tuyi and select a password and user name (“ Tuyi User ID”). If you are accessing the Services through a Third Party Website or service, Tuyi may require that your Tuyi User ID be the same as your user name for such Third Party Website or service.",
                },
                {
                  detailsIndex: "H.2.2",
                  detailsItem:
                    "You shall provide Tuyi with accurate, complete, and updated registration information. Failure to do so shall constitute a breach of this Terms of Use, which may result in immediate termination of your Tuyi Account.",
                },
                {
                  detailsIndex: "H.2.3",
                  detailsItem:
                    "You represent that you shall not: (a) Select or use as a Tuyi User ID a name of another person with the intent to impersonate that person; or (b) Use as a Tuyi User ID a name subject to any rights of a person other than you without appropriate authorization.",
                },
                {
                  detailsIndex: "H.2.4",
                  detailsItem:
                    "Tuyi reserves the right to refuse registration of or to cancel a Tuyi Account at its sole discretion. You shall be responsible for maintaining the confidentiality of your password.",
                },
              ],
            },
          ],
        },

        {
          title: "I. Booking Confirmation, Tickets, Vouchers, Fees and Payment",
          details: [
            {
              sontitle: "I.1 Booking Confirmation",
              detailsArr: [
                {
                  detailsIndex: "I.1.1",
                  detailsItem:
                    "Certain Services are stated to be subject to instant confirmation. Other than these Services, any required time for confirmation as stated on the Tuyi Platform is solely for reference only. Actual time required for confirmation may vary.",
                },
              ],
            },
            {
              sontitle: "I.2 Purchase and Use of the Vouchers",
              detailsArr: [
                {
                  detailsIndex: "I.2.1",
                  detailsItem:
                    "Through the Tuyi Platform, once the booking have been confirmed, end user will receive a vouchers. Subject to the policy of the relevant Operator, you will receive an email confirmation of your purchase that will contain a Voucher confirmation number (“Confirmation Number”).",
                },
                {
                  detailsIndex: "I.2.2",
                  detailsItem:
                    "In order to use your Voucher, you must appear in person at the meeting point designated by the relevant Operator on time, and present such documents (ID card/passport) and/or information as may be required by the Operator, that may include your Confirmation Number. If you fail to appear on time or to provide the required documents or information, no refunds will be granted.",
                },
                {
                  detailsIndex: "I.2.3",
                  detailsItem:
                    "An Operator may also require you to provide an identification document bearing your photo in order to use your Voucher. Neither Tuyi nor the Operator is responsible for lost, destroyed or stolen Vouchers or Confirmation Numbers. Vouchers will be void if the relevant Services to be provided are prohibited by law. If you attempt to use a Voucher in an unlawful manner (e.g., you attempt to use a Voucher for wine tasting when you are under the legal age to do so), the respective Operator may refuse to accept your Voucher, and no refunds will be granted.",
                },
              ],
            },
            {
              sontitle: "I.3 Terms of the Vouchers",
              detailsArr: [
                {
                  detailsIndex: "I.3.1",
                  detailsItem:
                    "The Terms of Use for each Voucher may vary amongst Operators and any restrictions that apply to the use of such Voucher, including but not limited to a minimum age requirement, will be conveyed to you at the time of purchase on the Tuyi Platform.",
                },
                {
                  detailsIndex: "I.3.2",
                  detailsItem:
                    "Vouchers are admission tickets to one-time events ('Events'): the date(s) on which a Voucher can be used will be stated on the Voucher. If you do not use your Vouchers on or by the date(s) noted on such Vouchers, except as expressly set forth therein, no refunds will be granted.",
                },
              ],
            },
            {
              sontitle: "I.4 Cancellation of Vouchers",
              detailsArr: [
                {
                  detailsIndex: "I.4.1",
                  detailsItem:
                    "You may cancel your Voucher by contacting Tuyi customer service within the cancellation period, as stated at the time of purchase on the Tuyi Platform. Cancellation windows vary on a case by case basis. A Voucher canceled with the required notice will be refunded in full to the credit card you used to purchase such Voucher.",
                },
                {
                  detailsIndex: "I.4.2",
                  detailsItem:
                    "The Operator, not Tuyi , is the offeror of the Services for the Events, to which the Vouchers correspond to, and is solely responsible for accepting or rejecting any Voucher you purchase, as related to all such Services.",
                },
                {
                  detailsIndex: "I.4.3",
                  detailsItem:
                    "Please directly consult with the Tuyi Travel if you have any enquiries or complaints in respect of the Service you received in connection with your Voucher. Except as expressly set forth herein, all fees paid for Vouchers are non-refundable. Prices quoted for Vouchers are in the currency stated on the Tuyi Platform at the time prior to purchase.",
                },
                {
                  detailsIndex: "I.4.4",
                  detailsItem:
                    "If an Event which you have purchased a Voucher for is canceled by the Operator, Tuyi will notify you as soon as reasonably practicable, and will process a full refund to the credit card you used to purchase such Voucher.",
                },
              ],
            },
            {
              sontitle: "I.5 Required Assistance",
              detailsArr: [
                {
                  detailsIndex: "I.5.1",
                  detailsItem:
                    'If you attempt to use a Voucher in accordance with this Terms of Use and the additional terms and conditions applicable to such Voucher and you are unable to do so (due to the fault of the Operator or otherwise), please contact Tuyi at <span class="mailbox" style="color : #66C976">Tuyi _travel@hk Tuyi trip.com</span> , and Tuyi will try to liaise with the Operator for an appropriate remedy.',
                },
              ],
            },
            {
              sontitle: "I.6 Additional Charges",
              detailsArr: [
                {
                  detailsIndex: "I.6.1",
                  detailsItem:
                    "Tuyi reserves the right to require payment of fees or charges for any Services offered by Tuyi . You shall pay all applicable fees or charges, as described on the Tuyi Platform in connection with such Services selected by you.",
                },
              ],
            },
            {
              sontitle: "I.7 Modifications to Charges",
              detailsArr: [
                {
                  detailsIndex: "I.7.1",
                  detailsItem:
                    "Tuyi reserves the right to change its price list for fees or charges at any time, upon notice to you, which may be sent to you by email or posted on the Tuyi Platform. Your use, or continued use, of the Services offered by Tuyi following such notification constitutes your acceptance of any new or revised fees or charges.",
                },
              ],
            },
            {
              sontitle: "I.8 Tuyi ’s Rights and Obligations",
              detailsArr: [
                {
                  detailsIndex: "I.8.1",
                  detailsItem:
                    "Tuyi reserves the right to deny and cancel bookings or purchases of any Services that are deemed in violation of this policy. Such a determination is at Tuyi ’s sole discretion.",
                },
                {
                  detailsIndex: "I.8.2",
                  detailsItem:
                    "Tuyi intends to offer or procure the Operators to offer the Services to you at the best price available on the market. You acknowledge and agree that all taxes and additional fees for particular Services that may be payable for using the Services are expressly excluded in determining the best price.",
                },
                {
                  detailsIndex: "I.8.3",
                  detailsItem:
                    "Whilst the Operators are required to provide Tuyi with accurate and updated prices of the Services on the Tuyi Platform, Tuyi cannot guarantee that all prices for the Services provided by the Operators are accurate and updated at all times.",
                },
              ],
            },
            {
              sontitle:
                "I.9 The above terms and conditions & return policies are appliable to all Tuyi users worldwide.",
            },
          ],
        },
        {
          title: "J. Indemnity",
          details: [
            {
              sontitle: "J.1 Tuyi 's Indemnification",
              detailsArr: [
                {
                  detailsIndex: "J.1.1",
                  detailsItem:
                    "You will indemnify and hold Tuyi , our holding companies, subsidiaries, affiliates, officers, directors and employees harmless from, including but not limited to all damages, liabilities, settlements, costs and attorney’s fees, claims or demands made by any third party due to or arising out of your access to the Tuyi Platform, use of the Tuyi Platform, your violation of this Terms of Use, or the infringement of any intellectual property or other right of any person or entity by you or any third party using your Tuyi User ID.",
                },
              ],
            },
            {
              sontitle: "J.2 Tuyi 's  Involvement",
              detailsArr: [
                {
                  detailsIndex: "J.2.1",
                  detailsItem:
                    "Tuyi may, if necessary, participate in the defense of any claim or action and any negotiations for settlement. You will not make any settlement that may adversely affect the rights or obligations of Tuyi without Tuyi 's prior written approval. Tuyi reserves the right, at its expense and upon notice to you to assume exclusive defense and control of any claim or action.",
                },
              ],
            },
          ],
        },
        {
          title: "K.1 Parties’ Relationship",
          details: [
            {
              sontitle: "",
              detailsArr: [
                {
                  detailsIndex: "K.1.1",
                  detailsItem:
                    "Tuyi has no special relationship with or fiduciary duty to you for accessing and using the Tuyi Platform and the Content. You acknowledge that Tuyi has no control over, and no duty to take any action regarding: ",
                },
                {
                  detailsIndex: "(a)",
                  detailsItem: "Which users gain access to this Tuyi Platform;",
                },
                {
                  detailsIndex: "(b)",
                  detailsItem: "What content you access via the Tuyi Platform;",
                },
                {
                  detailsIndex: "(c)",
                  detailsItem: "What effects the Content may have on you;",
                },
                {
                  detailsIndex: "(d)",
                  detailsItem: "How you may interpret or use the Content; and",
                },
                {
                  detailsIndex: "(e)",
                  detailsItem:
                    "What actions you may take as a result of having been exposed to the Content.",
                },
              ],
            },
            {
              sontitle: "K.2 Services and Comments",
              detailsArr: [
                {
                  detailsIndex: "K.2.1",
                  detailsItem:
                    "You acknowledge and agree that the Operators may offer the Services on the Tuyi Platform, and that suggestions or recommendations may be given by the Operators or Users of the Tuyi Platform. Tuyi makes no representations or warranties regarding the accuracy of descriptions anywhere on the Services, or regarding suggestions or recommendations of the Services offered or purchased through the Tuyi Platform.",
                },
              ],
            },
            {
              sontitle: "K.3 Exemption of Liability",
              detailsArr: [
                {
                  detailsIndex: "K.3.1",
                  detailsItem:
                    "In no event will Tuyi , the Tuyi Platform, or any of Tuyi ’s holding companies, subsidiaries, affiliates, officers, directors and/or employees be liable for any loss of profits or any indirect, consequential, special, incidental, or punitive damages arising out of, based on, or resulting from: ",
                },
                {
                  detailsIndex: "(a)",
                  detailsItem: "This Tuyi Platform; or",
                },
                {
                  detailsIndex: "(b)",
                  detailsItem: "This Terms of Use; or",
                },
                {
                  detailsIndex: "(c)",
                  detailsItem:
                    "Any breach of this Terms of Use by you or a third party; or",
                },
                {
                  detailsIndex: "(d)",
                  detailsItem:
                    "Use of the Tuyi Platform, tools or Services we provide related to the business we operate on the Tuyi Platform by you or any third party; or",
                },
                {
                  detailsIndex: "(e)",
                  detailsItem:
                    "Any actual or attempted communication or transaction between users, in each case, even if we have been advised of the possibility of such damages.",
                },
              ],
            },
            {
              sontitle: "",
              detailsArr: [
                {
                  detailsIndex: "K.3.2",
                  detailsItem:
                    "These limitations and exclusions apply without regard to whether the damages arise from: ",
                },
                {
                  detailsIndex: "(a)",
                  detailsItem: "Breach of contract; or",
                },
                {
                  detailsIndex: "(b)",
                  detailsItem: "Breach of warranty; or",
                },
                {
                  detailsIndex: "(c)",
                  detailsItem: "Strict liability; or",
                },
                {
                  detailsIndex: "(d)",
                  detailsItem: "Tort; or",
                },
                {
                  detailsIndex: "(e)",
                  detailsItem: "Negligence; or",
                },
                {
                  detailsIndex: "(f)",
                  detailsItem:
                    "Any other cause of action, to the maximum extent that such exclusion and limitations are not prohibited by applicable law.",
                },
                {
                  detailsIndex: "K.3.3",
                  detailsItem:
                    'This Tuyi Platform, including all content, software, functions, materials and information made available on or accessed through the Tuyi Platform, is provided on an "as is" basis. To the fullest extent permissible by applicable law, Tuyi makes no representations or warranties of any kind, either express or implied, including but not limited to the content on the Tuyi Platform or the materials, information and functions made accessible through the Tuyi Platform, for any of the Services or hypertext links to third parties or for any breach of security associated with the transmission of sensitive information through the Tuyi Platform, or for Operator ability, fitness for a particular purpose, non-infringement, or that the use of the Services will be uninterrupted or error-free.',
                },
                {
                  detailsIndex: "K.3.4",
                  detailsItem:
                    "You acknowledge and agree that any transmission to and from the Tuyi Platform is not confidential and your communications may be read or intercepted by others. You further acknowledge and agree that by submitting communications to Tuyi and by posting information on the Tuyi Platform, including tours and/or activities, no confidential, fiduciary, contractually implied or other relationship is created between you and Tuyi other than pursuant to this Terms of Use.",
                },
                {
                  detailsIndex: "K.3.5",
                  detailsItem:
                    "You acknowledge and agree that you will not hold or seek to hold Tuyi responsible for the content provided by any Operator or third party and you further acknowledge and agree that Tuyi is not a party to any transaction in relation to the Services provided by any Operator or third party. Tuyi has no control over and does not guarantee the safety of any transaction, tours and/or activities or the truth or accuracy of any listing or other content provided by any Operator or third party on the Tuyi Platform.",
                },
              ],
            },
            {
              sontitle: "K.4 Remedies",
              detailsArr: [
                {
                  detailsIndex: "K.4.1 ",
                  detailsItem:
                    "If you are dissatisfied with the Tuyi Platform, do not agree with any part of this Terms of Use, or have any other dispute or claim with or against Tuyi or another user of the Tuyi Platform with respect to this Terms of Use or this Tuyi Platform, your sole and exclusive remedy against Tuyi is to discontinue use of the Tuyi Platform. ",
                },
                {
                  detailsIndex: "K.4.2",
                  detailsItem:
                    "In any event, to the fullest extent permissible by the applicable law, Tuyi ’s liability, and the liability of any member of Tuyi , to you or any third party in any circumstance arising out of or in connection with the Tuyi Platform is limited to the greater of: ",
                },
                {
                  detailsIndex: "(a)",
                  detailsItem:
                    "The amount of fees you paid to Tuyi in the twelve months prior to the action giving rise to liability; or",
                },
                {
                  detailsIndex: "(b)",
                  detailsItem:
                    "Hong Kong Dollars 1000.00 in the aggregate for all claims.",
                },
              ],
            },
          ],
        },
        {
          title: "L. Interaction with Third Parties",
          details: [
            {
              sontitle: "L.1 Links to Third Party Websites",
              detailsArr: [
                {
                  detailsIndex: "L.1.1",
                  detailsItem:
                    "This Tuyi Platform may contain links to third party websites or services (“Third Party Websites”) that are not owned or controlled by Tuyi, or the Services may be accessible by logging in through a Third Party Website. Links to Third Party Websites do not constitute an endorsement or recommendation by Tuyi of such Third Party Websites or the information, products, advertising or other materials available on those Third Party Websites.",
                },
                {
                  detailsIndex: "L.1.2",
                  detailsItem:
                    "When you access Third Party Websites, you do so at your own risk. You hereby represent and warrant that you have read and agree to be bound by all applicable policies of any Third Party Websites relating to your use of the Services and that you will act in accordance with those policies, in addition to your obligations under this Terms of Use.",
                },
                {
                  detailsIndex: "L.1.3",
                  detailsItem:
                    "Tuyi has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any Third Party Websites. In addition, Tuyi will not and cannot monitor, verify, censor or edit the content of any Third Party Website. By using the Services, you expressly relieve and hold Tuyi harmless from any and all liability arising from your use of any Third Party Website.",
                },
                {
                  detailsIndex: "L.1.4",
                  detailsItem:
                    "Your interactions with organizations and/or individuals found on or through the Services including but not limited to the Operators, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals.",
                },
                {
                  detailsIndex: "L.1.5",
                  detailsItem:
                    "You should conduct whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties.",
                },
              ],
            },
            {
              sontitle: "L.2 Tuyi’s Responsibility",
              detailsArr: [
                {
                  detailsIndex: "L.2.1",
                  detailsItem:
                    "You agree that Tuyi shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings. If there is a dispute between participants on this site, or between users and any third party, you understand and agree that Tuyi is under no obligation to become involved. In the event that you have a dispute with one or more other users or third parties, you hereby release Tuyi, its holding companies, subsidiaries, officers, directors, employees, agents, and successors in rights from claims, demands, and damages (actual and consequential) of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes.",
                },
              ],
            },
          ],
        },
        {
          title: "M. Termination",
          details: [
            {
              sontitle: "M.1 Termination by Tuyi ",
              detailsArr: [
                {
                  detailsIndex: "M.1.1",
                  detailsItem:
                    "This Terms of Use shall remain in full force and effect while you use Tuyi Platforms or the Services. Tuyi may terminate or suspend your access to the Services or your membership at any time, for any reason, and without notice, which may result in the forfeiture and destruction of all information associated with your membership. Tuyi may also terminate or suspend any and all Services and access to the Tuyi Platform immediately, without prior notice or liability, if you breach any of the terms or conditions of this Terms of Use.",
                },
              ],
            },
            {
              sontitle: "M.2 Effects of Termination",
              detailsArr: [
                {
                  detailsIndex: "M.2.1",
                  detailsItem:
                    "Upon termination of your Tuyi Account, your right to use the Services, access the Tuyi  Platform, and any Content will immediately cease. All provisions of this Terms of Use which, by their nature, should survive termination, shall survive termination, including but not limited to ownership provisions, warranty disclaimers, and limitations of liability.",
                },
                {
                  detailsIndex: "M.2.2",
                  detailsItem:
                    "If this Terms of Use is terminated as a result of your breach, Tuyi reserves the right to cancel any outstanding Vouchers you may have purchased prior to said termination, without refund or liability to you.",
                },
              ],
            },
          ],
        },
        {
          title: "N. Passports, Visas, Documents, Licenses & Insurances",
          details: [
            {
              sontitle: "N.1 Your Responsibilities",
              detailsArr: [
                {
                  detailsIndex: "N.1.1",
                  detailsItem:
                    "It is the responsibility of all passengers, regardless of nationality and destination, to check with the consulate of the country they are visiting for current entry requirements.",
                },
                {
                  detailsIndex: "N.1.2",
                  detailsItem:
                    "As visa and health requirements are subject to changes without notice, Tuyi  recommends that you verify health and visa requirements with the appropriate consulate prior to departure.",
                },
                {
                  detailsIndex: "N.1.3",
                  detailsItem:
                    "You acknowledge that some Operators may require you to adhere to local policies and furnish the necessary documents and/or licenses as a prerequisite to using and/or participating in the Services they offer.",
                },
                {
                  detailsIndex: "N.1.4",
                  detailsItem:
                    "Tuyi strongly recommends that you purchase a comprehensive travel insurance policy prior to departure.",
                },
              ],
            },
          ],
        },
        {
          title: "O. Governing Law",
          details: [
            {
              sontitle:
                "O.1 This Terms of Use shall be governed by the laws of Hong Kong Special Administrative Region of the Peoples Republic of China. You agree to submit to the non-exclusive jurisdiction of the Hong Kong court.",
              detailsArr: [
                {
                  detailsIndex: "",
                  detailsItem: "",
                },
              ],
            },
          ],
        },
        {
          title: "P. Miscellaneous",
          details: [
            {
              sontitle: "P.1 Severability",
              detailsArr: [
                {
                  detailsIndex: "P.1.1",
                  detailsItem:
                    "If any provision of this Terms of Use is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Terms of Use shall otherwise remain in full force and effect and enforceable.",
                },
              ],
            },
            {
              sontitle: "P.2 Assignment",
              detailsArr: [
                {
                  detailsIndex: "P.2.1",
                  detailsItem:
                    "This Terms of Use is not assignable, transferable or sub-licensable by you except with Tuyi ’s prior written consent. Tuyi may transfer, assign or delegate this Terms of Use and its rights and obligations without prior notice to you.",
                },
              ],
            },
            {
              sontitle:
                "P.3 The Terms of Use have been drafted, and shall be construed, in the English language. Any translation of the Terms of Use is solely for reference only. In the event of inconsistency between the English language version and a translated version.",
              detailsArr: [
                {
                  detailsIndex: "",
                  detailsItem: "",
                },
              ],
            },
          ],
        },
        {
          title: "Q. Contact",
          details: [
            {
              sontitle:
                'Q.1 Please contact Tuyi at <span class="mailbox" style="color : #66C976"> tuyi_travel@hktuyitrip.com </span> to report any violations of this Terms of Use or to pose any questions regarding this Terms of Use or the Service.',
              detailsArr: [
                {
                  detailsIndex: "",
                  detailsItem: "",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    show() {
      this.showPop = true;
    },
    hide() {
      this.showPop = false;
    },
  },
};
</script>

<style scoped lang="scss">
.predetermined-container {
  .Navigation {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }

  .PrivacyPolicy_box {
    padding: 30px 0px;
  }

  .Navigation img {
    margin: 20px;
  }

  .privacy-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }

  .margin_buttom30 {
    padding-bottom: 30px !important;
  }

  .terms_box {
    padding: 0px 15px;
    padding-top: 20px;
    box-sizing: border-box;
  }

  .terms_title {
    font-size: 18px;
    padding-bottom: 10px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }

  .item_title {
    font-weight: bold;
  }

  .Privacy_item {
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
  }

  .Privacy_item b {
    font-weight: 400;
    color: #66c976;
  }

  .font-bold {
    font-weight: bold;
  }

  .Privacy_item + .Privacy_item {
    margin-top: 15px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  li {
    margin-top: 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    word-wrap: break-word;
  }

  .PrivacyPolicy_item {
    margin-top: 10px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    text-align: justify;
  }

  .item_flex {
    display: flex;
    margin-top: 5px;
  }
  .item_left {
    padding-right: 10px;
  }
  .item_right {
    flex: 1;
  }
}
</style>