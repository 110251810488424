export default {
  
  methods: {
    doubleStar(star) {
      const double = (star || 0) * 2;
      const result = [];
      const starIcon = require("../assets/image/Stars/star.png");
      const lackIcon = require("../assets/image/Stars/lack.png");

      for (let i = 1; i <= double; i++) {
        if (i % 2 === 0 || i === double) {
          result.push(i % 2 === 0 ? starIcon : lackIcon);
        }
      }

      return result;
    },
    random(num = 1) {
      const now = Date.now();
      return `${parseInt(Math.random() * num)}`;
    },
    returnRandom() {
      const f = 10000;
      const now = Date.now();
      return `${now}.${this.random(f)}.${this.random(f)}`;
    }
  }
};
