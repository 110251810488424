import axios from "axios";
import { urlDecodeSearch } from "@/shared/tools";
let overdue = false;

const require = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    "content-type": "application/json"
  }
});

require.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + localStorage.getItem("token")
    };
  }
  return config;
});

/* ============================================================================= 
  *                            登录模块
 ============================================================================= */

export const requireLogin = (data) => {
  return require({
    url: "/api/account/login",
    method: "POST",
    headers: {
      "Content-type": "multipart/form-data"
    },
    data
  });
};

export const requireLogout = () => {
  return require({
    url: "/api/account/loginout",
    method: "POST"
  });
};

export const shendVerifyCode = (params) => {
  return require({
    url: "/api/account/sendverificationcode",
    params
  });
};

/* ============================================================================= 
  *                            首页模块
 ============================================================================= */

// 推荐酒店
export const getTopHotels = (data = {}) => {
  return require({
    url: "/api/hotel/recommendhotel",
    data
  });
};

// 所有城市
export const getCountryCities = (data = {}) => {
  return require({
    url: "/api/hotel/countrycity",
    data
  });
};

// 推荐城市
export const getPopular = (data = {}) => {
  return require({
    url: "/api/hotel/popularcity",
    data
  });
};

/* ============================================================================= 
  *                            酒店
 ============================================================================= */

//  酒店搜索
export const queryHotel = (data = {}) => {
  return require({
    method: "POST",
    url: "/api/hotel/searchhotel",
    headers: {
      "Content-type": "multipart/form-data"
    },
    data
  });
};

export const getHotelDetail = (id) => {
  return require({
    url: `/api/hotel/hoteldetail/${id}`
  });
};

export const requireSearch = (data = {}) => {
  return require({
    url: "/api/hotel/gethotelprice",
    method: "POST",
    data
  });
};

export const queryRatePlan = (data) => {
  return require({
    url: "/api/Hotel/GetRatePlanDetail",
    method: "POST",
    data
  });
};

/* ============================================================================= 
  *                            Common
 ============================================================================= */

export const requirePhoneCode = (data) => {
  return require({
    url: "/api/infrastructure/telephoneareacode",
    data
  });
};

export const requireCompanyInfo = (data) => {
  return require({
    url: "/api/infrastructure/siteconfig",
    data
  });
};

export const requireImg = (data) => {
  return require({
    url: "/api/usercenter/uploadimage",
    method: "post",
    headers: {
      "Content-type": "multipart/form-data"
    },
    data
  });
};

/* ============================================================================= 
  *                            反馈
 ============================================================================= */

export const requireFeedback = (data) => {
  return require({
    url: "/api/usercenter/submitcustomerfeedback",
    method: "POST",
    data
  });
};

export const requireCreateOrder = (data) => {
  return require({
    url: "/api/order/booking",
    method: "POST",
    data
  });
};

export const requirePaypalApprove = (data) => {
  return require({
    url: "/api/Reservation/PaypalApprove",
    method: "POST",
    data
  });
};

export const requireOrderInfo = (params) => {
  return require({
    url: "/api/order/bookingdetail",
    params
  });
};

export const requirePaymentChannel = (params) => {
  return require({
    url: "/api/pay/paymentchannel",
    params
  });
};

export const requireCancelOrder = (data) => {
  return require({
    url: "/api/Reservation/CancelWhenOvertime",
    method: "POST",
    data
  });
};

export const validateCare = (url) => {
  return require({
    url
  });
};

export const requirePayPalClientId = () => {
  return require({
    url: "/api/Reservation/GetPayPalClientId",
    method: "POST"
  });
};

export const requireOrderDetail = (params) => {
  return require({
    url: "/api/order/bookingdetail",
    params
  });
};

export const requireOptList = (data) => {
  return require({
    url: "/api/Order/GetOptList",
    method: "POST",
    data
  });
};

export const requireRefundApply = (data) => {
  return require({
    url: "/api/Reservation/RefundApply",
    method: "POST",
    data
  });
};

export const requireGetRefundList = (data) => {
  return require({
    url: "/api/Order/GetRefundList",
    method: "POST",
    data
  });
};

export const requireOrderCheck = (data) => {
  return require({
    url: "/api/Reservation/OrderCheck",
    method: "POST",
    data
  });
};

export const requireOrderPay = (data) => {
  return require({
    url: "/api/pay/payorder",
    method: "POST",
    data
  });
};

export const requireOrderToken = (data) => {
  return require({
    url: "/api/order/bookinglist",
    method: "POST",
    data
  });
};

export const requireOrder = (data) => {
  return require({
    url: "/api/order/bookinglistbyids",
    method: "POST",
    data
  });
};

export const requireAddStatisticsPay = (data) => {
  return require({
    url: "/api/Statistics/AddStatisticsPay",
    method: "POST",
    data
  });
};

export const requireCreatePaySession = (params) => {
  return require({
    url: "/api/StripePay/CreatePaySession",
    method: "GET",
    params
  });
};

export const requireCheckPaySession = (query) => {
  return require({
    url: "/api/StripePay/CheckPaySession",
    method: "GET",
    query
  });
};

export const requireCountry = (params) => {
  return require({
    url: "/api/infrastructure/countryorganize",
    method: "GET",
    params
  });
};

export const requireCardPay = (data) => {
  return require({
    url: "/api/Order/CardPay",
    headers: {
      // 'content-type': 'application/x-www-form-urlencoded'
    },
    method: "POST",
    data
    // : {
    //   cardState: "sdfsdf",
    //   cardCity: "sdfsg",
    //   cardAddress: "dfgdfg",
    //   cardZipCode: "12345",
    //   cardFullPhone: null,
    //   grCountry: null,
    //   grState: null,
    //   grCity: null,
    //   grAddress: null,
    //   grZipCode: null,
    //   grEmail: "admin@qq.com",
    //   grPerName: null,
    //   cardNO: "123456789",
    //   cvv: "1234",
    //   expMonth: "52",
    //   expYear: "12",
    //   cardCountry: "AD",
    //   cardFullName: "first last",
    //   grphoneNumber: "+376 123456",
    //   En: "ZDVzNWNZUl9MbjQ0dTU4N1EwQ3dNWm9Fd1dYSzRfWGJZdWxzWmEtVkJxRjhSX0FoeTdzdjdJVHZrMDl5clVLOV9kU00xd1FDOXl4djhwc1dnY2hWbnp2NWl5MXpYV3ZkZVFWblp0N2VvMjJxQWRSbWF3QjJCcjhrODN6X1NKVlNrdEtORHdvWWNVbFJBN0tTVVhzcXhkU1NiMElzLWx4TW1EWVRGaGZ6c3RVMmpmR1dBR25odl9qc2Rzc21mOFBGb0lqdExEUVRHQXkxNFBUZnlyTExuZz09"
    // }
  });
};

export const requirePaymentInfo = (params) => {
  return require({
    url: "/api/Order/PayInfo",
    method: "POST",
    params
  });
};
