<template>
  <div class="search-block" @click="close">
    <div :class="mode">
      <div class="search-block-item" @click="searchCountryPop = true">
        <span class="icon-search"></span>
        <p class="position-name" :class="{ placeholder: !result.city }">
          {{ result.city || "City Name" }}
        </p>
        <span
          class="icon-map"
          @click.stop="queryCurrentPosition"
          :class="{ search: searchPosition }"
        ></span>
      </div>
      <div class="search-block-item">
        <span class="icon-calendar"></span>
        <div style="margin-left: 18px; flex: 1">
          <calender
            @change="calenderChange"
            :default="result.dateRange"
          ></calender>
        </div>
      </div>
      <div class="search-block-item" @click="guestPop = true">
        <span class="icon-user"></span>
        <div class="search-room-info">
          <b>{{ result.guest.roomNum }}</b>
          Room{{ result.guest.roomNum > 1 ? "s" : "" }}
          <b>{{ result.guest.adultNum }}</b>
          Adult{{ result.guest.adultNum > 1 ? "s" : "" }}
          <b>{{ result.guest.childNum }}</b>
          {{ result.guest.childNum > 1 ? "Children" : "Child" }}
        </div>
      </div>

      <div class="search-block-item">
        <div class="search-search">
          <span class="icon-keyword"></span>
          <div class="search-room-info">
            <input
              v-model="result.keyword"
              placeholder="Destination/Hotel Name"
            />
          </div>
        </div>
      </div>

      <div class="search-block-item reset">
        <div class="search-search-btn" @click="search">{{ searchBtn }}</div>
      </div>
    </div>

    <guest
      :pop.sync="guestPop"
      :default="result.guest"
      @change="guestChange"
    ></guest>

    <region
      @callback="formatPosition"
      ref="region"
      :options="region"
      @choice="regionPosition"
      :pop.sync="searchCountryPop"
      v-model="result.city"
    ></region>
  </div>
</template>

<script>
import Region from "./Region";
import Calender from "./Calender";
import Guest from "./Guest";
import axios from "axios";
import { guestStore } from "@/shared/data";
export default {
  props: {
    region: {
      type: Object,
      default() {
        return {
          popular: [],
          other: [],
        };
      },
    },
    searchBtn: {
      type: String,
      default: "Search",
    },
    mode: {
      type: String,
      default: "default",
    },
  },
  components: {
    Region,
    Calender,
    Guest,
  },
  data() {
    const result = guestStore();
    return {
      guestPop: false,
      result,
      searchPosition: false,
      searchCountryPop: false,
    };
  },
  async mounted() {},
  methods: {
    // 重置缓存数据
    update() {
      this.$set(this, "result", guestStore());
    },
    // 抛出关闭事件
    close(e) {
      if (e.currentTarget === e.target) {
        this.$emit("close");
      }
    },
    // 触发定位逻辑
    async location() {
      this.searchPosition = true;
      await this.$refs.region.queryPosition();
      this.searchPosition = false;
    },
    // 经纬度转换对应城市
    formatPosition(position) {
      if (!position.error) {
        const { latitude, longitude } = position;
        return axios({
          url: `https://maps.googleapis.com/maps/api/geocode/json`,
          method: "get",
          params: {
            latlng: `${latitude},${longitude}`,
            key: "AIzaSyCHBifnmOA7YghnQ6b0e3DnDbTs1bV51Js",
            result_type: "political|administrative_area_level_1",
            language: "en",
          },
        })
          .then(async (res) => {
            let googleResult = res.data.results;

            googleResult =
              googleResult && googleResult.length ? googleResult[0] : [];

            const result = googleResult.address_components.find((position) => {
              return (
                position.types.includes("locality") ||
                position.types.includes("administrative_area_level_1")
              );
            });

            const currentCity = result ? result.long_name : "";
            if (currentCity && this.result.city !== currentCity) {
              return this.$dialog
                .confirm({
                  message: `Current location:${currentCity}, Switch to current position?`,
                  confirmButtonText: "Switch",
                  cancelButtonText: "Cancel",
                })
                .then(() => {
                  this.result.city = currentCity;
                  this.result.latitude = latitude;
                  this.result.longitude = longitude;
                  localStorage.setItem("position", currentCity);
                  return currentCity;
                })
                .catch(() => {
                  return this.result.city;
                });
            }

            return this.result.city;
          })
          .catch((err) => {
            this.$toast.fail("location fail");
            return "";
          });
        // https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=AIzaSyCHBifnmOA7YghnQ6b0e3DnDbTs1bV51Js
        // });
      } else {
        this.$toast.fail(position.error);
      }
    },
    // 获取最新数据
    getResult() {
      return this.result;
    },
    // guest 修改数据
    guestChange(e) {
      this.result.guest = e;
    },
    // 日历修改数据
    calenderChange(e) {
      this.result.dateRange = e;
    },
    // region修改数据
    regionPosition(e) {
      this.result.city = e.name;
    },
    // 获取当前位置
    async queryCurrentPosition() {
      this.searchPosition = true;
      await this.$refs.region.queryCurrentLocation();
      this.searchPosition = false;
    },
    // 抛出筛选事件
    search() {
      this.$emit("search", this.result);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/Search.css";

@mixin icon() {
  width: 18px;
  height: 18px;
  background-size: 100%;
}

@keyframes search {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.search-block {
  height: 100vh;

  .icon-search {
    background: url(../assets/image/Index/search.png);
    @include icon;
  }

  .icon-map {
    background: url(../assets/image/Index/map.png);
    @include icon;

    // .icon-position.search,
    &.search {
      animation: search 2s infinite;
      opacity: 1;
    }
  }

  .icon-calendar {
    background: url(../assets/image/Index/calendar.png);
    @include icon;
  }

  .icon-user {
    background: url(../assets/image/Index/user.png);
    @include icon;
  }

  .icon-keyword {
    background: url(../assets/image/Index/keyword.png);
    @include icon;
  }

  .search-block-pop {
    padding: 15px;
    background: rgba(0, 0, 0, 0.3);

    .search-block-item {
      background: #fff;
      border-top: none !important;

      & + .search-block-item {
        margin-top: 12px;
      }
    }
    .search-search-btn {
      margin: 0;
    }
  }

  .search-room-info {
    display: flex;
    align-items: flex-end;

    b {
      padding: 0 2px 0 6px;
      font-weight: 600;
      font-size: 17px;
      vertical-align: middle;
    }
  }

  .search-block-item {
    padding: 15px;
    display: flex;
    align-items: center;

    & + .search-block-item {
      border-top: 1px solid #d3dce6;
    }

    &.reset {
      padding: 0;
      border-top: none !important;
    }
  }

  .search-search {
    width: 100%;
    display: flex;
    align-items: center;

    div {
      margin-left: 18px;
      color: #ccc;
      font-size: 16px;
    }

    &-btn {
      flex: 1;
      height: 44px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ff6454;
      margin: 8px 0;
    }
  }
}
</style>