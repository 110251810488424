<template>
  <div class="image" :style="{width : `${$attrs.width}px` || '100%', height : `${$attrs.height}px` || '100%'}">
    <!-- <img :src="emptyImageMap[language]" > -->
    <van-image v-bind="$attrs" :src="$attrs.src || ''">
      <template v-slot:error>
        <div style="height: 100%; width: 100%; display: block">
          <img :src="emptyImageMap[language]" />
        </div>
      </template>
    </van-image>
  </div>
</template>

<script>
export default {
  name: "imageComponent",
  data() {
    const language = localStorage.getItem("language") || "en-gb";
    return {
      language,
      emptyImageMap: {
        "en-gb": require("@/assets/image/Hotel/hotelNoImage.png"),
        "zh-cn": require("@/assets/image/Hotel/hotelNoImageCN.png"),
      },
    };
  },
};
</script>

<style scoped lang="scss">
.image {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  :deep {
    .van-image {
      display: block;
    }
    .van-image__error,
    .van-image__loading {
      position: static !important;
    }
  }
}
</style>
