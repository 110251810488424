export default {
  test: "test",
  hotel: {
    room: "Room" | "Rooms",
    guest: "Guest" | "Guests",

    sort: "Sort",
    priceStar: "Price Star",
    star: "Star",
    screen: "Screen",
    noRoom: "No available room",
    noRoomTips: "Please reselect the check-in date, price range and check for the room availability.",
    saveToday: "SAVE { save }%TODAY!",
    soldOut: "Sold out",
    eliminate: "Eliminate",
    displayResults: "Display results"
  },
  other: {
    close: "Close",
    price: "Price",
    show: "Show",
    less: "Less",
    all: "All"
  }
};
