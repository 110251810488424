<template>
  <div class="search-calendar">
    <div class="calendar_left" @click="calendarClick">
      <div class="date">
        <div class="Check">Check-in</div>
        <div class="finaldate">{{ CheckIn }}</div>
      </div>
      <div class="days">{{ Days }}</div>
      <div class="date text_right">
        <div class="Checkright">Check-out</div>
        <!-- {{CheckOut}} -->
        <div class="finaldateright">{{ CheckOut }}</div>
      </div>
    </div>

    <!-- calendar pop -->

    <van-popup
      v-model="calendarShow"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="pop-box" style="background: #f4f6f9">
        <div class="pop-navigation">
          <span class="close-button" @click="calendarShow = false">Close</span>
        </div>
        <div class="pop-container" style="padding-top: 20px" ref="container">
          <div class="dates">
            <div class="enter">{{ CheckIn }}</div>
            <div class="arrow">
              <img src="../assets/image/calendar/arrow.png" />
            </div>
            <div class="leave">{{ CheckOut }}</div>
          </div>
          <div class="vancalendar">
            <van-calendar
              :style="{ height: '100%' }"
              type="range"
              :show-mark="false"
              :formatter="formatter"
              :poppable="false"
              :default-date="defaultDate"
              :show-confirm="false"
              :show-subtitle="false"
              :min-date="min"
              :show-title="false"
              @confirm="onConfirm"
              ref="calendar"
            />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  formatDateEN,
  formatDate,
  getDaysBetween,
  timeZero,
} from "@/shared/tools";
export default {
  data() {
    return {
      defaultDate: [],
      calendarShow: false,
      CheckIn: "",
      CheckOut: "",
      Days: "1night",
      calendarClickstate: true,
      init: false,
      calendarShowModel: false,
    };
  },
  props: {
    default: {
      type: Object,
      default() {
        return {};
      },
    },
    min: {
      type: Date,
      default() {
        return new Date();
      },
    },
  },

  created() {
    this.englishDate(this.default);
  },
  watch: {
    calendarShow(n) {
      if (n && !this.init) {
        this.init = true;

        this.$nextTick(() => {
          this.$refs.container.addEventListener(
            "touchmove",
            (e) => {
              e.stopPropagation();
            },
            false
          );
        });
      }

      if (n) {
        this.$nextTick(() => {
          this.$refs.calendar.init();
        });
      }
    },
    default: {
      deep: true,
      handler(n) {
        if (n) {
          this.englishDate(n);
        }
      },
    },
  },
  methods: {
    s() {
      console.log(1);
    },
    jQueryText() {
      this.$nextTick(function () {
        let month = new Array(
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Spt",
          "Oct",
          "Nov",
          "Dec"
        ); // 月
        let week = new Array("S", "M", "T", "W", "T", "F", "S");
        let monthTitle = document.getElementsByClassName(
          "van-calendar__month-title"
        );
        let weekday = document.getElementsByClassName("van-calendar__weekday");
        // 更换月份
        for (let i = 0; i < monthTitle.length; i++) {
          const result = monthTitle[i].innerHTML.match(/(\d+)/g);
          // let replace = monthTitle[i].innerHTML.replace("月", "");
          // let index = replace.indexOf("年");
          // let result = replace.substr(index + 1, replace.length);

          // let year = new Date().getFullYear();
          monthTitle[i].innerHTML = month[result[1] - 1] + " " + result[0];
        }
        // 更换星期
        // for (let i = 0; i < weekday.length; i++) {
        //   weekday[i].innerHTML = week[i];
        // }
      });
    },
    // 英文的日历默认显示时间
    englishDate(defValue = {}) {
      let todayDate;
      let tomorrowDate;
      const { start, end } = defValue;

      const td = new Date();
      const timezone = td.getTimezoneOffset() * 60 * 1000;

      if (start && end) {
        todayDate = start instanceof Date ? start : new Date(start);
        tomorrowDate = end instanceof Date ? end : new Date(end);

        this.defaultDate = [todayDate, tomorrowDate];
      } else {
        todayDate = new Date();
        tomorrowDate = new Date(todayDate.getTime() + 24 * 3600 * 1000);
      }

      todayDate = new Date(todayDate.getTime() + timezone);
      tomorrowDate = new Date(tomorrowDate.getTime() + timezone);

      this.defaultDate = [todayDate, tomorrowDate];
      // console.log(todayDate, tomorrowDate);
      this.CheckIn = formatDateEN(todayDate);
      this.CheckOut = formatDateEN(tomorrowDate);

      const startFormat = formatDate(todayDate, "-");
      const endFormat = formatDate(tomorrowDate, "-");

      this.Days = getDaysBetween(startFormat, endFormat);
      this.Days = this.Days == "1" ? this.Days + "night" : this.Days + "nights";

      return {
        start: startFormat,
        end: endFormat,
      };
    },
    emit(options) {
      this.$emit("change", options);
    },
    formatter(day) {
      if (day.type === "start") {
        day.bottomInfo = "Checkin";
      } else if (day.type === "end") {
        day.bottomInfo = "leave";
      }

      return day;
    },
    calendarClick() {
      this.calendarShow = true;
      if (this.calendarClickstate) {
        this.jQueryText();
        this.calendarClickstate = false;
      }
    },
    onConfirm(values) {
      let [start, end] = values;

      start = timeZero(formatDate(start, "-"));
      end = timeZero(formatDate(end, "-"));

      console.log(start, end);

      this.calendarShow = false;

      this.CheckIn = formatDateEN(start);
      this.CheckOut = formatDateEN(end);

      const startTime = formatDate(start, "-");
      const endTime = formatDate(end, "-");

      this.Days = getDaysBetween(startTime, endTime);
      this.Days = this.Days == "1" ? this.Days + "night" : this.Days + "nights";

      this.$emit("change", {
        start: startTime,
        end: endTime,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.search-calendar {
  flex: 1;
  margin-left: 0;

  .calendar_left {
    padding: 0;
    width: auto;
    border-right: none;

    display: flex;
    align-items: center;
  }

  .Check {
    font-size: 12px;
    line-height: 20px;
    color: #999999;
  }

  .Checkright {
    font-size: 12px;
    line-height: 20px;
    color: #999999;
    text-indent: 15px;
  }

  .calendar {
    background-color: #fff;
    padding: 15px;
  }

  .calendar_flex {
    display: flex;
    width: 100%;
  }

  .calendar_right {
    display: flex;
    flex: 1;
    padding-left: 15px;
  }

  .Check {
    font-size: 12px;
    line-height: 20px;
    color: #999999;
  }

  .Checkright {
    font-size: 12px;
    line-height: 20px;
    color: #999999;
    text-indent: 15px;
  }

  .finaldateright {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #000000;
    text-align: right;
  }

  .finaldate {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #000000;
  }

  .days {
    flex: 1;
    font-size: 12px;
    color: #000000;
    text-align: center;
  }

  .leave {
    width: 35%;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }

  .van-calendar__footer {
    padding: 20px;
  }

  .calendar_box {
    height: 100%;
    width: 100%;
    background: #fff;
    position: absolute;
  }

  .close {
    padding: 20px;
  }

  .dates {
    display: flex;
    padding: 0px 20px;
    padding-bottom: 18px;

    div {
      font-size: 14px;
    }

    .arrow {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .enter {
    width: 35%;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }

  :deep {
    .van-calendar__day--end,
    .van-calendar__day--multiple-middle,
    .van-calendar__day--multiple-selected,
    .van-calendar__day--start,
    .van-calendar__day--start-end {
      color: #fff;
      background-color: #66c976;
    }

    .van-calendar__day--middle {
      color: #333 !important;
      background: #e7f3ff !important;
    }
  }
}
</style>