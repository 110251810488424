<template>
  <div class="google-detail">
    <div class="pop-navigation">
      <span class="close-button" @click="$router.back()">Close</span>
      <p>Change room type</p>
    </div>
    <div style="overflow: hidden" v-if="list && !list.length">
      <div class="hotel-empty">
        <h4>No available room</h4>
        <p>
          Please reselect the check-in date, price range and check for the room
          availability.
        </p>
      </div>
    </div>
    <template v-for="(room, li) in list || []">
      <!-- 展开 -->
      <div
        class="google-room-open"
        :key="`open_${li}`"
        v-if="openRoom.includes(li)"
      >
        <div class="room-type">
          <div class="banner">
            <van-swipe
              class="google-swipe"
              :autoplay="3000"
              indicator-color="white"
            >
              <van-swipe-item v-for="(photo, pi) in room.Photos" :key="pi">
                <div class="google-swipe-item">
                  <img :src="photo" />
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>

          <h2>
            <p>{{ room.RoomName }}</p>
            <i class="icon-up" @click="roomTrigger(li)"></i>
          </h2>

          <div class="amenities">
            <div class="amenities-list">
              <div class="amenities-item" v-if="room.RoomArea">
                <i class="icon-size"></i> {{ room.RoomArea }}
              </div>
              <div class="amenities-item" v-if="room.RoomFloor">
                <i class="icon-flow"></i> {{ room.RoomFloor }}
              </div>
            </div>
            <p @click="roomTypeHandler(room)">Amenities</p>
          </div>
        </div>
        <div class="package-wrap">
          <div
            class="package-item"
            :class="{ active: choiceId === ratePlan.RoomId }"
            v-for="(ratePlan, ri) in room.RatePlans"
            :key="ri"
          >
            <!-- 客人数量 -->
            <div
              class="package-guest"
              v-if="!ratePlan.Maxperson <= 3 && !ratePlan.Maxchildren <= 3"
            >
              <p>
                <i
                  class="icon-guest"
                  v-for="(p, pi) in ratePlan.Maxperson"
                  :key="pi"
                ></i>
              </p>
              <p>
                <i
                  class="icon-children"
                  v-for="(c, ci) in ratePlan.Maxchildren"
                  :key="ci"
                ></i>
              </p>
            </div>
            <div class="package-guest" v-else>
              <p>{{ ratePlan.Maxperson }}x<i class="icon-guest"></i></p>
              <p>{{ ratePlan.Maxchildren }}x<i class="icon-children"></i></p>
            </div>

            <!-- 设备 -->
            <div class="package-equipment">
              <div class="package-equipment-item">
                <template
                  v-for="(eq, eqi) in facilities[ratePlan.BedType] ||
                  facilities[99]"
                >
                  <i
                    :class="[eq]"
                    :key="eqi"
                    v-if="eq.indexOf('icon-') === 0"
                  ></i>
                  <i class="separator" :key="eqi" v-else>{{ eq }}</i>
                </template>

                {{ ratePlan.BedTypeDescription }}
              </div>
              <div class="package-equipment-item promotion">
                <i
                  class="icon-resolve"
                  :class="{ bright: ratePlan.InstantConfirm }"
                ></i>
                {{
                  ratePlan.InstantConfirm
                    ? "Instant Confirmation"
                    : "Confirm within 12 hours"
                }}
              </div>
              <div class="package-equipment-item">
                <i
                  class="icon-catering"
                  :class="{ noBreakfast: !ratePlan.Breakfast }"
                ></i>
                {{
                  ratePlan.Breakfast == 0
                    ? "No Breakfast"
                    : `${ratePlan.Breakfast} Breakfasts`
                }}
              </div>
              <div class="package-equipment-item" v-if="ratePlan.Wifi === 1">
                <i class="icon-wifi"></i>
                Free Wi-Fi
              </div>
              <div class="package-equipment-item" v-if="ratePlan.RefundRule">
                <i
                  class="icon-refundable"
                  :class="{
                    noRefundable: ratePlan.RefundRule.RefundType !== 1,
                  }"
                ></i>
                {{ ratePlan.RefundRule.RefundShortDesc }}
              </div>
            </div>

            <!-- 价格 -->
            <div
              class="package-price"
              :class="{ promotion: ratePlan.promotion }"
            >
              <span v-if="ratePlan.promotion">Promotion</span>
              <h3>
                <small>
                  {{ ratePlan.BaseRateAvgPrice.Price | rate(userLocale) }}
                </small>
                {{ ratePlan.BaseRateAvgPrice.CurrencySymbol
                }}{{ ratePlan.BaseRateAvgPrice.Price }}
              </h3>
              <h4>
                Prepay Online
                <i class="icon-sigh" @click="priceHandler(ratePlan)"></i>
              </h4>
              <!-- | Tax Included -->
              <button
                @click="
                  choiceType({
                    id: ratePlan.RoomId,
                    promotion: ratePlan.promotion,
                    payPrice: ratePlan.TotalOfAllRoom.NeedPay.Price,
                  })
                "
              >
                Book
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- 收起 -->
      <div class="google-room-close" :key="`close_${li}`" v-else>
        <div class="google-room-cover">
          <img :src="room.Photos[0]" alt="" />
        </div>
        <div class="google-room-info">
          <h2>
            <p>{{ room.RoomName }}</p>
            <i class="icon-up transform" @click="roomTrigger(li)"></i>
          </h2>
          <div class="google-room-equipment1">
            <div v-if="room.RoomArea">
              <i class="icon-size"></i>{{ room.RoomArea }}
            </div>
            <div v-if="room.RoomFloor">
              <i class="icon-flow"></i>{{ room.RoomFloor }}
            </div>
          </div>
          <div class="google-room-equipment2">
            <div>
              <i class="icon-people"></i
              >{{ room.RatePlans[0].Maxperson }} adult{{
                room.RatePlans[0] > 1 ? "s" : ""
              }}
            </div>
            <div>
              <i class="icon-bed"></i>{{ room.RatePlans[0].BedTypeDescription }}
            </div>
          </div>

          <div class="package-price">
            <h3>
              <small>
                {{
                  room.RatePlans[0].BaseRateAvgPrice.Price | rate(userLocale)
                }}
              </small>
              {{ room.RatePlans[0].BaseRateAvgPrice.CurrencySymbol
              }}{{ room.RatePlans[0].BaseRateAvgPrice.Price }}
            </h3>
            <!-- <h3><small>¥254.0055</small>$35</h3> -->
            <h4>
              Prepay Online<i
                class="icon-sigh"
                @click="priceHandler(room.RatePlans[0])"
              ></i>
            </h4>
          </div>
        </div>
      </div>
    </template>

    <div class="google-book-payment" v-if="baseRoom">
      <div class="google-book-price">
        <h3>
          {{ baseRoom.TotalOfAllRoom.NeedPay.CurrencySymbol
          }}{{ baseRoom.TotalOfAllRoom.NeedPay.Price }}
          <small v-if="baseRoom.BaseRateAvgPrice.Price">{{
            baseRoom.BaseRateAvgPrice.Price | rate(userLocale)
          }}</small>
        </h3>
        <!--  -->
        <div class="google-book-tax">
          <i class="icon-sigh" @click="priceHandler(baseRoom)"></i>
          <template v-if="$route.query.PriceType == 1">
            Tax Included |
          </template>
          Prepay Online
        </div>
      </div>
      <div class="google-book-pay">
        <button
          @click="
            choiceType({
              id: baseRoom.RoomId,
              promotion: baseRoom.promotion,
              payPrice: baseRoom.TotalOfAllRoom.NeedPay.Price,
            })
          "
        >
          Book
        </button>
      </div>
    </div>

    <!--价格详情-->
    <van-popup
      v-model="pricePop"
      closeable
      close-icon-position="top-left"
      round
      class="hoteldetails"
      @close="singDayTrigger = false"
      position="bottom"
    >
      <div class="price-detail-wrap">
        <div class="hoteldetails_box">Price Details</div>
        <div class="hoteldetailsroll" v-if="choiceRoom">
          <div class="OnlineBox">
            <div class="OnlinePrepay">
              <div class="OnlinePrepay_left">Prepay Online</div>
              <div class="OnlinePrepay_right">
                <div>
                  <i class="smSymbol">
                    {{ choiceRoom.TotalOfAllRoom.NeedPay.CurrencySymbol }}
                  </i>
                  {{ choiceRoom.TotalOfAllRoom.NeedPay.Price }}
                </div>
                <span v-if="choiceRoom.PriceType === 1">Tax Included</span>
              </div>
            </div>
            <!-- @click="singDayTrigger = !singDayTrigger" -->
            <div class="OnlinePrepay margTop20">
              <div
                class="OnlineroomPrepay_left"
                @click="singDayTrigger = !singDayTrigger"
              >
                <span
                  >{{ querySearch.room }} room x {{ days }} night{{
                    days > 1 ? "s" : ""
                  }}</span
                >

                <!-- upper.png -->
                <img
                  v-if="days > 1"
                  :src="
                    singDayTrigger
                      ? require('../../assets/image/Hotel/upper.png')
                      : require('../../assets/image/Hotel/openIcon.png')
                  "
                />
              </div>
              <div class="OnlineroomPrepay_right">
                <i class="smSymbol">
                  {{ choiceRoom.TotalOfAllRoom.RoomRate.CurrencySymbol }}
                </i>
                {{ choiceRoom.TotalOfAllRoom.RoomRate.Price }}
              </div>
            </div>
            <div class="Days_box" v-if="singDayTrigger && days > 1">
              <div
                class="JunRoom"
                v-for="(rate, ind) in calcUnitPrice({
                  range: dateRange,
                  room: querySearch.room,
                  price: choiceRoom.TotalOfAllRoom.RoomRate.Price,
                })"
                :key="ind"
              >
                <div class="JunRoom_left">
                  {{ rate.date }}, {{ rate.room | complex("Room") }}
                </div>
                <div class="JunRoom_right">
                  {{ rate.room }} x $ {{ rate.unit }}
                </div>
              </div>
            </div>
            <div class="Includes">
              <span>
                Includes
                <i class="smSymbol">
                  {{
                    choiceRoom.TotalOfAllRoom.TaxesAndServices.CurrencySymbol
                  }}
                </i>
                {{ choiceRoom.TotalOfAllRoom.TaxesAndServices.Price }} in taxes
                & fees
              </span>
            </div>

            <div class="pop-list" v-if="choiceRoom.TotalOfAllRoom.Extra.Price">
              <p>Extra <small>Pay at the property</small></p>
              <p>
                {{ choiceRoom.TotalOfAllRoom.Extra.Currency }}
                {{ choiceRoom.TotalOfAllRoom.Extra.Price }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <!--房型详情-->
    <van-popup
      v-model="roomDetailPop"
      closeable
      close-icon-position="top-left"
      round
      position="bottom"
    >
      <div class="RoomDetails" v-if="currentRoomType">
        <div class="RoomDetails_title">Room Details</div>
        <div class="RoomDetailsRoll">
          <div
            class="swipe_box"
            v-if="currentRoomType.Photos && currentRoomType.Photos.length"
          >
            <van-swipe
              class="my-swipe"
              :show-indicators="false"
              @change="RotationarrChange"
              ref="swiper"
            >
              <van-swipe-item
                v-for="(item, index) in currentRoomType.Photos"
                :key="index"
              >
                <van-image fit="cover" :src="item" height="200" />
              </van-swipe-item>
            </van-swipe>
            <div class="RotationLeft" @click="$refs.swiper.prev()">
              <img src="../../assets/image/Hotel/RotationLeft.png" alt="" />
            </div>
            <div class="RotationRight" @click="$refs.swiper.next()">
              <img src="../../assets/image/Hotel/RotationRight.png" alt="" />
            </div>
            <div class="quantity">
              <img
                src="../../assets/image/Hotel/Rotati.png"
                class="peopleimg"
              />
              <div class="quantityNumbers">
                {{ currentRoomType.PhotoIndex + 1 }}&nbsp;/&nbsp;{{
                  currentRoomType.Photos.length
                }}
              </div>
            </div>
          </div>

          <div class="King">{{ currentRoomType.RoomName }}</div>
          <div class="detailsfacilities">
            <div class="detailsfacilities_item" v-if="currentRoomType.RoomArea">
              <img
                src="../../assets/image/Hotel/measure.png"
                class="fontsSize20"
              />
              <span class="measure">{{ currentRoomType.RoomArea }}</span>
            </div>
            <div
              class="detailsfacilities_item"
              v-if="currentRoomType.RoomFloor"
            >
              <img
                src="../../assets/image/Hotel/floor.png"
                class="fontsSize20"
              />
              <span class="measure">{{ currentRoomType.RoomFloor }}</span>
            </div>
            <div
              class="detailsfacilities_item"
              v-if="currentRoomType.Window != 0 && currentRoomType.Window != 2"
            >
              <img
                src="../../assets/image/Hotel/Windowed.png"
                class="fontsSize20"
              />
              <span class="measure">{{ currentRoomType.WindowDesc }}</span>
            </div>
          </div>

          <div class="Rollhoteldetails">
            <!--<div class="Rollhoteldetails_item">
                            <div class=""><img src="../../assets/image/Hotel/RoomDetailsShow/Numberpeople.png"></div>
                            <div class="Rollhoteldetailsdescribe">2 adults</div>
                        </div>-->
            <div
              class="Rollhoteldetails_item"
              v-if="
                currentRoomType.BedType != null && currentRoomType.BedType != ''
              "
            >
              <div class="">
                <img
                  src="../../assets/image/Hotel/RoomDetailsShow/singlebed.png"
                />
              </div>
              <div class="Rollhoteldetailsdescribe">
                {{ currentRoomType.BedTypeDesc }}
              </div>
            </div>
            <!--<div class="Rollhoteldetails_item">
                            <div class=""><img src="../../assets/image/Hotel/RoomDetailsShow/Restaurant.png"></div>
                            <div class="Rollhoteldetailsdescribe">
                                <div class="Breakfasts">2 Breakfasts</div>
                                <div>Extra Child's Breakfast(O-2 years old): RMB$97</div>
                            </div>
                        </div>-->
            <div class="Rollhoteldetails_item">
              <div class="">
                <img src="../../assets/image/Hotel/RoomDetailsShow/wifi.png" />
              </div>
              <div class="Rollhoteldetailsdescribe">
                {{ currentRoomType.WifiDesc }}
              </div>
            </div>
          </div>
          <div class="Bottombutton">
            <div class="RoomDetails_button" @click="roomDetailPop = false">
              Select Room
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import NavBar from "@/components/Navbar";
import { requireSearch } from "@/apis/home";
import { getDaysBetween, formatTime } from "@/shared/tools";
let toast;
export default {
  name: "googleDetail",
  components: {
    NavBar,
  },
  data() {
    const { rate, userCurrency } = this.$route.query;
    let userLocale = null;

    if (rate) {
      userLocale = {
        rate,
        currency: userCurrency,
      };
    }

    const querySearch = this.$route.query;
    const dateRange = { start: querySearch.checkIn, end: querySearch.checkOut };

    return {
      userLocale,
      list: null,
      facilities: {
        0: ["icon-double"],
        14: ["icon-double"],
        1: ["icon-single"],
        4: ["icon-single"],
        5: ["icon-single"],
        99: ["icon-single"],
        2: ["icon-double", "/", "icon-single", "icon-single"],
        3: ["icon-threebeds"],
      },
      openRoom: [0],
      choiceId: null,
      baseRoom: null,
      choiceRoom: null,
      pricePop: false,
      querySearch,
      singDayTrigger: false,
      dateRange,
      roomDetailPop: false,
      currentRoomType: null,
    };
  },
  created() {
    this.init();
    this.choiceId = this.$route.query.roomId;
  },
  filters: {
    rate(price, userLocale) {
      if (!userLocale) return "";
      const { rate, currency } = userLocale;
      const transform = parseInt(rate * price * 100) / 100;
      return `${currency} ${transform}`;
    },
    complex(num, field) {
      field = typeof field === "string" ? [field, `${field}s`] : field;
      num = num || 0;
      return `${num} ${num > 1 ? field[1] : field[0]}`;
    },
  },
  computed: {
    days() {
      const { checkIn, checkOut } = this.querySearch;
      if (!checkIn && !checkOut) return 0;
      return getDaysBetween(checkIn, checkOut);
    },
  },
  methods: {
    roomTypeHandler(roomType) {
      this.currentRoomType = roomType;
      this.roomDetailPop = true;
    },
    RotationarrChange(index) {
      this.currentRoomType.PhotoIndex = index;
    },
    calcUnitPrice({ range, price, room }) {
      const start = formatTime(range.start);
      const end = formatTime(range.end);
      const days = (end.date - start.date) / (3600 * 24) / 1000;
      const result = [];
      const average = Math.ceil(price / days || 0);
      let total = 0;

      for (let i = 0; i < days; i++) {
        const index = i + 1;
        const current = formatTime(start.date, "-", i);

        const p = index === days ? price - total : average;
        result.push({
          price: p,
          unit: Math.round((p / room) * 1000) / 1000,
          date: current,
          room,
        });

        total += average;
      }
      return result;
    },
    priceHandler(price) {
      this.choiceRoom = price;
      this.pricePop = true;
    },
    choiceType(query) {
      localStorage.setItem("googleRoomType", JSON.stringify(query));
      this.$router.back();
    },
    includeBed(value, include) {
      return include.includes(value);
    },
    roomTrigger(index) {
      const _i = this.openRoom.indexOf(index);
      if (_i < 0) {
        this.openRoom.push(index);
      } else {
        this.openRoom.splice(_i, 1);
      }
    },
    init() {
      const locale = localStorage.getItem("language");
      const currency = localStorage.getItem("currency");
      const {
        hotelId,
        userDevice,
        checkIn,
        checkOut,
        gSite,
        room,
        adult,
        children,
        age,
      } = this.$route.query;

      let data = {
        HotelId: hotelId,
        ComeDate: checkIn,
        LeaveDate: checkOut,
        RoomId: "",
        Locale: locale,
        RoomNum: room || 1,
        AdultNum: adult || 2,
        UserDevice: userDevice,
        ChildNum: children || 0,
        ChildAges: age ? age.split(",") : [],
        GoogleSite: gSite,
        SellCurrency: currency,
      };

      toast = this.$toast({
        type: "loading",
        duration: 0,
        message: "Loading...",
        overlay: true,
      });

      requireSearch(data)
        .then((response) => {
          toast.close();

          let result = [];
          if (response.status == 200) {
            var { Success, Data } = response.data;
            if (Success) {
              if (Data && Data.List && Data.List.length) {
                let choiceIndex = 0;
                const list = (Data.List || []).map((room, roomIndex) => {
                  room.RatePlans.forEach((t, ti) => {
                    if (t.RoomId === this.choiceId) {
                      choiceIndex = roomIndex;
                    }
                    if (ti === 0 && roomIndex === 0) {
                      this.baseRoom = t;
                      t.promotion = true;
                    }
                  });
                  return room;
                });

                if (choiceIndex) {
                  const choice = list.splice(choiceIndex, 1);
                  list.unshift(choice[0]);
                }
                result = list;
              }
            }
          } else {
            this.$toast({
              type: "fail",
              message: response.Message || "No availablity",
            });
          }

          this.list = result;
        })
        .catch(() => {
          this.$toast.fail("Fail");
          this.list = [];
        });
    },
  },
};
</script>

<style scoped lang="scss">
.google-detail {
  padding-bottom: 80px;
  padding-top: 70px;

  i[class^="icon-"] {
    display: block;
  }

  .icon-up {
    width: 20px;
    height: 20px;
    background: url(../../assets/image/google/up.png) no-repeat;
    background-size: 100%;

    &.transform {
      transform: rotate(-180deg);
    }
  }

  .icon-size {
    background: url(../../assets/image/google/size.png) no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
  }

  .icon-flow {
    background: url(../../assets/image/google/flow.png) no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
  }

  .icon-guest {
    background: url(../../assets/image/google/guest.png) no-repeat;
    background-size: 100%;
    width: 14px;
    height: 14px;
  }

  .icon-bed {
    background: url(../../assets/image/google/bed.png) no-repeat;
    background-size: 100%;
    width: 16px;
    height: 16px;
  }

  .icon-people {
    background: url(../../assets/image/google/people.png) no-repeat;
    background-size: 100%;
    width: 16px;
    height: 16px;
  }

  .icon-catering {
    background: url(../../assets/image/google/Catering.png) no-repeat;
    background-size: 100%;

    &.noBreakfast {
      background: url(../../assets/image/google/no-breakfast.png) no-repeat;
      background-size: 100%;
    }
  }

  .icon-resolve {
    background: url(../../assets/image/google/12hourConfirmation.png) no-repeat;
    background-size: 100%;

    &.bright {
      background: url(../../assets/image/google/resolve.png) no-repeat;
      background-size: 100%;
    }
  }

  .icon-double {
    background: url(../../assets/image/google/Double.png) no-repeat;
    background-size: 100%;
    width: 16px;
    height: 16px;
  }

  .icon-single {
    background: url(../../assets/image/google/Single.png) no-repeat;
    background-size: 100%;
    width: 16px;
    height: 16px;
  }

  .icon-threebeds {
    background: url(../../assets/image/google/Threebeds.png) no-repeat;
    background-size: 100%;
    width: 16px;
    height: 16px;
  }

  .promotion {
    .icon-resolve {
      background: url(../../assets/image/google/resolve2.png) no-repeat;
      background-size: 100%;
    }
  }

  .icon-sigh {
    background: url(../../assets/image/google/sigh.png) no-repeat;
    background-size: 100%;
    width: 16px;
    height: 16px;
  }

  .icon-wifi {
    background: url(../../assets/image/google/wifi.png) no-repeat;
    background-size: 100%;
  }

  .icon-refundable {
    background: url(../../assets/image/google/Refundable.png) no-repeat;
    background-size: 100%;

    &.noRefundable {
      background: url(../../assets/image/google/prohibit.png) no-repeat;
      background-size: 100%;
    }
  }

  .icon-children {
    background: url(../../assets/image/google/children.png) no-repeat;
    background-size: 100%;
    width: 14px;
    height: 14px;
  }

  .package-price {
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;

    span {
      background: #e9f3ec;
      font-size: 12px;
      border-radius: 4px;
      padding: 0 6px;
      line-height: 1.8;
      color: #327e46;
    }

    &.promotion {
      h3 {
        color: #327e46;
      }
    }

    h3 {
      margin: 0;
      font-size: 22px;
      color: #000;
      font-weight: 400;
      margin: 6px 0;

      small {
        font-size: 12px;
        color: #202124;
        display: line-block;
        margin-right: 5px;
      }
    }

    h4 {
      font-size: 12px;
      line-height: 16px;
      color: #70757a;
      margin: 0;
      font-weight: 400;
      display: flex;
      align-items: center;

      i {
        margin-left: 5px;
      }
    }

    button {
      width: 140px;
      height: 42px;
      background: #1a73e8;
      opacity: 1;
      border-radius: 50px;
      border: none;
      outline: none;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }

  .google-room-open {
    + .google-room-open {
      margin-top: 10px;
    }

    + .google-room-close {
      margin-top: 10px;
    }

    .room-type {
      padding-bottom: 15px;

      .banner {
        height: 160px;
        border-radius: 10px;
        overflow: hidden;

        .google-swipe-item {
          height: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ccc;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      h2 {
        margin: 20px 15px 15px;
        font-size: 16px;
        display: flex;
        line-height: 20px;

        p {
          flex: 1;
          margin: 0;
          margin-right: 10px;
        }
      }

      .amenities {
        margin: 0 15px;

        .amenities-list {
          display: flex;
          align-items: center;

          .amenities-item {
            display: flex;
            align-items: center;
            line-height: 20px;
            font-size: 14px;

            + .amenities-item {
              margin-left: 15px;
            }

            i {
              width: 20px;
              height: 20px;
              margin-right: 6px;
              transform: translate(0, -2px);
            }
          }
        }

        p {
          color: #66c976;
          margin: 0;
        }
      }
    }

    .package-wrap {
      background: #f8f9fa;

      .package-item {
        padding: 16px 16px 90px;
        position: relative;
        box-sizing: border-box;

        &.active {
          border: 1px solid #f00;
        }

        .package-equipment {
          &-item {
            font-size: 12px;
            margin-top: 15px;
            color: #202124;
            display: flex;
            align-items: center;

            &.promotion {
              color: #34a853;
            }

            i {
              width: 16px;
              margin-right: 10px;
              height: 16px;
            }
          }
        }

        .package-guest {
          display: flex;
          align-items: center;

          p {
            margin: 0;
            display: flex;
            align-items: center;

            + p {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .google-room-close {
    height: 186px;
    border: 1px solid #dadce0;
    border-radius: 10px;
    overflow: hidden;

    display: flex;

    + .google-room-close {
      margin-top: 10px;
    }

    + .google-room-open {
      margin-top: 10px;
    }

    .google-room-cover {
      height: 100%;
      width: 120px;
      min-width: 120px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .google-room-info {
      flex: 1;
      padding: 15px;
      position: relative;
      box-sizing: border-box;

      h2 {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          width: 50vw;
          font-size: 16px;
          font-weight: bold;
          margin: 0;
          color: #000;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        i {
          margin-left: 10px;
        }
      }

      .google-room-equipment1 {
        display: flex;
        align-items: center;
        margin-top: 10px;

        div {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #000;

          i {
            margin-right: 5px;
          }

          + div {
            margin-left: 10px;
          }
        }
      }

      .google-room-equipment2 {
        div {
          margin-top: 6px;
          font-size: 12px;
          display: flex;
          align-items: center;
          color: #202124;

          i {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .google-book-payment {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 64px;
    padding: 15px;
    background: #fff;
    z-index: 20;
    box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    .google-book-pay {
      button {
        width: 140px;
        height: 42px;
        background: #1a73e8;
        outline: none;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        border: none;
        border-radius: 50px;
      }
    }

    .google-book-price {
      flex: 1;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #202124;
        margin: 0;

        small {
          font-size: 12px;
          color: #202124;
        }
      }

      .google-book-tax {
        font-size: 12px;
        color: #70757a;
        margin-top: 5px;
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  .google-book-payment {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 64px;
    padding: 15px;
    background: #fff;
    z-index: 20;
    box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    .google-book-pay {
      button {
        width: 140px;
        height: 42px;
        background: #1a73e8;
        outline: none;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        border: none;
        border-radius: 50px;
      }
    }

    .google-book-price {
      flex: 1;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #202124;
        margin: 0;

        small {
          font-size: 12px;
          color: #202124;
        }
      }

      .google-book-tax {
        font-size: 12px;
        color: #70757a;
        margin-top: 5px;
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  .price-detail-wrap {
    .hoteldetails_box {
      text-align: center;
      padding: 15px 0px;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #d3dce6;
    }

    .OnlineBox {
      padding: 20px 15px 0;
    }

    .pop-list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 6px 0;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex-direction: column;

        small {
          font-size: 12px;
          color: #999;
        }
      }
    }

    .OnlinePrepay {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      // margin-top: 30px;
      & + .OnlinePrepay {
        margin-top: 20px;
      }
    }

    .OnlinePrepay_left {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
    }

    .OnlinePrepay_right {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 16px;
      font-weight: bold;
      color: #ff6454;

      span {
        display: block;
        font-size: 12px;
        color: #000;
        font-weight: 400 !important;
        margin-top: 4px;
      }
    }

    .Days_box {
      background: #f4f6f9;
      padding: 7px 10px;
      padding-bottom: 5px;
      margin-top: 10px;
    }

    .JunRoom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .JunRoom_left {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        line-height: 22px;
        font-size: 12px;
        color: #999999;
      }

      .JunRoom_right {
        flex: 1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        line-height: 22px;
        font-size: 12px;
        color: #999999;
      }
    }

    .Includes {
      margin-top: 10px;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      padding-bottom: 20px;

      &.dashed {
        border-bottom: 1px dashed #eceff5;
      }
    }

    .Includes span {
      font-size: 12px;
      color: #999999;
      margin-right: 5px;
    }

    .Includes img {
      width: 16px;
      height: 16px;
    }

    .feesexplainPopup {
      padding: 38px 15px;
      padding-bottom: 20px;
      /* padding: 38px 32px; */
      width: 272px;
    }

    .Includestaxes {
      font-size: 12px;
      color: #000000;
    }

    .OnlineroomPrepay_left {
      display: flex;
      align-items: center;
      img {
        width: 11px;
        display: inline-block;
        margin-left: 4px;
      }
    }

    .fees_item {
      display: flex;
      margin-top: 10px;
    }

    .feesitem_left {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #000000;
    }

    .feesitem_right {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 12px;
      color: #000000;
    }

    .extra {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 40px;

      p {
        font-size: 14px;
        display: flex;
        flex-direction: column;

        small {
          font-size: 12px;
          color: #ccc;
        }
      }
    }
  }

  .RoomDetails {
    height: 550px;
  }

  .RoomDetails_title {
    text-align: center;
    padding: 20px 0px;
    font-size: 18px;
    font-weight: bold;
  }

  .swipe_box {
    height: 200px;
    width: 100%;
    position: relative;
  }

  .RoomDetailsRoll {
    height: 488px;
    position: relative;
    overflow: auto;
  }

  .King {
    font-size: 18px;
    color: #000000;
    padding: 10px 15px;
  }

  .detailsfacilities {
    border-bottom: 1px solid #d3dce6;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
    margin: 0 15px;
    padding-top: 0px;
  }

  .detailsfacilities_item {
    display: flex;
    align-items: center;
    margin-right: 30px;
    padding-top: 5px;
  }

  .detailsfacilities_item img {
    width: 20px;
    height: auto;
  }
  .Rollhoteldetails {
    margin-top: 15px;
    padding: 0px 15px;
  }

  .Rollhoteldetails_item {
    margin-top: 8px;
    display: flex;
  }

  .Rollhoteldetailsdescribe {
    font-size: 12px;
    color: #000000;
    margin-left: 6px;
    line-height: 19px;
  }

  .Rollhoteldetails_item img {
    max-width: 16px;
    height: auto;
  }

  .Breakfasts {
    font-size: 12px;
    color: #00adb2;
  }

  .detailsAmenities {
    padding: 20px 15px;
  }

  .RoomDetailsRoll::-webkit-scrollbar {
    display: none;
  }

  .Rotation_box {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .RotationLeft {
    position: absolute;
    left: 15px;
    top: 40%;
  }

  .RotationRight {
    position: absolute;
    right: 15px;
    top: 40%;
  }

  .quantity {
    position: absolute;
    right: 0px;
    bottom: 8px;
    /* width: 50px; */
    /* height: 20px; */
    background: #000000;
    opacity: 0.6;
    border-radius: 20px 0px 0px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
  }

  .quantityNumbers {
    color: #cccccc;
    font-size: 14px;
    margin-left: 3px;
    padding-top: 3px;
  }

  .van-image {
    width: 100%;
    height: 100%;
  }

  .van-swipe-item img {
    width: 100%;
    height: 200px;
  }

  .hotelName {
    padding: 15px;
  }

  .Bottombutton {
    padding: 0 15px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    bottom: 20px;
  }

  .RoomDetails_button {
    width: 100%;
    padding: 12px 0px;
    background: #ff6454;
    border-radius: 2px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
  }
}
</style>