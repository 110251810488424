function formatDateString(time) {
  if (typeof time === "string") {
    return time.split(".");
    //.replace(/\-/g, "/")
  }
  return time;
}

export const formatDate = (time, separator = null) => {
  if (!(time instanceof Date)) {
    time = new Date(formatDateString(time));
  }

  const year = time.getFullYear();
  let month = time.getMonth() + 1;
  let day = time.getDate();

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  let result = [year, month, day];

  if (separator !== null) {
    result = result.join(separator);
  }

  return result;
};

export const timeZero = (time) => {
  const date = new Date();
  const timezone = date.getTimezoneOffset() * 60 * 1000;

  time = time instanceof Date ? time : new Date(time);

  return new Date(time.getTime() + timezone);
};

export const formatTime = (time, boundary, deviation = 0) => {
  const oneDay = 3600 * 24 * 1000;

  if (typeof time === "string" || typeof time === "number") {
    time = new Date(time);
  }

  if (deviation) {
    const timeStamp = time.getTime() + deviation * oneDay;
    time = new Date(timeStamp);
  }

  const year = time.getUTCFullYear();
  let month = time.getUTCMonth() + 1;
  let day = time.getUTCDate();
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return boundary
    ? [year, month, day].join(boundary)
    : {
        year,
        month,
        day,
        date: time,
        week: time.getUTCDay()
      };
};

export const formatDateEN = (time) => {
  var dt = time instanceof Date ? time : new Date(formatDateString(time));

  var m = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec"]; // 月
  var w = new Array("Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"); // 星期
  var d = new Array("st", "nd", "rd", "th");

  var yn = dt.getFullYear(); //当前年份
  var mn = dt.getMonth() + 1; //月
  var wn = dt.getDay(); //星期几
  var dn = dt.getDate(); //几号

  return w[wn] + "," + m[mn - 1] + " " + dn;
};

export const formatDateENShort = (time) => {
  var dt = time instanceof Date ? time : new Date(formatDateString(time));
  var m = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec"]; // 月
  var d = new Array("st", "nd", "rd", "th");

  var yn = dt.getFullYear(); //当前年份
  var mn = dt.getMonth() + 1; //月
  var wn = dt.getDay(); //星期几
  var dn = dt.getDate(); //几号

  return m[mn - 1] + " " + dn;
};
export const debounce = (e, fn, step = 200) => {
  const { target } = e;

  if (!target.debounce) {
    target.debounce = (() => {
      let timer = null;

      return () => {
        clearTimeout(timer);

        timer = setTimeout(() => {
          fn && fn.call(fn);
        }, step);
      };
    })();
  }

  target.debounce();
};
// 计算天数
export const getDaysBetween = (start, end) => {
  if (!start || !end) return 0;
  var startDate = Date.parse(start);
  var endDate = Date.parse(end);
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
};

export const urlDecode = (query) => {
  const { dateRange, guest } = query;
  const keyMap = {
    start: "checkInDate",
    end: "checkOutDate",
    adultNum: "adults",
    childNum: "children",
    childAges: "age",
    roomNum: "room"
  };
  const queryData = {
    ...(dateRange || {}),
    ...(guest || {})
  };

  const result = {};
  Object.entries(queryData).forEach((item) => {
    if (item[1] && keyMap[item[0]]) {
      // console.log(keyMap[item[0]], item[0]);
      if (item[0] === "childAges") {
        item[1] = item[1].join(",");
      }
      result[keyMap[item[0]]] = item[1];
    }
  });

  return result;
};

export function updateUrl(params, path) {
  const search = urlDecodeSearch();
  const query = {
    ...search,
    ...params
  };

  let queryUrl = Object.entries(query).reduce((total, item) => {
    total += `&${item[0]}=${item[1]}`;
    return total;
  }, "");
  queryUrl = queryUrl.replace("&", "?");
  // this.queryStore = query;
  history.replaceState({}, null, `${path}${queryUrl}`);
  return query;
}

export const config = {
  tuyi: {
    email: "tuyi_travel@hktuyitrip.com",
    telephone: "020-39431520",
    contactTimes: ["08:00-24:00"]
  },
  default: {
    city: "Bangkok"
  },
  maxPriceSet: 1000
};

export const urlDecodeSearch = () => {
  let search = (location.search || "").replace("?", "");

  if (search) {
    search = search.split("&");
    return search.reduce((total, item) => {
      const [key, value] = item.split("=");

      total[key] = value;

      return total;
    }, {});
  }

  return {};
};

export const urlEncode = (path, search) => {
  let searchString = Object.entries(search).reduce((s, i) => {
    const [key, value] = i;
    s += `&${key}=${value}`;

    return s;
  }, "");

  searchString = searchString.replace(/^&/, "?");

  // const url = 'https://m.hktuyitrip.com';
  const url = location.origin;

  return `${url}${path}${searchString}`;
};

export const excludeSearch = (exclude = []) => {
  const search = urlDecodeSearch();

  return Object.entries(search).reduce((s, [key, value]) => {
    if (!exclude.includes(key) && key.indexOf("c_") !== 0) {
      s[key] = value;
    }

    return s;
  }, {});
};

export const timeT = (time) => {
  const result = time.replace("T", " ");
  const [d, t] = result.split(" ");
  return d;
};

export const ruleObject = {
  require(value) {
    return value !== undefined && value !== null && value !== "";
  },
  email(value) {
    if (typeof value !== "string") return false;
    const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    return reg.test(value);
  },
  phone(value) {
    if (typeof value !== "string") return false;
    const reg = /^([0-9]|-|\s){2,16}$/;
    return reg.test(value);
  },
  number(value, arg) {
    let scope = [0, ""];
    if (arg) scope = arg.split("-");
    const reg = new RegExp(`^[0-9]{${scope.join(",")}}$`);
    return reg.test(value);
  },
  fdate(value) {
    const reg = /^\d{2}\/\d{2}/;
    return reg.test(value);
  }
};
