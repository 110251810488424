import { getHotelDetail, requirePhoneCode, requireCreateOrder, requireSearch, requireOrderCheck } from "@/apis/home";
import { getDaysBetween, formatDateEN, excludeSearch } from "@/shared/tools";
import predetermined from "@/views/info/predetermined";
import policy from "@/views/info/policy";

export default {
  data() {
    return {
      roomDetail: {},
      roomPriceInfo: {},
      roomType: {},
      price: {
        total: null
      },
      guest: null,
      userLocale: null,
      dateRange: {},
      // policySort: "",
      phoneCodeList: [],
      equipment: null,
      countryChoice: null,
      bedTypeChoice: null,
      bedType: [],
      requirement: "",
      emailReg: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      phoneReg: /^([0-9]|-|\s){2,16}$/,
      nameReg: /^[a-zA-Z]+$/
    };
  },
  components: {
    policy,
    predetermined
  },
  directives: {
    error: {
      inserted(dom) {
        dom.classList.add("validate-box");
      },
      bind(dom, arg) {
        let timer;
        let box = dom;
        let input = dom;
        const type = box.toString().slice(12, -8).toLowerCase();

        if (type !== "input") {
          input = box.querySelector("input");
        }

        if (!input) return;

        input.addEventListener(
          "input",
          (e) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              const target = e.target;
              const test = arg.value.test(target.value);
              const classList = box.classList;
              if (test) {
                classList.remove("error");
              } else {
                classList.add("error");
              }
            }, 500);
          },
          false
        );
      }
    }
  },
  filters: {
    toEN(time) {
      if (!time) return "";
      return formatDateEN(time);
    },
    toFixed(value) {
      return (value || 0).toFixed(2);
    },
    formatDay(day) {
      return day == "1" ? day + " night" : day + " nights";
    },
    phoneCode(code) {
      if (!code) return "";
      const codeMap = code.split(" ");
      return codeMap[codeMap.length - 1];
    }
  },
  computed: {
    days() {
      const { start, end } = this.dateRange;
      if (!start && !end) return 0;
      return getDaysBetween(start, end);
    },
    ratePrice() {
      if (!this.userLocale) return "";
      const { rate, currency } = this.userLocale;
      if (this.price.total && rate) {
        const price = parseInt(this.price.total[2] * rate * 100) / 100;
        return `${currency} ${price}`;
      }

      return "";
    }
  },
  created() {
    const { guest, checkIn, checkOut } = this.initSearch();
    this.dateRange = {
      start: checkIn,
      end: checkOut
    };

    this.$set(this, "guest", guest);

    this.init();
    this.getPhoneCodeList();
  },
  methods: {
    validate(reg, value) {
      return reg.test(value);
    },
    book() {
      const { email, phone } = this.values;
      const { emailReg, phoneReg, nameReg, price } = this;

      if (!price.total) return;

      let phoneCode = this.phoneCodeList[this.countryChoice].split(" ");
      phoneCode = phoneCode[phoneCode.length - 1];

      const emailValidate = this.validate(emailReg, email);
      const phoneVALidate = this.validate(phoneReg, phone);

      const guestInfo = this.roomOptionalArr.every((room) => {
        return room.OptionalArr.every((guest) => {
          return nameReg.test(guest.name) && nameReg.test(guest.surName);
        });
      });

      if (emailValidate && phoneVALidate && guestInfo) {
        this.$refs.orderResolve.show();
      } else {
        this.$toast.fail("Please enter the correct information");
      }
    },
    createOrder() {
      var self = this;
      const { queryStore, currency, price, values, dateRange, guest } = this;
      const { hotelId, roomId, userCountry, gSite } = queryStore;
      const { start, end } = dateRange;
      const { roomNum, adultNum, childNum, childAges } = guest;

      var data = {
        HotelId: hotelId,
        RoomId: roomId,
        Breakfast: 0,
        ComeDate: start,
        LeaveDate: end,
        RoomNum: roomNum,
        AdultNum: adultNum,
        UserCountry: userCountry,
        ChildNum: childNum,
        // SellCurrency: currency,
        SellTotalPrice: price.total[2],
        PaymentPrice: price.total[2],
        ContactMobile: values.phone,
        ContactEmail: values.email,
        GuestNames: [],
        // NoteToHotel: self.requirement,
        RateTotalPrice: price.baseTotal[2],
        TaxesFees: price.taxesAndFees[2],
        ChildAges: childAges
      };

      let phoneCode = this.phoneCodeList[this.countryChoice].split(" ");
      phoneCode = phoneCode[phoneCode.length - 1];
      data.ContactMobile = phoneCode + " " + values.phone;

      self.roomOptionalArr.forEach((room) => {
        var roomGuestNames = [];
        room.OptionalArr.forEach((v) => {
          var firstName = v.surName; //First name
          var lastName = v.name; //Last name

          roomGuestNames.push({ FirstName: firstName, LastName: lastName });
        });
        data.GuestNames.push(roomGuestNames);
      });

      if (self.roomPriceInfo) {
        data.Breakfast = self.roomPriceInfo.Breakfast;
      }

      const loading = this.$toast.loading({
        message: "Loading...",
        duration: 0,
        overlay: true
      });

      // console.log(data);
      return;
    },
    querySearch() {
      const searchUrl = location.search.replace("?", "");
      return searchUrl.split("&").reduce((total, item) => {
        const [key, value] = item.split("=");
        total[key] = value;
        return total;
      }, {});
    },
    updateUrl(params) {
      const search = this.querySearch();
      const query = {
        ...search,
        ...params
      };

      let queryUrl = Object.entries(query).reduce((total, item) => {
        total += `&${item[0]}=${item[1]}`;
        return total;
      }, "");
      queryUrl = queryUrl.replace("&", "?");
      this.queryStore = query;
      history.replaceState({}, null, `/google-book${queryUrl}`);
    },
    addOptional(index) {
      this.roomOptionalArr[index].OptionalArr.push({
        surName: "",
        name: "",
        surNameTips: false,
        NameTips: false
      });
    },
    RoomAdd() {
      this.roomOptionalArr.push({
        OptionalArr: [
          {
            surName: "",
            name: "",
            surNameTips: false,
            NameTips: false
          }
        ]
      });
    },
    bedHandler(id) {
      let toast = this.$toast({
        type: "loading",
        duration: 0,
        message: "Loading...",
        overlay: true
      });

      const { currency, queryStore, guest } = this;
      const { hotelId, roomId, checkIn, checkOut } = queryStore;
      const { roomNum, adultNum, childNum, childAges } = guest;

      requireOrderCheck({
        HotelId: hotelId,
        roomId,
        // IsPackage,
        ComeDate: checkIn,
        LeaveDate: checkOut,
        RoomNum: roomNum,
        AdultNum: adultNum,
        ChildNum: childNum,
        ChildAges: childAges,
        Locale: currency,
        BedGroupId: this.bedTypeChoice
      }).then((res) => {
        if (res.data.Success) {
          this.bedTypeChoice = id;
          toast.clear();
        } else {
          this.$toast({
            type: "fail",
            message: "This room type has no room"
          });
        }
      });
    },
    getPhoneCodeList() {
      requirePhoneCode({
        HotelId: this.queryStore.hotelId
      }).then((response) => {
        if (response.status == 200) {
          let localIndex = null;
          let defaultIndex = null;

          const phoneCodeList = response.data.map((item, index) => {
            const userCountry = this.queryStore.userCountry;

            if (userCountry === item.CountryOrRegion) localIndex = index;
            if (item.DefaultShow) defaultIndex = index;

            return `${item.CountryOrRegion} +${item.PhoneCode}`;
          });

          this.countryChoice = localIndex || defaultIndex || 0;
          this.$set(this, "phoneCodeList", phoneCodeList);
        }
      });
    },
    getRoomDetail() {
      const { hotelId, roomId } = this.queryStore;
      getHotelDetail({
        hotelId,
        roomId,
        locale: this.language,
        ...excludeSearch(["hotelId", "locale", "roomId", "promotion"])
      }).then((response) => {
        if (response.status == 200) {
          const result = response.data;
          this.roomDetail = result;
        }
      });
    },
    search() {
      let { hotelId, roomId, userDevice, gSite } = this.queryStore;
      const { start, end } = this.dateRange;
      const { roomNum, adultNum, childNum, childAges } = this.guest;

      let toast = this.$toast({
        type: "loading",
        duration: 0,
        message: "Loading...",
        overlay: true
      });

      requireSearch({
        HotelId: hotelId,
        RoomId: roomId,
        ComeDate: start,
        LeaveDate: end,
        RoomNum: roomNum,
        AdultNum: adultNum,
        ChildNum: childNum,
        ChildAges: childAges,
        Locale: this.language,
        UserDevice: userDevice,
        GoogleSite: gSite,
        SellCurrency: this.currency,
        ...excludeSearch([
          "hotelId",
          "roomId",
          "gSite",
          "locale",
          "currency",
          "adult",
          "children",
          "age",
          "room",
          "userDevice",
          "promotion",
          "checkIn",
          "checkOut"
        ])
      })
        .then((response) => {
          var ret = response.data;
          if (ret.Success && ret.Data) {
            let roomPriceInfo, roomType;

            if (!roomId) {
              roomType = ret.Data.List[0];
              roomPriceInfo = roomType ? roomType.RatePlans[0] : {};

              this.queryStore.roomId = roomPriceInfo.RoomId;
            } else {
              ret.Data.List.forEach((v, i) => {
                v.RatePlans.forEach((rp, ind) => {
                  if (rp.RoomId == roomId) {
                    roomPriceInfo = rp;
                    roomType = v;
                  }
                });
              });
            }

            const { totalPrice, userCurrency } = this.queryStore;
            if (totalPrice && userCurrency !== this.currency && !this.userLocale) {
              const minPrice = roomPriceInfo.TotalOfAllRoom.NeedPay;
              let rate = totalPrice / minPrice.Price;
              rate = rate || 1;

              this.userLocale = {
                currency: userCurrency,
                rate: parseFloat(parseInt(rate * 1000000) / 1000000)
              };
            }

            // ret.Data.List.forEach((v, i) => {
            //   v.RatePlans.forEach((rp, ind) => {
            //     if (rp.RoomId == roomId) {
            this.roomPriceInfo = roomPriceInfo;
            this.roomType = roomType;

            if (roomPriceInfo.BedGroups && roomPriceInfo.BedGroups.length) {
              this.bedTypeChoice = roomPriceInfo.BedGroups[0].BedGroupId;
              this.bedType = roomPriceInfo.BedGroups || [];
            }

            //  设施
            this.equipment = {
              Maxperson: roomPriceInfo.Maxperson,
              Breakfast: roomPriceInfo.Breakfast,
              RefundRule: roomPriceInfo.RefundRule && roomPriceInfo.RefundRule.RefundType,
              BedTypeDesc: roomType.BedTypeDesc
            };

            // 政策
            // this.policySort = rp.RefundRule.RefundDescription;

            // 所有价格
            const tPrice = roomPriceInfo.TotalOfAllRoom;
            this.price = {
              total: [tPrice.NeedPay.Currency, tPrice.NeedPay.CurrencySymbol, tPrice.NeedPay.Price],
              baseTotal: [tPrice.RoomRate.Currency, tPrice.RoomRate.CurrencySymbol, tPrice.RoomRate.Price],
              extra: [tPrice.Extra.Currency, tPrice.Extra.CurrencySymbol, tPrice.Extra.Price],
              taxesAndFees: [
                tPrice.TaxesAndServices.Currency,
                tPrice.TaxesAndServices.CurrencySymbol,
                tPrice.TaxesAndServices.Price
              ]
            };
            //     }
            //   });
            // });
            toast.close();
          } else {
            this.$toast({
              type: "fail",
              message: ret.Message || "No availablity"
            });
            this.price = {};
          }
        })
        .catch(() => {
          this.$toast({
            type: "fail",
            message: "Fail"
          });
          this.price = {};
        });
    }
  }
};
