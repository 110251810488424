<template>
  <div class="hotel-detail-container">
    <nav-bar :back="true" @change="init" />

    <div class="Rotation_box" v-if="hotelPhotos && hotelPhotos.length">
      <!-- banner图片浏览 -->
      <previewer ref="previewer" :list="photoReview" />
      <!-- BANNER -->
      <van-swipe
        class="my-swipe"
        :show-indicators="false"
        @change="HotelPhotoChange"
        ref="swiper"
      >
        <van-swipe-item v-for="(item, index) in hotelPhotos" :key="index">
          <van-image fit="cover" :src="item.value" height="200" />
        </van-swipe-item>
      </van-swipe>

      <div class="RotationLeft" @click="$refs.swiper.prev()">
        <img src="../../assets/image/Hotel/RotationLeft.png" alt="" />
      </div>

      <div class="RotationRight" @click="$refs.swiper.next()">
        <img src="../../assets/image/Hotel/RotationRight.png" alt="" />
      </div>

      <div class="quantity" @click="$refs.previewer.show(hotelPhotoIndex - 1)">
        <img src="../../assets/image/Hotel/Rotati.png" class="peopleimg" />
        <div class="quantityNumbers">
          {{ hotelPhotoIndex }}&nbsp;/&nbsp;{{ hotelPhotos.length }}
        </div>
      </div>
    </div>

    <div class="hotelName">
      <div class="Hotel_title">
        <div class="hotel_text">
          {{ entity.hotelName }}
          <div class="Stars_icon">
            <template v-for="(star, index) in doubleStar(entity.star)">
              <img :src="star" :key="index" class="Star" />
            </template>
          </div>
        </div>
      </div>
      <div class="flex_center">
        <div class="Select_box" @click="selectRoomClick">Select Room</div>
      </div>

      <div class="position">
        <div class="Galaxy">
          <img src="../../assets/image/Hotel/position.png" class="peopleimg" />
          <span>{{ entity.address }}</span>
        </div>
        <div class="show-on-map">
          <a
            :href="entity.MapGoogle"
            target="_blank"
            v-if="entity && entity.MapGoogle"
          >
            Show on map
          </a>
        </div>
      </div>
    </div>

    <!-- Calendar -->
    <div class="content_bag">
      <div class="calendar">
        <div class="calendar_flex">
          <div class="calendar_left">
            <calender
              :default="dateRange"
              @change="calenderHandler"
              :min="minDate"
            />
          </div>
          <div class="calendar_right" @click="RoomIsGuestsShow = true">
            <div class="Room">
              <div class="calendar_Room">Room</div>
              <div class="calendar-quantity">{{ guest.roomNum }}</div>
            </div>
            <div class="Room">
              <div class="calendar_Room">Guests</div>
              <div class="calendar-quantity">{{ guest.adultNum }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- loading bar -->
    <div class="boxs" v-if="queryingPleaseShow">
      <div class="img-list">
        <img src="../../assets/image/share/img__loadingBar.png" />
        <img src="../../assets/image/share/img__loadingBar.png" />
      </div>
      <div class="box-text">Querying, please wait…</div>
    </div>

    <div style="background: #f4f6f8; overflow: hidden" v-if="noRoom">
      <div class="hotel-empty">
        <h4>No available room</h4>
        <p>
          Please reselect the check-in date, price range and check for the room
          availability.
        </p>
      </div>
    </div>

    <!-- 房间列表 -->
    <div class="roomtypelist room_box">
      <div
        class="roomtype_item Room_selection"
        v-for="(roomType, rtInd) in roomTypeList"
        :key="rtInd"
        v-show="showMore(rtInd)"
      >
        <div>
          <div class="Room_display">
            <!-- <img :src="../../assets/image/Hotel/basicimage.png" /> -->
            <custom-image src="" width="114" height="104" />
            <div class="selection_describe">
              <div class="describeName">{{ roomType.roomName }}</div>
              <!-- 酒店类型图标 -->
              <div class="describefacilities">
                <div
                  class="describefacilities_item"
                  v-if="getRoomInfo(roomType.roomCode).area"
                >
                  <img
                    src="../../assets/image/Hotel/measure.png"
                    class="fontsSize20"
                  />
                  <span class="measure">{{
                    getRoomInfo(roomType.roomCode).area
                  }}</span>
                </div>
                <!-- <div class="describefacilities_item" v-if="roomType.RoomFloor">
                  <img
                    src="../../assets/image/Hotel/floor.png"
                    class="fontsSize20"
                  />
                  <span class="measure">{{ roomType.RoomFloor }}</span>
                </div> -->
              </div>
              <!-- 显示隐藏房型按钮 -->
              <div class="fold">
                <div class="Amenitiesx" @click="openRoomDetail(roomType)">
                  Photos and Amenities
                </div>
                <i
                  class="PutAway"
                  :class="[hasRoomFold(rtInd) ? 'close-icon' : 'open-icon']"
                  @click="roomFold(rtInd)"
                  v-if="roomType.rates.length > 2"
                ></i>
              </div>
            </div>
          </div>
          <template v-for="(rateplan, index) in roomType.rates">
            <div
              class="room_service borderTop"
              v-if="ratePlansTrigger(rtInd, roomType.rates.length)"
              :key="index"
            >
              <!--maxperson-->
              <div class="service_item">
                <!-- <div
                  class="service_adult"
                  v-if="rateplan.Maxperson <= 3 && rateplan.Maxchildren <= 3"
                >
                  <img
                    src="../../assets/image/Hotel/adult.png"
                    class="peopleimg"
                    v-for="(m, i) in rateplan.Maxperson"
                    :key="i"
                  />
                </div> 
                <div class="service_adult" v-else-if="rateplan.Maxperson > 3">
                  <div>{{ rateplan.Maxperson }}x</div>
                  <div>
                    <img
                      src="../../assets/image/Hotel/adult.png"
                      class="peopleimg"
                    />
                  </div>
                </div>
                -->
                <!-- <div
                  class="service_adult"
                  v-if="rateplan.Maxchildren <= 3 && rateplan.Maxperson <= 3"
                >
                  <img
                    src="../../assets/image/Hotel/children.png"
                    class="peopleimg"
                    v-for="(m, i) in rateplan.Maxchildren"
                    :key="i"
                  />
                </div>
                <div class="service_adult" v-else-if="rateplan.Maxchildren > 3">
                  <div>{{ rateplan.Maxchildren }}x</div>
                  <div>
                    <img
                      src="../../assets/image/Hotel/children.png"
                      class="peopleimg"
                    />
                  </div>
                </div> -->
              </div>

              <!--bedtype-->
              <div class="service_item">
                <!-- <img
                  src="../../assets/image/Hotel/Double.png"
                  class="fontsSize20"
                /> -->
                <span class="Bed">{{ rateplan.bedType }}</span>
              </div>

              <!--wifi-->
              <div
                class="service_item"
                v-for="(amenities, amenitiesIndex) in rateplan.amenities || {}"
                :key="amenitiesIndex"
              >
                <span>{{ amenities }}</span>
              </div>

              <!--confirmation-->
              <!-- <div class="service_item" v-if="rateplan.supplierCancelPolicy">
                {{ rateplan.supplierCancelPolicy }}
                <img
                  src="../../assets/image/Hotel/confirmation.png"
                  class="fontsSize20"
                />
                <span class="Confirmation">Instant Confirmation</span>
              </div> -->

              <!--breakfast-->
              <!-- <div class="service_item" v-if="!rateplan.breakfastIncluded">
                <img
                  src="../../assets/image/Hotel/breakfast.png"
                  class="fontsSize20"
                />
                <span class="included">No Breakfast</span>
              </div>
              <div class="service_item" v-else>
                <img
                  src="../../assets/image/Hotel/Catering.png"
                  class="fontsSize20"
                />
                <span class="included">Include Breakfast</span>
              </div> -->

              <!--refundable-->
              <div class="service_item">
                <!-- <img
                  :src="
                    rateplan.RefundRule.RefundType === 1
                      ? require('@/assets/image/Hotel/Refundable.png')
                      : require('@/assets/image/Hotel/Non.png')
                  "
                  class="fontsSize20"
                /> -->

                <span class="included">{{
                  rateplan.cancelPolicyDescription
                }}</span>
              </div>
              <div class="payment_box">
                <div class="payment_left">
                  <div>
                    <!--<div class="save_text">SAVE 44%TODAY!</div>-->
                    <div class="payment_Price">
                      <div
                        style="
                          color: #333;
                          font-size: 12px;
                          line-height: 14px;
                          text-align: right;
                          margin-bottom: 5px;
                        "
                      >
                        <!-- {{ rateplan.TotalOfAllRoom?.OtherCurrency | rate }} -->
                        <!-- {{ rateplan.currency }} -->
                        <!-- {{ rateplan.BaseRateAvgPrice.Price | rate(userLocale) }} -->
                      </div>
                      <div class="Price_icon">
                        <div
                          @click="openPriceDetail(rateplan)"
                          class="price-warn"
                        >
                          <img
                            src="../../assets/image/Hotel/PriceIcon.png"
                            class="fontsSize16"
                          />
                        </div>
                        <div class="Symbol">$</div>
                        <!-- v-text="averagePrice(rateplan)" -->
                        <div class="Price">
                          {{ rateplan.price }}
                        </div>
                      </div>
                      <div class="hotelItemTips">
                        <span v-if="index === 0">Lower up to</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="payment_right">
                  <div class="PrepayOnline">Prepay Online</div>
                  <div class="payment_Button" @click="book(rateplan, roomType)">
                    Book
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- 【房间列表】 更多按钮 -->
      <div
        class="ShowTypes"
        @click="triggerMap.hotelList = !triggerMap.hotelList"
        v-if="hotelShowNum < roomTypeList.length"
      >
        <span>{{ rtRemainNum }}</span>
        <img
          :src="
            triggerMap.hotelList
              ? require('../../assets/image/Hotel/upperIcon.png')
              : require('../../assets/image/Hotel/openIcon.png')
          "
        />
      </div>
    </div>

    <div class="Policy">
      <div class="HotelPolicy">Hotel Policy</div>
      <div>
        <!-- <div> -->
        <template v-for="(policy, index) in hotelPolicy">
          <!-- v-show="index === 0 || triggerState('import')" -->
          <div class="Important_box" :key="index">
            <!-- <template v-if="policy.Title == 'Need to Know'">
                <div
                  :key="indexs"
                  v-for="(pItem, indexs) in policy.Items"
                  class="Important_box"
                >
                  <div class="Info">{{ pItem.Title }}</div>
                  <div class="According" v-html="pItem.Description"></div>
                </div>
              </template>
              <template v-else-if="policy.Title == 'Check-in and Check-out'">
                <div class="Info">{{ policy.Title }}</div>
                <div class="Checktime">
                  <div v-for="(pItem, indexs) in policy.Items" :key="indexs">
                    <div class="Checktime_item">{{ pItem.Description }}</div>
                  </div>
                </div>
              </template> -->
            <h3 class="Info">{{ policy.caption }}</h3>
            <div class="According" v-html="policy.rule"></div>
          </div>
        </template>
        <!--Payment Method-->
        <!-- triggerState('import') -->
        <div class="Important_box">
          <!-- <template v-for="(pm, index) in entity.onsitePayments">
              <div :key="index" v-if="pm == 'BankCard'" class="BankCard">
                <template v-for="(imgItem, index) in paymentImg['BankCard']">
                  <img
                    :src="imgItem"
                    class="fontsSize30"
                    :key="index"
                    v-if="pm == 'BankCard'"
                  />
                </template>
              </div>
            </template> -->
          <div class="Paymentmethod">
            <template v-for="(pm, index) in entity.onsitePayments">
              <span :key="index" v-if="!paymentImg[pm] && pm !== 'BankCard'">{{
                pm
              }}</span>
              <img
                :src="paymentImg[pm][0]"
                class="paypaitem1"
                :key="index"
                v-if="paymentImg[pm] && pm !== 'BankCard'"
              />
            </template>
          </div>
        </div>
        <!-- </div> -->

        <!-- <div class="ShowLess" @click="triggerState('import', true)">
          <div class="ShowLess_text">
            {{ !triggerState("import") ? "Show All" : " Show Less" }}
            <i
              class="PutAway"
              :class="[triggerState('import') ? 'close-icon' : 'open-icon']"
            ></i>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 酒店简介 -->
    <div class="profile" v-if="entity.description">
      <div class="Info">Hotel Profile</div>
      <div
        class="description"
        v-for="(desc, descIndex) in entity.description"
        :key="descIndex"
      >
        <h3>{{ descIndex }}</h3>
        <div class="else_describehotel" v-html="desc"></div>
      </div>
      <!-- <div
        class="ShowLess"
        @click="triggerState('intro', true)"
        v-if="entity.Intro"
      >
        <div class="ShowLess_text">
          {{ triggerState("intro") ? "Show Less" : "Show All" }}
          <i
            class="PutAway"
            :class="[triggerState('intro') ? 'close-icon' : 'open-icon']"
          ></i>
        </div>
      </div> -->
    </div>

    <!-- 服务 -->
    <div class="Services" v-if="saa && saa.length">
      <div class="Services_box">
        <div class="HotelPolicy">Services & Amenities</div>
        <!-- <template v-for="(amenity, index) in entity.ServicesAndAmenities"> -->
        <!-- <div> -->
        <div class="ServicesFor">
          <div class="ServicesFor_item" v-for="(item, ind) in saa" :key="ind">
            <img src="../../assets/image/Hotel/Opcomponent.png" />
            <span>{{ item }}</span>
          </div>
        </div>
        <!-- </div> -->
        <!-- </template> -->
      </div>

      <div class="ShowLess" @click="triggerState('service', true)">
        <div class="ShowLess_text">
          {{ triggerState("service") ? "Show Less" : "Show All" }}
          <i
            class="PutAway"
            :class="[triggerState('service') ? 'close-icon' : 'open-icon']"
          ></i>
        </div>
      </div>
    </div>

    <!--价格详情-->
    <van-popup
      v-model="hoteldetailsShow"
      closeable
      close-icon-position="top-left"
      round
      class="hoteldetails"
      @close="DaysboxShow = false"
      position="bottom"
    >
      <div class="hoteldetails_box">Price Details</div>
      <div class="hoteldetailsroll" v-if="currentRatePlan">
        <div class="OnlineBox">
          <div class="OnlinePrepay">
            <div class="OnlinePrepay_left">Prepay Online</div>
            <div class="OnlinePrepay_right">
              <div>
                <i class="smSymbol"> $ </i>
                {{ currentRatePlan.price }}
              </div>
              <span>Tax Included</span>
            </div>
          </div>
          <!-- @click="DaysboxShow = !DaysboxShow" -->
          <div class="OnlinePrepay margTop20">
            <div
              class="OnlineroomPrepay_left"
              @click="DaysboxShow = !DaysboxShow"
            >
              <span
                >{{ guest.roomNum }} room x {{ days }} night{{
                  days > 1 ? "s" : ""
                }}</span
              >

              <!-- upper.png -->
              <img
                v-if="days > 1"
                :src="
                  DaysboxShow
                    ? require('../../assets/image/Hotel/upper.png')
                    : require('../../assets/image/Hotel/openIcon.png')
                "
              />
            </div>
            <div class="OnlineroomPrepay_right">
              <i class="smSymbol"> $ </i>
              {{ roomPrice(currentRatePlan) }}
            </div>
          </div>
          <div class="Days_box" v-if="DaysboxShow && days > 1">
            <div
              class="JunRoom"
              v-for="(rate, ind) in calcUnitPrice({
                range: dateRange,
                room: guest.roomNum,
                price: roomPrice(currentRatePlan),
              })"
              :key="ind"
            >
              <div class="JunRoom_left">
                {{ rate.date }}, {{ rate.room | complex("Room") }}
              </div>
              <div class="JunRoom_right">
                {{ rate.room }} x $ {{ rate.unit }}
              </div>
            </div>
          </div>
          <div class="Includes">
            <span>
              Includes
              <i class="smSymbol"> $ </i>
              {{ currentRatePlan.tax }} in taxes & fees
            </span>
          </div>

          <!-- <div class="pop-list" v-if="currentRatePlan.tax">
            <p>Extra <small>Pay at the property</small></p>
            <p>
              {{ currentRatePlan.currency }}
              {{ currentRatePlan.tax }}
            </p>
          </div> -->
        </div>
      </div>
    </van-popup>

    <van-popup v-model="feesexplain" closeable class="feesexplain">
      <div
        class="feesexplainPopup"
        v-if="currentRatePlan && currentRatePlan.TotalOfAllRoom"
      >
        <div class="Includestaxes">
          Includes
          <i class="smSymbol"> $ </i>
          {{ currentRatePlan.TotalOfAllRoom.TaxesAndServices.Price }} in taxes &
          fees
        </div>
        <template v-for="(tItem, k) in currentRatePlan.Taxes">
          <div class="fees_item" :key="k" v-if="currentRatePlan.Taxes != null">
            <div class="feesitem_left">{{ tItem.Type }}</div>
            <div class="feesitem_right">
              <i class="smSymbol">$</i>{{ tItem.Price }}
            </div>
          </div>
        </template>
        <template v-for="(tItem, k) in currentRatePlan.Fees">
          <div class="fees_item" :key="k" v-if="currentRatePlan.Fees != null">
            <div class="feesitem_left">{{ tItem.Type }}</div>
            <div class="feesitem_right">
              <i class="smSymbol">$</i>{{ tItem.Price }}
            </div>
          </div>
        </template>
      </div>
    </van-popup>

    <!--房型详情-->
    <van-popup
      v-model="RoomDetailsShow"
      closeable
      close-icon-position="top-left"
      round
      position="bottom"
    >
      <div class="RoomDetails">
        <div class="RoomDetails_title">Room Details</div>
        <div class="RoomDetailsRoll">
          <div
            class="swipe_box"
            v-if="currentRoomType.images && currentRoomType.images.length"
          >
            <van-swipe
              class="my-swipe"
              :show-indicators="false"
              @change="RotationarrChange"
              ref="swiper"
            >
              <van-swipe-item
                v-for="(item, index) in currentRoomType.images"
                :key="index"
              >
                <van-image fit="cover" :src="item.value" height="200" />
              </van-swipe-item>
            </van-swipe>
            <div class="RotationLeft" @click="$refs.swiper.prev()">
              <img src="../../assets/image/Hotel/RotationLeft.png" alt="" />
            </div>
            <div class="RotationRight" @click="$refs.swiper.next()">
              <img src="../../assets/image/Hotel/RotationRight.png" alt="" />
            </div>
            <div class="quantity">
              <img
                src="../../assets/image/Hotel/Rotati.png"
                class="peopleimg"
              />
              <div class="quantityNumbers">
                {{ currentRoomType.PhotoIndex + 1 }}&nbsp;/&nbsp;{{
                  currentRoomType.images.length
                }}
              </div>
            </div>
          </div>

          <div class="King">{{ currentRoomType.roomName }}</div>
          <div class="detailsfacilities" v-if="currentRoomType.area">
            <div class="detailsfacilities_item">
              <img
                src="../../assets/image/Hotel/measure.png"
                class="fontsSize20"
              />
              <span class="measure">{{ currentRoomType.area }}</span>
            </div>
            <!-- <div
              class="detailsfacilities_item"
              v-if="currentRoomType.RoomFloor"
            >
              <img
                src="../../assets/image/Hotel/floor.png"
                class="fontsSize20"
              />
              <span class="measure">{{ currentRoomType.RoomFloor }}</span>
            </div> -->
            <!-- <div
              class="detailsfacilities_item"
              v-if="currentRoomType.Window != 0 && currentRoomType.Window != 2"
            >
              <img
                src="../../assets/image/Hotel/Windowed.png"
                class="fontsSize20"
              />
              <span class="measure">{{ currentRoomType.WindowDesc }}</span>
            </div> -->
          </div>

          <div class="Rollhoteldetails">
            <!--<div class="Rollhoteldetails_item">
                            <div class=""><img src="../../assets/image/Hotel/RoomDetailsShow/Numberpeople.png"></div>
                            <div class="Rollhoteldetailsdescribe">2 adults</div>
                        </div>-->
            <!-- <div
              class="Rollhoteldetails_item"
              v-if="
                currentRoomType.bedType != null && currentRoomType.bedType != ''
              "
            >
              <div class="">
                <img
                  src="../../assets/image/Hotel/RoomDetailsShow/singlebed.png"
                />
              </div>
              <div class="Rollhoteldetailsdescribe">
                {{ currentRoomType.bedType }}
              </div>
            </div> -->
            <div
              class="Rollhoteldetails_items"
              v-html="currentRoomType.description"
            ></div>
            <!--<div class="Rollhoteldetails_item">
                            <div class=""><img src="../../assets/image/Hotel/RoomDetailsShow/Restaurant.png"></div>
                            <div class="Rollhoteldetailsdescribe">
                                <div class="Breakfasts">2 Breakfasts</div>
                                <div>Extra Child's Breakfast(O-2 years old): RMB$97</div>
                            </div>
                        </div>-->
            <!-- <div class="Rollhoteldetails_item">
              <div class="">
                <img src="../../assets/image/Hotel/RoomDetailsShow/wifi.png" />
              </div>
              <div class="Rollhoteldetailsdescribe">
                {{ currentRoomType.WifiDesc }}
              </div>
            </div> -->
          </div>
          <div class="Bottombutton">
            <div class="RoomDetails_button" @click="RoomDetailsShow = false">
              Select Room
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="NoRoomshow" round :close-on-click-overlay="false">
      <div class="NoRoom_box">
        <div class="NoRoom_titleBox">
          <div><img src="../../assets/image/Hotel/warning.png" /></div>
          <div class="available">
            The rooms have been sold out, and other rooms are available.
          </div>
        </div>
        <div class="ok" @click="NoRoomshow = false">ok</div>
      </div>
    </van-popup>

    <guest-pop
      :default="guest"
      :pop.sync="RoomIsGuestsShow"
      @change="guestHandle"
    />
  </div>
</template>

<script>
import NavBar from "@/components/Navbar";
import Calender from "@/components/Calender";
import GuestPop from "@/components/Guest";
import previewer from "vue-picture-preview";

import { getHotelDetail, requireSearch } from "@/apis/home";
import { getDaysBetween, updateUrl, excludeSearch } from "@/shared/tools";
import {
  // bedTypes,
  currency as currencyData,
} from "@/shared/data";

import baseMixin from "@/mixins/base.mixin";
import hotelMixin from "@/mixins/hotel.mixin";

let loading;

export default {
  name: "hotelDetail",
  components: {
    NavBar,
    Calender,
    GuestPop,
    previewer,
  },
  mixins: [baseMixin, hotelMixin],
  data() {
    return {
      minDate: new Date(),
      noRoom: false,
      paymentImg: {
        WeChat: [require("../../assets/image/Hotel/paypaitem2.png")],
        PayPal: [require("../../assets/image/Hotel/paypaitem1.png")],
        Alipay: [require("../../assets/image/Hotel/paypaitem3.png")],
        BankCard: [
          require("../../assets/image/Hotel/Carditem1.png"),
          require("../../assets/image/Hotel/Carditem2.png"),
          require("../../assets/image/Hotel/Carditem3.png"),
        ],
      },
      ratePlanInfo: {},
      entity: {},
      roomTypeList: [],
      dateRange: null,
      guest: {
        adultNum: 1,
        childAges: [],
        childNum: 0,
        roomNum: 1,
      },
      userLocale: null,
      hotelPhotos: [],
      hotelPhotoIndex: 1,
      currentRoomType: {},
      currentRatePlan: {},
      RoomIsGuestsShow: false,
      hotelShowNum: 3,
      triggerMap: {
        hotelList: false,
        roomFold: [0],
        import: false,
        service: false,
        intro: false,
      },
      hoteldetailsShow: false,
      DaysboxShow: false,
      feesexplain: false,
      RoomDetailsShow: false,
      NoRoomshow: false,
      queryingPleaseShow: false,
    };
  },
  computed: {
    hotelPolicy() {
      // const hotelPolicy = this.entity && this.entity.Policies;
      // const result = [];
      // if (!hotelPolicy) return [];
      // hotelPolicy.forEach((res) => {
      //   if (res.Items && res.Items.length) {
      //     result.push({
      //       Title: res.Title,
      //       Items: res.Items,
      //     });
      //   }
      // });
      const {
        //   fees,
        //   phone,
        //   latestCheckoutTime,
        //   latestCheckinTime,
        //   pets,
        enhancedHouseRules,
      } = this.entity;
      // const result = {
      //   Fees: fees,
      //   "Contact Information": phone,
      //   "Check-in and Check-out": `<div class="Checktime"><div><div class="Checktime_item">Check-in from ${latestCheckinTime}</div></div>
      //             <div><div class="Checktime_item">Check-out before ${latestCheckoutTime}</div></div></div>`,
      //   Pets: pets,
      // };

      return enhancedHouseRules || [];
    },
    saa() {
      let amenities = this.entity.amenities || {};
      amenities = Object.values(amenities);
      if (!amenities.length) return [];

      if (!this.triggerState("service")) {
        return amenities.slice(0, 8);
      }
      return amenities;
    },
    photoReview() {
      return (this.hotelPhotos || []).map((t) => {
        return { src: t.value };
      });
    },
    days() {
      if (this.dateRange.start && this.dateRange.end) {
        return getDaysBetween(this.dateRange.start, this.dateRange.end);
      }
      return "1 night";
    },
    rtRemainNum() {
      let number = this.roomTypeList.length - this.hotelShowNum;
      return this.triggerMap.hotelList
        ? `Hide ${number} Remaning Room Types`
        : `Show ${number} Remaning Room Types`;
    },
  },
  created() {
    // if (!this.queryStore.v) {
    //   this.$router.replace({
    //     path: "/hotel-detail",
    //     query: {
    //       ...this.queryStore,
    //       // v: this.returnRandom(),
    //     },
    //   });
    // }
    const { guest, checkIn, checkOut } = this.initSearch();
    this.dateRange = {
      start: checkIn,
      end: checkOut,
    };
    this.$set(this, "guest", guest);

    this.searchStore();
    this.init();
  },
  destroyed() {
    loading && loading.clear();
  },
  activated() {
    let googleRoom = localStorage.getItem("googleRoom");
    if (googleRoom) {
      googleRoom = JSON.parse(googleRoom);
      const params = {};
      if (googleRoom.guest) {
        this.$set(this, "guest", googleRoom.guest);
        params["room"] = googleRoom.guest.roomNum;
        params["adult"] = googleRoom.guest.adultNum;
        params["children"] = googleRoom.guest.childNum;
        params["age"] = googleRoom.guest.childAges.join(",");
      }

      if (googleRoom.dateRange) {
        this.dateRange = googleRoom.dateRange;

        params["checkIn"] = googleRoom.dateRange.start;
        params["checkOut"] = googleRoom.dateRange.end;
      }

      localStorage.removeItem("googleRoom");
      updateUrl(params, "/hotel-detail");

      this.searchStore();
      this.init();
    }
  },
  filters: {
    rate(price) {
      if (price && price.Show) {
        return `${price.Currency} ${price.Price}`;
      }
      return "";
    },
    // rate(price, userLocale) {
    //   if (!userLocale) return "";
    //   const { rate, currency } = userLocale;
    //   const transform = parseInt(rate * price * 100) / 100;
    //   return `${currency} ${transform}`;
    // },
  },
  methods: {
    roomPrice({ price, tax }) {
      price = price || 0;
      tax = tax || 0;
      let total = parseInt(Math.ceil((price - tax) * 1000)) / 1000;
      return total.toFixed(2);
    },
    getRoomInfo(code) {
      const result = this.ratePlanInfo[code] || {};
      return result;
    },
    searchStore() {
      const { dateRange, guest } = this;
      const store = JSON.parse(localStorage.getItem("hotelfilter") || "{}");

      const result = {
        keyword: store.keyword || "",
        city: store.city || "",
        dateRange,
        guest,
        latitude: store.latitude || "",
        longitude: store.longitude || "",
      };

      localStorage.setItem("hotelfilter", JSON.stringify(result));
    },
    triggerState(key, trigger = false) {
      if (trigger) this.triggerMap[key] = !this.triggerMap[key];
      return this.triggerMap[key];
    },
    ratePlansTrigger(index, length) {
      if (length <= 2) return true;
      return this.hasRoomFold(index);
    },
    hasRoomFold(index) {
      return this.triggerMap.roomFold.indexOf(index) >= 0;
    },
    roomFold(index) {
      const roomFold = this.triggerMap.roomFold;
      const hasIndex = roomFold.indexOf(index);

      if (hasIndex >= 0) {
        roomFold.splice(hasIndex, 1);
      } else {
        roomFold.push(index);
      }
    },
    showMore(index) {
      return (
        index < this.hotelShowNum ||
        (this.triggerMap.hotelList && index >= this.hotelShowNum)
      );
    },
    updateUrl(params) {
      this.searchStore();
      const searchUrl = location.search.replace("?", "");
      const search = searchUrl.split("&").reduce((total, item) => {
        const [key, value] = item.split("=");

        total[key] = value;

        return total;
      }, {});

      const query = {
        ...search,
        ...params,
      };

      let queryUrl = Object.entries(query).reduce((total, item) => {
        total += `&${item[0]}=${item[1]}`;
        return total;
      }, "");
      queryUrl = queryUrl.replace("&", "?");

      history.replaceState({}, null, `/hotel-detail${queryUrl}`);
    },
    calenderHandler(e) {
      this.$set(this, "dateRange", e);

      this.updateUrl({
        checkInDate: e.start,
        checkOutDate: e.end,
      });

      this.getRoomTypeList();
    },
    guestHandle(e) {
      this.$set(this, "guest", e);

      this.updateUrl({
        room: e.roomNum,
        age: e.childAges,
        children: e.childNum,
        adults: e.adultNum,
      });
      this.getRoomTypeList();
    },
    includeBed(value, include) {
      return include.includes(value);
    },
    init() {
      this.getEntity();
      this.getRoomTypeList();
    },
    getEntity() {
      getHotelDetail(this.queryStore.hotelId).then((response) => {
        const { status, data } = response;
        const res = data.data;
        if (status == 200) {
          this.entity = res;
          this.hotelPhotos = res.images || [];
          const ratePlanInfo = {};

          (res.rooms || []).forEach((rateplan) => {
            ratePlanInfo[rateplan.roomId] = rateplan;
          });

          this.ratePlanInfo = ratePlanInfo;

          // if (res.LocalDate) {
          //   this.minDate = new Date(res.LocalDate);
          // }
        }
      });
    },
    requireSearchCurry(params) {
      // const locale = localStorage.getItem("language");
      // const currency = localStorage.getItem("currency");
      const { dateRange, guest, queryStore, language, currency } = this;
      const { hotelId, userDevice, gSite } = queryStore;

      // hotelId, roomId, checkIn, checkOut, room, adult, children, age, userCountry, gSite,
      // userDevice
      // locale
      // currency
      // paymentId -
      // taxPrice
      // totalPrice
      // planId -
      // ruleId -
      // userLanguage -

      // console.log(dateRange);
      let data = {
        Hotels: [hotelId],
        CheckIn: dateRange.start,
        CheckOut: dateRange.end,
        // RoomId: "",
        Occupancy: {
          Rooms: guest.roomNum,
          Adults: guest.adultNum,
          ChildrenAges: guest.childAges,
        },

        // CountryCodes: "",
        // AllCountries: "",
        // CloseHotelIds: "",
        // CloseRoomIds: "",
        // IsPreCheck: "",

        // Locale: language,
        // UserDevice: userDevice,
        // ChildNum: guest.childNum,

        // GoogleSite: gSite,
        // SellCurrency: currency,
        // ...excludeSearch([
        //   "hotelId",
        //   "gSite",
        //   "locale",
        //   "currency",
        //   "adult",
        //   "children",
        //   "age",
        //   "room",
        //   "userDevice",
        //   "checkIn",
        //   "checkOut",
        // ]),
      };

      if (params) data = Object.assign({}, data, params);
      return requireSearch(data);
    },
    getRoomTypeList() {
      this.queryingPleaseShow = true;
      this.roomTypeList = [];
      this.noRoom = false;

      this.requireSearchCurry().then((response) => {
        this.queryingPleaseShow = false;
        const { succeeded, data } = response.data;
        if (succeeded) {
          if (data && data.hotels && data.hotels.length) {
            const list = (data.hotels[0] || { rooms: [] }).rooms;
            // console.log(list);
            this.roomTypeList = list;
            if (!list.length) this.noRoom = true;

            // const currency = localStorage.getItem("currency");
            // TODO 汇率算法
            // const { totalPrice, userCurrency } = this.queryStore;
            // if (
            //   totalPrice &&
            //   userCurrency !== this.currency &&
            //   !this.userLocale
            // ) {
            //   const minPrice = list[0].RatePlans[0].TotalOfAllRoom.NeedPay;
            //   let rate = totalPrice / minPrice.Price;
            //   rate = rate || 1;

            //   this.userLocale = {
            //     currency: userCurrency,
            //     rate: parseFloat(parseInt(rate * 1000000) / 1000000),
            //   };
            // }
            return;
          } else {
            this.noRoom = true;
          }
        } else {
          this.noRoom = true;
        }

        // if (rsp.Message == "No availablity") {
        //   this.noRoom = true;
        // }
      });
    },

    sumRatePrice(item) {
      var sum = 0;
      var list = item.Rates;
      if (list != null && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          sum += list[i].Price;
        }
      }
      return sum;
    },
    sumRatePriceRooms(item, num) {
      var sumPerRoom = this.sumRatePrice(item);
      var sum = num * sumPerRoom;
      return sum;
    },
    taxesAndFees: function (item) {
      var sum = 0;
      var list = item.Taxes;
      if (list != null && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          sum += list[i].Price;
        }
      }
      list = item.Fees;
      if (list != null && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          sum += list[i].Price;
        }
      }
      return sum;
    },
    getCurrencySymbol() {
      return "$";
    },
    book(rateplan, room) {
      const { language, currency, dateRange, guest, queryStore, userLocale } =
        this;
      const { hotelId, gSite, userDevice, userCountry, userCurrency, v } =
        queryStore;

      const { start, end } = dateRange;
      const { adultNum, childNum, roomNum, childAges } = guest;

      var info = {
        hotelId,
        roomId: room.roomCode,
        rateplanId: rateplan.ratePlanCode,
        checkInDate: start,
        checkOutDate: end,
        room: roomNum,
        adults: adultNum,
        children: childNum,
        age: childAges.join(","),
      };

      this.$router.push({
        path: "/booking",
        query: {
          ...excludeSearch(),
          ...info,
        },
      });
    },
    openRoomDetail(room) {
      this.currentRoomType = {
        ...this.getRoomInfo(room.roomCode),
        roomName: room.roomName,
        PhotoIndex: 0,
      };
      this.RoomDetailsShow = true;
      // TODO
      // $(".RoomDetailsRoll").animate({ scrollTop: 0 });
    },
    openPriceDetail(rateplan) {
      // console.log(rateplan);
      console.log(rateplan);
      this.currentRatePlan = rateplan;
      // console.log(rateplan);
      this.hoteldetailsShow = true;
    },
    onConfirmRooms() {
      this.RoomIsGuestsShow = false;
      this.getRoomTypeList();
      // this.search();
    },
    selectRoomClick() {
      const roomDom = document.querySelector(".room_box");
      if (roomDom) roomDom.scrollIntoView();
    },
    RotationarrChange(index) {
      //this.RotationarrIndex = index + 1
      this.currentRoomType.PhotoIndex = index;
    },
    HotelPhotoChange(index) {
      this.hotelPhotoIndex = index + 1;
    },
    // dateFormat: function (val) {
    //   var str = val;
    //   var date = new Date(val);
    //   var year = date.getFullYear();
    //   var month = date.getMonth() + 1;
    //   var day = date.getDate();

    //   str = year + "-";
    //   if (month < 10) str += "0";
    //   str += month + "-";
    //   if (day < 10) str += "0";
    //   str += day;

    //   return str;
    // },
  },
};
</script>

<style lang="scss">
.According {
  font-size: 14px;
  color: #000000;
  margin-top: 10px;
  line-height: 20px;

  div,
  p,
  li {
    font-size: 14px;
  }
}

.else_describehotel,
.describehotel_item {
  font-size: 14px !important;
  line-height: 20px;
  overflow: hidden;

  div,
  p,
  span,
  li {
    font-size: 14px !important;
    line-height: 20px;
  }
}

.else_describehotel {
  // max-height: 80px;
}
</style>

<style scoped lang="scss">
.hotel-detail-container {
  .Rotation_box {
    width: 100%;
    height: 100%;
    position: relative;

    img {
      pointer-events: none;
    }
  }

  .Rotation_box {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .RotationLeft {
    position: absolute;
    left: 15px;
    top: 40%;
  }

  .RotationRight {
    position: absolute;
    right: 15px;
    top: 40%;
  }

  .quantity {
    position: absolute;
    right: 0px;
    bottom: 8px;
    /* width: 50px; */
    /* height: 20px; */
    background: #000000;
    opacity: 0.6;
    border-radius: 20px 0px 0px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
  }

  .quantityNumbers {
    color: #cccccc;
    font-size: 14px;
    margin-left: 3px;
    padding-top: 3px;
  }

  .van-image {
    width: 100%;
    height: 100%;
  }

  .van-swipe-item img {
    width: 100%;
    height: 200px;
  }

  .hotelName {
    padding: 15px;
  }

  .Hotel_title {
    display: flex;
    align-items: center;
  }

  .hotel_text {
    margin-right: 10px;
    font-size: 24px;
    word-break: break-all;
    font-weight: bold;
    color: #333333;

    .Stars_icon {
      display: inline-block;
      height: 15px;

      img {
        max-width: 100%;
        height: 15px;
        margin-right: 2px;
      }
    }
    /* flex: 1; */
  }

  // .Stars_icon {
  //   min-width: 96px;

  //   text-align: right;
  // }

  .flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Select_box {
    margin-top: 17px;
    margin-bottom: 15px;
    width: 345px;
    height: 47px;
    background: #66c976;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #ffffff;
  }

  .position {
    padding-top: 15px;
    border-top: 1px solid #eceff5;
    font-size: 14px;
  }

  .Galaxy {
    line-height: 20px;
    display: flex;

    span {
      font-size: 14px;
    }

    // align-items: center;
    // align-items: center;
    img {
      margin: 3px 0;
    }
  }

  // .show-on-map {
  //   padding-left: 16px;
  //   /* font-size: 14px; */
  //   color: #66C976;
  //   line-height: 20px;
  // }

  .content_bag {
    background-color: #f4f6f9;
    // padding: 0 0px 10px;
  }

  .calendar {
    background-color: #fff;
    padding: 15px;
    border-top: 10px solid rgb(244, 246, 248);
  }

  .calendar_flex {
    display: flex;
    width: 100%;
  }

  .calendar_left {
    display: flex;
    align-items: center;
    /* width: 250px; */
    width: 90%;
    /* padding-right: 5px; */
    border-right: 1px solid #eceff5;
    padding-right: 15px;
  }

  .calendar_right {
    display: flex;
    flex: 1;
    padding-left: 15px;
  }

  .text_right {
    text-align: right;
  }

  .Policy {
    padding: 15px;
    padding-bottom: 22px;
    border-top: 10px solid #f4f6f9;
  }

  .HotelPolicy {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }

  .ShowLess {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  .ShowLess_text {
    font-size: 14px;
    color: #66c976;
    margin-right: 10px;
    line-height: 20px;
  }

  .profile {
    padding: 15px;
    padding-bottom: 22px;
    border-top: 10px solid #f4f6f9;
  }

  .profile .description h3 {
    font-size: 14px;
  }

  // .Services {
  //   padding: 15px;
  //   padding-bottom: 22px;
  //   border-top: 10px solid #f4f6f9;
  //   border-bottom: 40px solid #f4f6f9;
  // }

  .ServicesFor {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    & + .ServicesFor {
      margin-top: 0;
    }
  }

  .ServicesFor_item {
    width: 50%;
    display: flex;
    margin-top: 10px;

    img {
      width: 12px;
      height: 11px;
      display: block;
      margin-top: 4px;
    }

    span {
      color: #000000;
      font-size: 14px;
      flex: 1;
      word-break: break-all;
      text-indent: 2px;
      margin-left: 6px;
    }
  }

  .Important_box {
    margin-top: 20px;

    h3 {
      font-size: 14px;
    }

    .BankCard {
      img {
        margin-right: 10px;
      }
    }
  }

  // .Checktime {
  //   background: #f8f8f8;
  //   border-radius: 4px;
  //   width: 100%;
  //   display: flex;
  //   margin-top: 10px;
  // }

  // .Info {
  //   font-size: 14px;
  //   font-weight: bold;
  //   color: #000000;
  // }

  // .Checktime_item {
  //   width: 50%;
  //   padding: 10px;
  //   font-size: 12px;
  //   color: #000000;
  //   line-height: 18px;
  // }

  // .According {
  //   font-size: 14px;
  //   color: #000000;
  //   margin-top: 10px;
  //   text-align: justify;
  // }
  // .room_box {
  //   padding: 15px;
  // }

  .room_basic {
    padding-bottom: 15px;
    border-bottom: 1px solid #eceff5;
    display: flex;
  }

  // .basicimage {
  //   width: 114px;
  //   height: 80px;
  // }

  // .room_namebox {
  //   margin-left: 20px;
  // }

  // .hotel_Name {
  //   font-size: 16px;
  //   font-weight: bold;
  //   color: #000000;
  //   line-height: 20px;
  //   margin-top: 3px;
  // }

  // .hotel_Basics {
  //   display: flex;
  //   margin-top: 10px;
  // }

  // .hotelBasics_item {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin-right: 15px;
  // }

  .measure {
    text-indent: 6px;
    font-size: 14px;
    color: #000000;
  }

  .room_service {
    padding: 15px 0px 0;
    // border-bottom: 1px solid #eceff5;
  }

  .borderTop {
    border-top: 1px solid #eceff5;
    margin-top: 15px;
  }

  .room_service:last-child {
    border-bottom: none;
  }

  .service_item {
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
      width: 15px;
      height: auto;
      display: block;
    }

    span:not(.division) {
      font-size: 12px;
      margin-left: 6px;
    }

    &:first-child {
      margin-top: 0px;
    }
  }

  .service_children {
    margin-left: 10px;
  }

  .service_adult {
    font-size: 12px;
    color: #000000;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 3px;
  }

  .payment_Price {
    margin-right: 10px;
  }

  .Price_icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-top: 19px;
  }

  .Symbol {
    font-size: 18px;
    color: #ff6454;
    font-weight: bold;
    margin-left: 3px;
  }

  .Price {
    font-size: 18px;
    font-weight: bold;
    color: #ff6454;
    line-height: 20px;
  }

  .hotel-detail-box {
    margin: 17px 15px;
    display: flex;
  }

  .hotel-detail-riht {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  // .flex_center {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  .UsdBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
  }

  .UsdBox img {
    margin-left: 5px;
  }
  .country {
    width: 20px;
    height: 14px;
  }
  .Usd_text {
    font-size: 14px;
    color: #000000;
    font-family: Microsoft YaHei;
  }

  .CardType {
    display: flex;
    align-items: center;
  }

  .CardType img {
    display: block;
    margin-right: 10px;
    height: 20px;
    width: auto;
  }

  // .Rotation_box {
  //   width: 100%;
  //   height: 100%;
  //   position: relative;
  // }

  .RotationLeft {
    position: absolute;
    left: 15px;
    top: 40%;
  }

  .RotationRight {
    position: absolute;
    right: 15px;
    top: 40%;
  }

  .quantity {
    position: absolute;
    right: 0px;
    bottom: 8px;
    /* width: 50px; */
    /* height: 20px; */
    background: #000000;
    opacity: 0.6;
    border-radius: 20px 0px 0px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
  }

  .quantityNumbers {
    color: #cccccc;
    font-size: 14px;
    margin-left: 3px;
    padding-top: 3px;
  }

  .van-image {
    width: 100%;
    height: 100%;
  }

  .van-swipe-item img {
    width: 100%;
    height: 200px;
  }

  .hotelName {
    padding: 15px;
  }

  // .Hotel_title {
  //   display: flex;
  //   align-items: center;
  // }

  // .hotel_text {
  //   margin-right: 10px;
  //   font-size: 24px;
  //   font-weight: bold;
  //   color: #333333;
  //   /* flex: 1; */
  // }

  .flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // .Select_box {
  //   margin-top: 17px;
  //   margin-bottom: 15px;
  //   width: 345px;
  //   height: 47px;
  //   background: #FF6454;
  //   border-radius: 4px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-weight: bold;
  //   color: #ffffff;
  // }

  .position {
    padding-top: 15px;
    border-top: 1px solid #eceff5;
    font-size: 14px;
  }

  .show-on-map {
    padding-left: 16px;
    /* font-size: 14px; */
    color: #66c976;

    line-height: 20px;

    a {
      font-size: 14px;
    }
  }

  .calendar {
    background-color: #fff;
    padding: 15px;
  }

  .calendar_flex {
    display: flex;
    width: 100%;
  }

  .calendar_left {
    display: flex;
    align-items: center;
    /* width: 250px; */
    width: 90%;
    /* padding-right: 5px; */
    border-right: 1px solid #eceff5;
    padding-right: 15px;
  }

  .calendar_right {
    display: flex;
    flex: 1;
    padding-left: 15px;
  }

  .text_right {
    text-align: right;
  }

  .finaldateright {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #000000;
  }

  .finaldate {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #000000;
  }

  .days {
    flex: 1;
    font-size: 12px;
    color: #000000;
    text-align: center;
  }

  .Splitline {
    width: 2px;
  }

  .calendar_Room {
    font-size: 12px;
    color: #999999;
    line-height: 20px;
  }

  .Room {
    padding-right: 10px;
    flex: 1;
    text-align: center;
  }

  .calendar-quantity {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }

  // .room_box {
  //   padding: 15px;
  // }

  // .room_basic {
  //   padding-bottom: 15px;
  //   border-bottom: 1px solid #eceff5;
  //   display: flex;
  // }

  // .basicimage {
  //   width: 114px;
  //   height: 80px;
  // }

  // .room_namebox {
  //   margin-left: 20px;
  // }

  // .hotel_Name {
  //   font-size: 16px;
  //   font-weight: bold;
  //   color: #000000;
  //   line-height: 20px;
  //   margin-top: 3px;
  // }

  // .hotel_Basics {
  //   display: flex;
  //   margin-top: 10px;
  // }

  // .hotelBasics_item {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin-right: 15px;
  // }

  .measure {
    text-indent: 6px;
    font-size: 14px;
    color: #000000;
  }

  .Amenities {
    margin-top: 10px;
    font-size: 14px;
    color: #66c976;
  }

  .borderTop {
    border-top: 1px solid #eceff5;
    margin-top: 15px;
  }

  .room_service:last-child {
    border-bottom: none;
  }

  // .service_children {
  //   margin-left: 10px;
  // }

  .service_adult {
    font-size: 12px;
    color: #000000;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 3px;
  }

  .division {
    padding: 0px 3px;
    font-size: 14px;
    color: #000000;
  }

  .Bed {
    color: #000000;
    font-size: 12px;
    display: block;
    // text-indent: 5px;
  }

  .Basics_icon + .Basics_icon {
    margin-left: 4px;
  }

  .Confirmation {
    text-indent: 5px;
    color: #00adb2;
    font-size: 14px;
  }

  .included {
    font-size: 14px;
    color: #000000;
    // text-indent: 5px;
  }

  .provide {
    font-size: 14px;
    color: #999999;
    text-indent: 3px;
  }

  .payment_box {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .payment_top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .save_text {
    padding: 3px 10px;
    line-height: 14px;
    background-color: #ff0c00;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 0;
    width: 110px;
    color: #ffffff;
    margin-right: 10px;
  }

  .PrepayOnline {
    font-size: 12px;
    color: #000000;
    width: 90px;
    text-align: center;
    margin-bottom: 5px;
  }

  .smSymbol {
    font-size: 12px;
  }

  .payment_bottom {
    display: flex;
    justify-content: flex-end;
  }

  .payment_Button {
    width: 90px;
    height: 44px;
    background: #66c976;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
  }

  .payment_left {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  // .payment_Price {
  //   margin-right: 10px;
  // }

  .Symbol {
    font-size: 18px;
    color: #ff6454;
    font-weight: bold;
    margin-left: 3px;
  }

  .Price {
    font-size: 18px;
    font-weight: bold;
    color: #ff6454;
    line-height: 20px;
  }

  .original {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: #999999;
    text-decoration: line-through;
  }

  // .payment_SoldOut {
  //   width: 90px;
  //   height: 44px;
  //   background: #cccccc;
  //   border-radius: 2px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 16px;
  //   color: #ffffff;
  //   font-weight: bold;
  // }

  .Room_selection {
    border-top: 10px solid #f4f6f9;
    padding: 15px;
  }

  .Room_display {
    display: flex;
  }

  .selection_describe {
    margin-left: 20px;
    flex: 1;
  }

  .selection_describe > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .describeName {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
  }

  .describefacilities {
    display: flex;
    margin-top: 10px;
    height: 20px;
    display: flex;
    align-items: center;
  }

  .describefacilities_item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }

  .PutAway {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
  }

  .PutAway.close-icon {
    background: url(../../assets/image/Hotel/PutAway.png) no-repeat;
    background-size: 100%;
  }

  .PutAway.open-icon {
    background: url(../../assets/image/Hotel/open.png) no-repeat;
    background-size: 100%;
  }

  .Amenitiesx {
    /* margin-top: 10px; */
    font-size: 14px;
    color: #66c976;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }

  .fold {
    display: flex;
  }

  .ShowTypes {
    border-top: 10px solid #f4f6f9;
    padding: 15px;
    font-size: 14px;
    color: #66c976;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ShowTypes img {
    margin-left: 10px;
  }

  .Policy {
    padding: 15px;
    padding-bottom: 22px;
    border-top: 10px solid #f4f6f9;
  }

  .HotelPolicy {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }

  .Important_box {
    margin-top: 20px;
  }

  .Info {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px 0;
    color: #000000;
  }

  .Checktime {
    background: #f8f8f8;
    border-radius: 4px;
    width: 100%;
    display: flex;
    margin-top: 10px;
  }

  .Checktime_item {
    width: 50%;
    padding: 10px;
    font-size: 12px;
    color: #000000;
    line-height: 18px;
  }

  .Paymentmethod {
    margin-top: 10px;
    span {
      font-size: 12px;
      color: #333;

      display: inline-block;
      background: #eee;
      padding: 4px 8px;
      margin-right: 4px;
      margin-bottom: 4px;
      font-size: 15px;
    }
    img {
      margin-right: 10px;
      height: 20px;
      width: auto;
    }
  }

  // .ShowLess {
  //   margin-top: 25px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  // .ShowLess_text {
  //   font-size: 14px;
  //   color: #66C976;
  //   margin-right: 10px;
  //   line-height: 20px;
  // }

  // .profile {
  //   padding: 15px;
  //   padding-bottom: 22px;
  //   border-top: 10px solid #f4f6f9;
  // }

  // .Opened_box {
  //   width: 95%;
  //   background: #f8f8f8;
  //   border-radius: 4px;
  //   display: flex;
  //   flex-wrap: wrap;
  //   padding: 10px;
  //   padding-top: 0px;
  //   margin-top: 10px;
  // }

  // .Opened_item {
  //   width: 50%;
  //   margin-top: 10px;
  //   font-size: 12px;
  //   color: #000000;
  // }

  .profileShow_img {
    margin-top: 10px;
    width: 100%;

    img {
      width: 100%;
      height: 170px;
    }
  }

  .describehotel {
    margin-top: 10px;
  }

  .describehotel_item {
    margin-top: 15px;
    text-align: justify;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    font-family: Microsoft YaHei;
  }

  .else_describehotel {
    margin-top: 15px;
    text-align: justify;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    font-family: Microsoft YaHei;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 4;
    // -webkit-box-orient: vertical;
  }

  .Services {
    padding: 15px;
    // padding-bottom: 22px;
    border-top: 10px solid #f4f6f9;
    // border-bottom: 40px solid #f4f6f9;
  }

  .ServicesFor {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  // .ServicesFor_item {
  //   width: 50%;
  //   display: flex;
  //   margin-top: 10px;

  //   img {
  //     width: 20px;
  //     height: 20px;
  //   }

  //   span {
  //     color: #000000;
  //     font-size: 14px;
  //     width: 116px;
  //     word-break: break-all;
  //     margin-top: 3px;
  //     text-indent: 2px;
  //     margin-left: 6px;
  //   }
  // }

  .Opcomponent {
    width: 12px;
    height: 11px;
    padding-top: 2px;
  }

  .OpcomponentFor_item {
    width: 50%;
    display: flex;
    margin-top: 10px;
  }

  .OpcomponentFor_item span {
    color: #000000;
    font-size: 14px;
    word-break: break-all;
    text-indent: 2px;
    margin-left: 6px;
  }

  // .Amenitiesitem {
  //   margin-top: 20px;
  // }

  .Services_box {
    padding-bottom: 20px;
  }

  .ServicesForx {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  // .mailboxPopup {
  //   padding: 38px 32px;
  //   display: flex;
  //   background: #fff;
  //   align-items: center;
  //   justify-content: center;
  // }

  .price-warn {
    width: 16px;
    height: 16px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  // .mailboxaddress {
  //   color: #00adb2;
  //   font-size: 14px;
  //   line-height: 24px;
  //   text-align: center;
  // }

  // .Beijingtime {
  //   font-size: 12px;
  //   line-height: 24px;
  //   color: #666666;
  //   text-align: center;
  // }

  // .mailboxPopup_right {
  //   margin-left: 10px;
  // }

  .van-icon-cross::before {
    color: #000000;
    font-size: 17px;
  }

  .hoteldetails .van-icon-cross::before {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
  }

  .hoteldetailsroll {
    height: 388px;
    overflow: auto;
  }

  .hoteldetails {
    /* padding-bottom: 50px; */
    height: 450px;
  }

  .hoteldetails_box {
    /* padding: 17px 15px; */
    text-align: center;
    padding: 20px 0px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #d3dce6;
  }

  .OnlineBox {
    padding: 20px 15px;
  }

  .ShowTypes img {
    width: 11px;
    height: 6px;
  }

  .OnlinePrepay {
    display: flex;
    align-items: flex-start;
  }

  .OnlinePrepay_left {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }

  .OnlinePrepay_right {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 18px;
    font-weight: bold;
    color: #ff6454;
    span {
      display: block;
      font-size: 12px;
      color: #000;
      font-weight: 400 !important;
      margin-top: 4px;
    }
  }

  .OnlineroomPrepay_left {
    justify-content: flex-start;
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      color: #000000;
      margin-right: 5px;
    }

    img {
      width: 11px;
      height: 6px;
    }
  }

  .OnlineroomPrepay_right {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #000000;
  }

  // .margTop30 {
  //   margin-top: 30px;
  // }

  .margTop20 {
    margin-top: 20px;
  }

  .Days_box {
    background: #f4f6f9;
    padding: 7px 10px;
    padding-bottom: 5px;
    margin-top: 10px;
  }

  .JunRoom {
    display: flex;
  }

  .JunRoom_left {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    line-height: 22px;
    font-size: 12px;
    color: #999999;
  }

  .JunRoom_right {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    line-height: 22px;
    font-size: 12px;
    color: #999999;
  }

  .Includes {
    margin-top: 10px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px dashed #eceff5;
  }

  .Includes span {
    font-size: 12px;
    color: #999999;
    margin-right: 5px;
  }

  .Includes i {
    font-size: 12px;
    color: #999999;
  }

  .Includes img {
    width: 16px;
    height: 16px;
  }

  .Meals {
    padding: 0px 15px;
    padding-bottom: 50px;
  }

  // .Meals_title {
  //   font-size: 14px;
  //   font-weight: bold;
  //   color: #000000;
  // }

  // .Meals_lncluded {
  //   font-size: 12px;
  //   color: #000000;
  //   margin-top: 20px;
  // }

  // .Meals_Per {
  //   margin-top: 15px;
  //   color: #999999;
  //   font-size: 12px;
  // }

  .originalPrepay {
    margin-bottom: 20px;
    display: flex;
  }

  // .originalPrepay_left {
  //   justify-content: flex-start;
  //   display: flex;
  //   align-items: center;
  //   font-size: 14px;
  //   color: #000000;
  // }

  .originalPrepay_right {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000000;
    text-decoration: line-through;
  }

  .feesexplain .van-icon-cross::before {
    font-weight: 400;
  }

  .hoteldetailsroll .van-popup--center {
    top: 60%;
  }

  .feesexplainPopup {
    padding: 38px 15px;
    padding-bottom: 20px;
    /* padding: 38px 32px; */
    width: 272px;
  }

  .Includestaxes {
    font-size: 12px;
    color: #000000;
  }

  .fees_item {
    display: flex;
    margin-top: 10px;
  }

  .feesitem_left {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #000000;
  }

  .feesitem_right {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 12px;
    color: #000000;
  }

  .RoomDetails {
    height: 550px;
  }

  .RoomDetails_title {
    text-align: center;
    padding: 20px 0px;
    font-size: 18px;
    font-weight: bold;
  }

  .swipe_box {
    height: 200px;
    width: 100%;
    position: relative;
  }

  .RoomDetailsRoll {
    // height: 488px;
    padding-bottom: 60px;
    position: relative;
    overflow: auto;
  }

  .RoomDetailsRoll :deep(p) {
    font-size: 14px;
  }

  .RoomDetailsRoll :deep(strong) {
    font-size: 14px;
  }

  .King {
    font-size: 18px;
    color: #000000;
    padding: 10px 15px;
  }

  .detailsfacilities {
    border-bottom: 1px solid #d3dce6;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
    margin: 0 15px;
    padding-top: 0px;
  }

  .detailsfacilities_item {
    display: flex;
    align-items: center;
    margin-right: 30px;
    padding-top: 5px;
  }

  .detailsfacilities_item img {
    width: 20px;
    height: auto;
  }
  .Rollhoteldetails {
    margin-top: 15px;
    padding: 0px 15px;
  }

  .Rollhoteldetails_item {
    margin-top: 8px;
    display: flex;
  }

  .Rollhoteldetailsdescribe {
    font-size: 12px;
    color: #000000;
    margin-left: 6px;
    line-height: 19px;
  }

  .Rollhoteldetails_item img {
    max-width: 16px;
    height: auto;
  }

  .Breakfasts {
    font-size: 12px;
    color: #00adb2;
  }

  .detailsAmenities {
    padding: 20px 15px;
  }

  .RoomDetailsRoll::-webkit-scrollbar {
    display: none;
  }

  // .detailsAmenities_title {
  //   font-size: 14px;
  //   color: #000000;
  //   font-weight: bold;
  // }

  .basicAll {
    margin-top: 12px;
  }

  // .basicAll_item {
  //   margin-top: 13px;
  //   display: flex;
  // }

  // .basicAll_item:first-child {
  //   margin-top: 0px;
  // }

  // .basicAllitem_left {
  //   width: 60%;
  // }

  // .basicAllitem_right {
  //   width: 40%;
  // }

  // .basicAll_itemText {
  //   font-size: 12px;
  //   color: #000000;
  //   margin-left: 5px;
  //   width: 90%;
  //   word-break: break-all;
  // }

  .Bottombutton {
    padding: 0 15px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    bottom: 20px;
  }

  .RoomDetails_button {
    width: 100%;
    padding: 12px 0px;
    background: #ff6454;
    border-radius: 2px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
  }

  .basicAll_item img {
    width: 12px;
    height: auto;
  }

  .NoRoom_titleBox {
    display: flex;
    padding: 20px;
    padding-bottom: 17px;
    border-bottom: 1px solid #d3dce6;
  }

  .van-popup--center.van-popup--round {
    border-radius: 12px;
  }

  .ok {
    padding: 12px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: #66c976;
  }

  .available {
    font-size: 12px;
    color: #000000;
    width: 170px;
    margin-left: 10px;
    margin-top: 3px;
  }

  // .van-toast {
  //   padding: 10px;
  //   width: 100%;
  //   height: 100%;
  //   max-width: 100%;
  // }

  .boxs {
    height: 32px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    border-bottom: 10px solid #f4f6f9;

    .box-text {
      position: absolute;
      left: 35%;
      top: 9px;
      font-size: 12px;
      color: #00adb2;
    }

    .img-list {
      animation: rolling 50s linear infinite;
      position: absolute;
      display: flex;

      img {
        height: 32px;
        float: left;
      }
    }
  }

  @keyframes rolling {
    from {
      transform: translateX(-50%);
    }

    to {
      transform: translateX(0);
    }
  }

  /* .date{
    flex: 1;
} */

  /* ----------------- */

  // .Exhibition_box {
  // }

  // .close {
  //   padding: 20px;
  // }

  // .dates {
  //   display: flex;
  //   padding: 0px 20px;
  //   padding-bottom: 18px;
  // }

  // .enter {
  //   width: 35%;
  //   font-size: 18px;
  //   font-weight: bold;
  //   color: #000000;
  // }

  // .arrow {
  //   width: 30%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  // .leave {
  //   width: 35%;
  //   text-align: right;
  //   font-size: 18px;
  //   font-weight: bold;
  //   color: #000000;
  // }

  // .van-calendar__footer {
  //   padding: 20px;
  // }

  // .calendar_box {
  //   height: 100%;
  //   width: 100%;
  //   position: absolute;
  //   background: #fff;
  // }

  // .vancalendar {
  //   height: 85%;
  // }

  // .active {
  //   color: #ffddcc;
  // }

  // .van-calendar__month-title {
  //   font-weight: bold;
  // }

  // .van-calendar__day--end,
  // .van-calendar__day--multiple-middle,
  // .van-calendar__day--multiple-selected,
  // .van-calendar__day--start,
  // .van-calendar__day--start-end {
  //   color: #fff;
  //   background-color: #66C976;
  // }

  // .van-calendar__day--middle {
  //   background-color: #e7f3ff;
  //   color: #000000;
  // }

  /* --------- */

  // .Navigation {
  //   padding: 20px;
  //   display: flex;
  //   background-color: #fff;
  // }

  // .Navigation_title {
  //   /* text-align: center;
  //   line-height: 20px;
  //   font-weight: bold;
  //   font-size: 16px;
  //   width: 100%; */
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-weight: bold;
  //   font-size: 16px;
  //   flex: 1;
  //   text-indent: -45px;
  // }

  // .option_item {
  //   padding: 18px 15px;
  //   border-bottom: 1px solid #d3dce6;
  //   display: flex;
  //   background-color: #fff;
  // }

  // .option_left {
  //   justify-content: flex-start;
  //   display: flex;
  //   align-items: center;
  //   font-size: 16px;
  //   color: #000000;
  // }

  // .option_right {
  //   flex: 1;
  //   justify-content: flex-end;
  //   display: flex;
  //   align-items: center;

  //   img {
  //     width: 24px;
  //     height: 24px;
  //   }
  // }

  // .number {
  //   padding: 0px 10px;
  //   font-size: 16px;
  //   color: #000000;
  //   width: 20px;
  //   text-align: center;
  // }

  // .option_right

  // .Tips {
  //   padding: 17px 15px;
  //   font-size: 12px;
  //   color: #000000;
  // }

  // .children_item {
  //   background-color: #fff;
  //   padding: 18px 15px;
  //   display: flex;
  //   border-bottom: 1px solid #d3dce6;
  // }

  // .children_left {
  //   justify-content: flex-start;
  //   display: flex;
  //   align-items: center;
  // }

  // .children_right {
  //   flex: 1;
  //   justify-content: flex-end;
  //   display: flex;
  //   align-items: center;
  //   font-size: 16px;
  //   color: #000000;

  //   img {
  //     width: 24px;
  //     height: 24px;
  //     margin-left: 18px;
  //   }

  //   &:last-child {
  //     border-bottom: none;
  //   }
  // }

  // .RoomIsGuestsShow_box {
  //   background-color: #f4f6f9;
  //   height: 100%;
  //   position: relative;
  // }

  // .bottom_confirm {
  //   position: absolute;
  //   left: 0px;
  //   bottom: 0px;
  //   width: 100%;
  //   background-color: #fff;
  // }

  .confirm {
    padding: 14px;
    background: #66c976;
    border-radius: 2px;
    margin: 16px;
    text-align: center;
    font-size: 15px;
    font-family: Microsoft YaHei;
    color: #ffffff;
  }

  .countryNavigation img {
    margin: 20px;
  }
  .countryFor_item {
    padding-top: 18px;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
  }

  .countryFor_right {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
  .countryFor_left {
    margin-left: 15px;
  }
  .countryFor_left img {
    width: 30px;
    height: 20px;
  }
  .countryName {
    font-size: 16px;
    margin-left: 15px;
    line-height: 22px;
  }
  .color {
    color: #66c976;
  }
  .countryBox {
    padding: 0px 15px;
  }
  .mailbox {
    width: 18px;
    height: 14px;
  }
  .fontsSize20 {
    width: 20px;
    height: 20px;
  }
  .fontsSize30 {
    width: 30px;
    height: 20px;
  }
  .fontsSize16 {
    width: 16px;
    height: 16px;
  }
  .peopleimg {
    width: 14px;
    height: 14px;
  }
  .paypaitem1 {
    width: 82px;
    height: 20px;
  }
  .paypaitem2 {
    width: 93px;
    height: 20px;
  }
  .paypaitem3 {
    width: 59px;
    height: 20px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .Loadingtext {
    font-size: 12px;
    font-family: Microsoft YaHei;
    color: #ffffff;
    margin-top: 6px;
  }

  .flexloading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .van-toast__text {
    padding: 0px 40px;
  }

  .pop-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 6px 0;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex-direction: column;

      small {
        font-size: 12px;
        color: #999;
      }
    }
  }

  .hotelItemTips {
    color: #999;
    font-size: 12px;
    margin-top: 4px;
    text-align: right;

    span {
      font-size: 12px;
    }
  }
}
</style>