<template>
  <van-popup
    v-model="popModel"
    position="bottom"
    :style="{ height: '100vh' }"
    @open="$emit('update:pop', true)"
    @close="$emit('update:pop', false)"
  >
    <div class="pop-box" style="background: #f4f6f9">
      <div class="pop-navigation">
        <span class="close-button" @click="popModel = false">Close</span>
        <p>Guests & Rooms</p>
      </div>
      <div class="pop-container">
        <template v-for="(item, index) in guest">
          <div class="option_item" :key="`option_${index}`">
            <div class="option_left">{{ item.name }}</div>
            <div class="option_right">
              <div class="reduce" @click="stepTrigger(index, -1)">
                <img
                  :src="
                    boundary(item, 'min')
                      ? require('../assets/image/Residentnumber/reduce.png')
                      : require('../assets/image/Residentnumber/ashReduce.png')
                  "
                />
              </div>
              <div class="number">{{ item.value }}</div>
              <div class="plus" @click="stepTrigger(index, 1)">
                <img
                  :src="
                    boundary(item, 'max')
                      ? require('../assets/image/Residentnumber/plus.png')
                      : require('../assets/image/Residentnumber/ashPlus.png')
                  "
                />
              </div>
            </div>
          </div>
          <template v-for="(child, cid) in item.children || []">
            <div
              class="childrens"
              :key="`children_${cid}`"
              v-if="child.value && child.value.length"
            >
              <div class="Tips">{{ child.label }}</div>
              <div
                class="children_item"
                v-for="(age, ai) in child.value"
                :key="ai"
                @click="showChildrenPop(child, ai)"
              >
                <div class="children_left">{{ child.name }} {{ ai + 1 }}</div>
                <div class="children_right">
                  {{ age }}
                  <img src="../assets/image/Residentnumber/choice.png" />
                </div>
              </div>
            </div>
          </template>
        </template>
        <div class="bottom_confirm">
          <div class="confirm" @click="confirm">Done</div>
        </div>
      </div>
      <van-popup v-model="showPicker" round position="bottom">
        <div>
          <van-nav-bar
            left-text="close"
            right-text="determine"
            left-arrow
            @click-left="showPicker = false"
            @click-right="onClickRight"
          />
        </div>
        <van-picker ref="picker" :columns="columns" />
      </van-popup>
    </div>
  </van-popup>
</template>

<script>
export default {
  watch: {
    pop(n) {
      this.popModel = n;
    },
    default: {
      deep: true,
      immediate: true,
      handler(n) {
        if (n) this.fillGuestValue();
      },
    },
  },
  props: {
    pop: {
      type: Boolean,
      default: false,
    },
    default: {
      type: Object,
      require: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      popModel: false,
      guest: [
        {
          name: "Rooms",
          key: "roomNum",
          depend: ["adultNum"],
          range: [1, 9],
          value: 1,
        },
        {
          name: "Adults",
          key: "adultNum",
          range: ["{roomNum}", "{roomNum} * 6"],
          value: 2,
          get() {
            return `Math.min(Math.max({roomNum}, {result}), ({roomNum} * 6))`;
          },
        },
        {
          name: "Children",
          key: "childNum",
          range: [0, 3],
          value: 0,
          children: [
            {
              label: `Please select the child's age.`,
              name: "Child",
              key: "childAges",
              value: [],
              get(parent, value) {
                const result = new Array(parent.value).fill("< 1");
                return result.map((item, index) => {
                  return value[index] || item;
                });
              },
            },
          ],
        },
      ],
      columns: [
        "< 1",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
      ],
      showPicker: false,
      pickerIndex: null,
      pickerItem: {},
    };
  },
  created() {},
  methods: {
    fillGuestValue() {
      this.guest.map((item) => {
        const { key, range, depend, children } = item;
        const defaultValue = this.default[key];

        // console.log(defaultValue, range);

        const rangeArgument = this.getValue({ result: item.value });
        const [min, max] = range.map((item) =>
          this.formatRange(item, rangeArgument)
        );

        if (defaultValue <= max && defaultValue >= min) {
          item.value = defaultValue || item.value;
        }

        if (depend && depend.length) {
          for (let i = 0; i < depend.length; i++) {
            this.stepTrigger(depend[i], 0);
          }
        }

        if (children && children.length) {
          for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.get) {
              child.value = child.get(item, this.default[child.key] || []);
            }
          }
        }
      });
    },
    getValue(s = {}) {
      const result = { ...s };
      this.guest.forEach((item) => {
        const { key, value, children } = item;
        result[key] = value;

        if (children && children.length) {
          children.forEach((child) => {
            result[child.key] = child.value;
          });
        }
      });

      return result;
    },
    boundary(item, type) {
      const { range, value } = item;
      const rangeArgument = this.getValue({ result: item.value });

      if (!range) {
        return true;
      }

      const [min, max] = range.map((item) =>
        this.formatRange(item, rangeArgument)
      );

      return (type === "min" && value > min) || (type === "max" && value < max);
    },
    showChildrenPop(item, index) {
      this.showPicker = true;
      this.pickerIndex = index;
      this.pickerItem = item;
      this.$nextTick(() => {
        this.$refs.picker.setValues([item.value[index]]);
      });
    },
    confirm() {
      this.popModel = false;
      this.$emit("change", this.getValue());
    },
    formatRange(s, params = {}) {
      if (s === null || s === undefined) return "";
      const result = String(s).replace(/{(.*?)}/g, function (_, key) {
        return params[key] || key;
      });
      return eval(`${result}`);
    },
    stepTrigger(index, step) {
      let guest;
      if (typeof index === "number") {
        guest = this.guest[index];
      } else {
        guest = this.guest.find((item) => item.key === index);
      }

      if (!guest) return;

      const { range, value, get, depend, children } = guest;
      let result = value + step;

      const rangeArgument = this.getValue({ result });

      const formatRange =
        range &&
        range.map((item) => {
          return this.formatRange(item, rangeArgument);
        });

      if (get) {
        result = this.formatRange(get(), rangeArgument);
      }

      if (
        !formatRange ||
        (formatRange && result >= formatRange[0] && result <= formatRange[1])
      ) {
        guest.value = result;
      }

      if (children && children.length) {
        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          if (child.get) {
            child.value = child.get(guest, child.value);
          }
        }
      }

      if (depend && depend.length && step) {
        // this.$nextTick(() => {
        for (let i = 0; i < depend.length; i++) {
          this.stepTrigger(depend[i], 0);
        }
        // });
      }
    },
    onClickRight() {
      const value = this.$refs.picker.getValues();
      this.pickerItem.value[this.pickerIndex] = value[0];
      this.showPicker = false;
    },
  },
};
</script>
